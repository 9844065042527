type ItemProps = {
  children: React.ReactNode
  href?: string
  onClick?: () => void
  active?: boolean
  highlight?: boolean
  className?: string
  newTab?: boolean
}

export type NaviItem = {
  name: string
  href: string
  newTab?: boolean
}

const Item: React.FC<ItemProps> = ({ children, href, onClick, active = false, highlight = false, newTab = false, className = '' }) => {
  if (onClick) {
    return (
      <div
        onClick={onClick}
        className={`cursor-pointer flex items-center relative justify-center uppercase text-white ${active && '!text-danger'} ${
          highlight ? 'bg-danger px-3' : ''
        }  ${className}`}
      >
        {children}
      </div>
    )
  }

  return (
    <a
      href={href}
      className={`flex items-center justify-center uppercase text-white ${active && '!text-danger'} ${
        highlight ? 'bg-danger px-3' : ''
      }  ${className}`}
      target={newTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

export default Item

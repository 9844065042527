import { useParams } from 'react-router-dom'
import getCategoryData, { Category, Item } from './getCategoryData'
import { useEffect, useState } from 'react'
import props from '../../redux/props'
import { useSelector } from 'react-redux'

const useGetSelectedCategoryItem = (): { item: Item; category: Category } | false => {
  const { categoryItemID } = useParams()
  const [category, setCategory] = useState<Category>(null)
  const [item, setItem] = useState<Item>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    const findCategory = (): Category | undefined => {
      const category = getCategoryData(t).find((c) => c.items.some((i) => i.id === categoryItemID))
      return category
    }

    const findItem = (): Item | undefined => {
      const category = findCategory()
      if (!category) return null
      const item = category.items.find((i) => i.id === categoryItemID)
      return item
    }

    if (!!categoryItemID) {
      const category = findCategory()
      const item = findItem()
      if (category && item) {
        setCategory(category)
        setItem(item)
        // scroll to #module
        if (!didScroll) {
          const element = document.getElementById('module')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
            setDidScroll(true)
          }
        }
      }
    } else {
      setCategory(getCategoryData(t)[0])
      setItem(getCategoryData(t)[0].items[0])
    }
  }, [categoryItemID, t, didScroll])

  return !!item && !!category ? { item, category } : false
}

export default useGetSelectedCategoryItem

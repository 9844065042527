import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'

const Download = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-danger py-5">
      <Container>
        <div className="flex flex-col gap-3 lg:flex-row justify-between items-center">
          <div>
            {t.easylan.download.prefix}
            <span className="text-white font-bold" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
            {t.easylan.download.suffix}
          </div>
          <a href={t.easylan.download.href} target="_blank" rel="noreferrer" className="bg-dark text-white uppercase py-1 px-8">
            {t.easylan.download.cta}
          </a>
        </div>
      </Container>
    </div>
  )
}

export default Download

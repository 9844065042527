import styled, { keyframes } from 'styled-components'
import { fadeInLeft, fadeInRight, fadeInUp, fadeInDown, fadeIn } from 'react-animations'

const fadeInLeftAnimation = keyframes`${fadeInLeft}`
const fadeInRightAnimation = keyframes`${fadeInRight}`
const fadeInUpAnimation = keyframes`${fadeInUp}`
const fadeInDownAnimation = keyframes`${fadeInDown}`
const fadeInAnimation = keyframes`${fadeIn}`
const slideInRightAnimation = keyframes`
from {
    transform: translateX(10%);
  }

  to {
    transform: translateX(0%);
  }
`
const slideInDownAnimation = keyframes`
from {
    transform: translateY(-10%);
  }

  to {
    transform: translateY(0%);
  }
`

export const float = keyframes`
    0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
`

const getAnimation = (animation) => {
  switch (animation) {
    case 'fadeInLeft':
      return fadeInLeftAnimation
    case 'fadeInRight':
      return fadeInRightAnimation
    case 'fadeInUp':
      return fadeInUpAnimation
    case 'fadeInDown':
      return fadeInDownAnimation
    case 'fadeIn':
      return fadeInAnimation
    case 'slideInRight':
      return slideInRightAnimation
    case 'slideInDown':
      return slideInDownAnimation
    default:
      return ''
  }
}

export const Animated = styled.div<{ animation?: any; duration?: string }>`
  animation: ${({ duration }) => duration || '0.5s'} ${({ animation }) => getAnimation(animation)};
`

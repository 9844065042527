import styled from 'styled-components'
import useGetSelectedCategoryItem from '../../useGetSelectedCategoryItem'
import useSelectCategoryItemID from '../../useSelectCategoryItemID'
import fixlink from '../../../../assets/image/easyLan/fixlink.svg'

const Item = styled.div`
  width: calc(100% / 6 - (20px / 6 * 5));
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2 - (20px / 2));
  }
`

const ModuleSelection = () => {
  const selectCategoryItem = useSelectCategoryItemID()
  const selectedCategoryItem = useGetSelectedCategoryItem()

  if (!selectedCategoryItem) return <></>
  return (
    <div className="flex flex-wrap flex-row mt-10 gap-5 w-full">
      {selectedCategoryItem.category.items.map((item) => {
        const isActive = item.id === selectedCategoryItem.item.id
        return (
          <Item onClick={() => selectCategoryItem(item.id)} key={item.name}>
            <div
              className={`${isActive ? 'bg-danger' : 'bg-light'} relative flex items-center justify-center aspect-square w-full grow-0 p-1`}
              key={item.name}
            >
              <div className="absolute top-2 left-2">
                <img src={fixlink} alt="" className="h-5" />
              </div>
              <img src={item.images[0]} alt={item.name} className="w-[80%] h-[80%] aspect-square object-contain" />
            </div>
            <div className={`font-bold text-sm ${!isActive && 'opacity-70'}`}>{item.name}</div>
          </Item>
        )
      })}
    </div>
  )
}

export default ModuleSelection

import { useNavigate } from 'react-router-dom'

const useSelectCategoryItemID = (): ((categoryItemID: string) => void) => {
  const navigate = useNavigate()

  return (categoryItemID: string) => {
    navigate(`/${categoryItemID}`)
    window.location.hash = '#module'
  }
}

export default useSelectCategoryItemID

export const translation = {
  header: {
    items: [
      {
        name: 'Acasă',
        href: '/',
      },
      {
        name: 'Sistem',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montaj',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Limba',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Simplitate',
        text2: 'în conexiune',
      },
      subtitle: 'Linkul tău pentru viitor',
      cta: 'mai mult',
    },
    handling: {
      title: 'MANIPULARE INTUITIVĂ ÎNTÂLNEȘTE TEHNOLOGIA AVANSATĂ',
      vorreiterPrefix: 'Combinația dintre precizie și design funcțional face ca ',
      vorreiterSuffix: ' să fie alegerea principală în tehnologia de rețea.',
      experte: ' oferă soluții eficiente de 360°, combinând designul și performanța pentru a minimiza complexitatea.',
      pionier: ' este pionier în transformarea spațiilor de lucru, combinând expertiza cu manipularea intuitivă.',
    },
    download: {
      prefix: 'Prezentare generală ',
      suffix: ' - Descărcați sistemul',
      cta: 'Descărcați',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Prezentare generală a produselor',
      title: 'FIXLINK<sup>®</sup> - SISTEM',
    },
    module: {
      overview: 'Descărcați prezentarea generală',
      accordion: {
        ghmtCertificates: {
          title: 'Certificări GHMT',
          text: 'Ne bazăm pe GHMT și pe Programul lor de Verificare Premium pentru asigurarea independentă a calității produselor noastre de rețea. Astfel, garantăm cele mai înalte standarde de produs pentru clienții noștri, bazate pe 20 de ani de expertiză.',
        },
        datasheets: {
          title: 'Fișe tehnice',
          text: 'Găsiți aici toate fișele tehnice necesare. Descărcați-le și aveți toate informațiile la îndemână.',
        },
        compatibilitySheets: {
          title: 'Liste de compatibilitate',
          text: '',
        },
        excelTemplates: {
          title: 'Fereastră de etichetare ca șablon Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'INSTRUCȚIUNI DE MONTARE',
      text: 'Urmărește videoclipul sau descarcă simplu PDF-ul aici',
      manual: {
        title: 'INSTRUCȚIUNI DE MONTARE:',
      },
    },
    separator: {
      title: 'Mai mult decât conexiune',
      title2: 'Descoperă universul EasyLan',
      text: 'Descoperă diversitatea soluțiilor inovatoare de rețea pe EasyLan.de: Personalizate pentru tine. Schimbătorul de jocuri pentru rețeaua ta: Sistemele noastre de cablare și soluțiile de gestionare a cablurilor.',
      cta: 'mai mult',
    },
    combination: {
      bau: {
        prefix: 'Experimentează adaptarea maximă și calitatea cu ',
        text: ' suporturile de grup EasyLan ',
        suffix: 'direct din Europa.',
      },
      intelligent: {
        prefix: 'Rețeaua ta',
        text: ' personalizată ',
        suffix: 'cu cutia de fuziune configurabilă EasyLan',
      },
      fixlink: {
        prefix: 'Cu produsele noastre ',
        suffix: ' vă puteți îmbunătăți pe termen lung clădirile de birouri.',
      },
      hds: {
        prefix: 'Sistemul',
        text: ' EasyLan<sup>®</sup> H.D.S. - Sistem ',
        suffix: 'combină ușurința de instalare și întreținere cum nu o face niciun alt sistem de cablare trunchiată de pe piață.',
      },
    },
    recommended: {
      titleLabel: 'Recomandat',
      title: 'fixLink<sup>®</sup> - SISTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Garanția Produsului',
        text: [
          'Garanția produsului include toate componentele unui ',
          '- sistem de cablare cu fibră optică sau cupru, instalate și testate de un instalator calificat conform specificațiilor ',
          ' și normelor de instalare în vigoare EN50174.',
        ],
      },
      {
        title: 'Garanția Performanței',
        text: [
          'Garanția performanței garantează, pe lângă aplicație (Ethernet, Fiber Channel, ATM, etc.), caracteristicile sistemului, inclusiv vitezele de transmisie (10MBit, 100MBit, 1GBit și 10GBit).',
        ],
      },
      {
        title: 'Garanția Aplicațiilor',
        text: [
          'Garanția aplicațiilor garantează toate aplicațiile specificate (Ethernet, Fiber Channel, ATM, etc.), bazate pe standardele de cablare valabile la momentul instalării conform ISO/IEC 11801, EN50173 și EIA/TIA 568B.',
        ],
      },
    ],
    end: {
      title: 'CONEXIUNILE NU AU FOST NICIODATĂ AȘA DE SIMPLE.',
      text: 'Manipulare intuitivă întâlnește tehnologia avansată; descoperiți conexiunea perfectă a viitorului: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Module de conexiune',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Modul Keystone RJ45 Pro Kat. 6A ISO/IEC, ecranat',
        text: 'Cu modulul Keystone RJ45 Pro fixLink<sup>®</sup> utilizatorul primește un modul de priză RJ45 de cea mai înaltă calitate. Modulul Keystone RJ45 Pro fixLink<sup>®</sup> poate fi asamblat în mai puțin de un minut folosind un tăietor lateral. Zona de contact accesibilă asigură o calitate perfectă a asamblării și, prin urmare, o performanță de transmisie garantată. Geometria specială a contactului în combinație cu un strat de aur de 30 μ" asigură 4PPoE conform IEEE 802.3bt în toate condițiile. Materialul carcasei este din zinc turnat nichelat, îndeplinind astfel cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul de design, este posibilă combinarea cu toate programele de comutatoare. Acestea sunt livrate într-un ambalaj de carton ecologic.',
        ghmtCertificates: [
          {
            name: 'Certificat PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Certificat Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, ecranat',
        text: 'Cu extenderul fixLink<sup>®</sup> cablurile pot fi prelungite fără mult efort. În plus, cablurile inactive pot fi reactivate și prelungite până la următoarea conexiune necesară. Spațiul limitat din canalul de cablu este astfel utilizat optim. Extenderul este pur și simplu înșurubat la capătul cablului instalat în canal sau pe perete. După instalarea efectuată, cablul astfel prelungit poate fi utilizat pentru transmisii Ethernet de până la 10 gigabiți și suportă PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'Plug RJ45 Kat. 6A ISO/IEC ecranat',
        text: 'Cu modulul de priză RJ45 fixLink<sup>®</sup> utilizatorul primește un modul de priză RJ45 de câmp de cea mai înaltă calitate și flexibilitate. Geometria specială a contactului în combinație cu un strat de aur de 50 μ" asigură 4PPoE conform IEEE 80.3bt în toate condițiile. Materialul carcasei este din zinc turnat nichelat, îndeplinind astfel cele mai înalte cerințe de mediu și mecanice. Diverse certificări (listare actuală: www.easylan.de) de la laboratoare de testare independente completează cea mai înaltă cerință de calitate a sistemului fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'Modul Keystone RJ45 Kat. 6A EIA/TIA, neecranat',
        text: 'Cu modulul Keystone RJ45 fixLink<sup>®</sup> (capabil PoE+) utilizatorul primește un modul de priză RJ45 de cea mai înaltă calitate. Modulul Keystone RJ45 fixLink<sup>®</sup> poate fi asamblat în mai puțin de un minut folosind un tăietor lateral. Zona de contact accesibilă asigură o calitate perfectă a asamblării și, prin urmare, o performanță de transmisie garantată. Materialul carcasei este din policarbonat și îndeplinește astfel cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul de design, este posibilă combinarea cu toate programele de comutatoare. Diverse certificări de la laboratoare de testare independente completează cea mai înaltă cerință de calitate a modulului fixLink<sup>®</sup> SL Keystone. Acestea sunt livrate într-un ambalaj de carton ecologic.',
      },
      fixLink_SL_02: {
        name: 'Modul Keystone RJ45 SL Kat. 6A ISO/IEC, ecranat',
        text: 'Cu modulul Keystone RJ45 SL fixLink<sup>®</sup> utilizatorul primește un modul de priză RJ45 de cea mai înaltă calitate. Modulul Keystone RJ45 SL fixLink<sup>®</sup> poate fi asamblat în mai puțin de un minut folosind un tăietor lateral. Zona de contact accesibilă asigură o calitate perfectă a asamblării și, prin urmare, o performanță de transmisie garantată. Pentru preluarea ecranului la 360° este necesară doar închidere cu ajutorul unei pene integrate, eliminând necesitatea aplicării unui colier de cablu. Geometria specială a contactului în combinație cu un strat de aur de 50 μ" asigură 4PPoE conform IEEE 802.3bt în toate condițiile. Materialul carcasei este din zinc turnat nichelat, îndeplinind astfel cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul de design, este posibilă combinarea cu toate programele de comutatoare. Diverse certificări de la laboratoare de testare independente completează cea mai înaltă cerință de calitate a modulului fixLink<sup>®</sup> SL Keystone. Acestea sunt livrate într-un ambalaj de carton ecologic.',
        ghmtCertificates: [
          {
            name: 'Certificat PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Raport de testare 4PoE conform DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'Modul Keystone RJ45 SL înclinat Kat. 6A ISO/IEC, ecranat',
        text: 'Cu modulul Keystone RJ45 SL fixLink<sup>®</sup> utilizatorul primește un modul de priză RJ45 de cea mai înaltă calitate, dezvoltat special pentru aplicații cu spațiu limitat. Datorită ieșirii cablului la 75°, modulul este potrivit în special pentru utilizarea în prize de date și cablări de goluri. O adâncime minimă de montare de 27 mm permite o varietate nelimitată de aplicații. Modulul Keystone RJ45 SL fixLink<sup>®</sup> poate fi asamblat în mai puțin de un minut folosind un tăietor lateral. Zona de contact accesibilă asigură o calitate perfectă a asamblării și, prin urmare, o performanță de transmisie garantată. Geometria specială a contactului în combinație cu un strat de aur de 50 μ" asigură PoE+ conform IEEE 80.3at în toate condițiile. Materialul carcasei este din zinc turnat nichelat, îndeplinind astfel cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul de design, este posibilă combinarea cu toate programele de comutatoare. Diverse certificări de la laboratoare de testare independente completează cea mai înaltă cerință de calitate a modulului fixLink<sup>®</sup> SL Keystone. Acestea sunt livrate într-un ambalaj de carton ecologic.',
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Instrumente și accesorii',
        text: [
          'Instrument de dezizolat pentru îndepărtarea mantalei exterioare și a foliilor metalice ale unui cablu de date. Culoare albastră.',
          'Bandă de cupru pentru fixarea ecranului. Tăiată în prealabil la 35 x 9 mm. Unitatea de ambalare: 20 bucăți/pungă 1500 bucăți/rolă',
          'Capace de protecție împotriva prafului colorate pentru modulele preLink® SL și fixLink® SL Keystone.',
          'Unitate de ambalare 50 bucăți/pungă de plastic',
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Cablu de instalare',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Cablu de date EasyLan® Gold S/FTP AWG22/1 1500 MHz Cat. 7A',
        text: [
          'Cablu de date pentru transmiterea semnalelor analogice și digitale în cablajul din domeniile primar, secundar și terțiar. Potrivit pentru aplicații de până la clasa FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fișa tehnică',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Cablu de date EasyLan® Silber S/FTP AWG23/1 1200 MHz Cat. 7A',
        text: [
          'Cablu de date pentru transmiterea semnalelor analogice și digitale în cablajul din domeniile primar, secundar și terțiar. Potrivit pentru aplicații de până la clasa FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fișa tehnică',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Cablu de date EasyLan® Bronze S/FTP AWG23/1 1000 MHz Cat. 7',
        text: 'Cablu de date pentru transmiterea semnalelor analogice și digitale în cablajul din domeniile primar, secundar și terțiar. Potrivit pentru aplicații de până la clasa FA.',
        datasheets: [
          {
            name: 'Fișa tehnică',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Prize de date',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Carcase și rame AP pentru suporturile de module menționate',
        text: [
          'Carcase pentru montaj aparent IP20, 80x80x42mm pentru instalarea suporturilor de module EasyLan',
          'Cadru intermediar pentru carcasa aparentă pentru extinderea înălțimii cu 10mm',
          'Cadru de acoperire 1-fach 80x80mm',
          'Cadru de acoperire 2-fach 80x150mm',
        ],
        datasheets: [
          {
            name: 'Carcase aparentă',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Extinderea adâncimii carcasei aparente',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Suport de module SL, ieșire la 45°, UP, BK, AP, BT 1-fach, 2-fach, 3-fach',
        text: 'Suporturile de module SL sunt potrivite în special pentru instalarea în canalele de cabluri (EK), montaj încastrat (UP) și montaj aparent (AP). Prin ruperea a două urechi de montaj, este posibilă și montarea în rezervor de podea (BT). Suportul de module SL poate găzdui în siguranță și permanent până la trei module Keystone EasyLan. Are o fereastră mare de etichetare centrală pentru o identificare ușoară. Placa centrală (50x50 mm) și cadrul (80x80 mm) sunt realizate din plastic rezistent la UV și extrem de durabil. Astfel este asigurată o durată lungă de viață și un aspect de culoare permanent.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Suport de module de design SL, ieșire la 45°, UP, BK, AP, BT 1-fach, 2-fach',
        text: 'Suportul de module de design este realizat dintr-un cadru din zinc turnat. Este potrivit pentru instalarea în canalele de cabluri (EK), pentru montaj încastrat (UP) sau cu ajutorul unui cadru separat și pentru montaj aparent (AP). Prin ruperea a două urechi de montaj, este posibilă și montarea în rezervor de podea (BT). Priza de date de design este compatibilă cu aproape toate programele de comutatoare disponibile pe piață. Datorită compatibilității UAE, priza de date de design nu oferă loc pentru o clapetă de protecție împotriva prafului.',
        datasheets: [
          {
            name: 'Keystone 1/2-fach, fără placă centrală',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Keystone 2-fach, cu placă centrală 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-fach cu placă centrală',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Suport de module IP44 aparent, 1-fach, 2-fach',
        text: 'Carcasa robustă aparentă este potrivită pentru instalarea a două module Keystone preLink<sup>®</sup> / fixLink<sup>®</sup> folosind un adaptor. În partea din spate a carcasei se află două puncte ușor de străpuns pentru introducerea a două cabluri cu un diametru de 5,0 - 9,5mm fiecare. Suportul de module atinge clasa de protecție IP20 în stare conectată și clasa de protecție IP44 în stare închisă.',
      },
      DoseUPleer2xKS: {
        name: 'Suport de module ieșire dreaptă, 2-fach',
        text: 'Suportul de module cu ieșire dreaptă Keystone este realizat dintr-un cadru din zinc turnat și este potrivit pentru instalarea în canale de cabluri (EK), montaj încastrat (UP) sau cu ajutorul unui cadru separat și pentru montaj aparent (AP).',
      },
      bodentank: 'Rezervor de podea',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Sisteme de rezervoare de podea OBO/Ackermann UT3, UT4',
        text: 'Placa suport înlocuiește suportul de dispozitiv, oferind astfel cel mai mare spațiu posibil pentru introducerea cablurilor. Conducerea înclinată a cablurilor permite o cablare sigură chiar și în spații foarte mici între pardoseli. Este prevăzută o combinație cu plăci de montaj pentru 4 module preLink<sup>®</sup>/fixLink<sup>®</sup> sau 1 sistem H.D.S. Plăcile de montaj sunt fixate pe placa suport cu două șuruburi, câmpurile plăcii suport care nu sunt utilizate pot fi acoperite cu plăci de acoperire pentru praf.',
        datasheets: [
          {
            name: 'Placă suport pentru UT3 fără placă de montaj',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Placă suport pentru UT4 fără placă de montaj',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Suport de rezervor de podea OBO/Ackermann Basic UT3, UT4',
        text: 'Suportul de rezervor de podea Keystone înlocuiește suportul de dispozitiv, oferind astfel cel mai mare spațiu posibil pentru introducerea cablurilor. Conducerea înclinată a cablurilor permite o cablare sigură chiar și în spații foarte mici între pardoseli. Plăcile de montaj sunt fixate pe placa suport cu două șuruburi, orificiile Keystone ale suportului de rezervor de podea care nu sunt utilizate pot fi acoperite cu plăci de acoperire pentru praf. Cu ajutorul unui adaptor, suportul de rezervor de podea poate fi folosit și pentru suporturile de dispozitiv Electraplan.',
        datasheets: [
          {
            name: 'Suport de rezervor de podea UT3 pentru până la 6 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Suport de rezervor de podea UT4 pentru până la 9 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Suport de rezervor de podea UT4 pentru până la 12 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Suport de rezervor de podea PUK Basic UT4',
        text: 'Suportul de rezervor de podea Keystone înlocuiește suportul de dispozitiv, oferind astfel cel mai mare spațiu posibil pentru introducerea cablurilor. Conducerea înclinată a cablurilor permite o cablare sigură chiar și în spații foarte mici între pardoseli. Plăcile de montaj sunt fixate pe placa suport cu două șuruburi, orificiile Keystone ale suportului de rezervor de podea care nu sunt utilizate pot fi acoperite cu plăci de acoperire pentru praf. Suporturile de rezervor de podea sunt compatibile cu sistemul de rezervoare de podea PUK.',
        datasheets: [
          {
            name: 'Suport de rezervor de podea UT4 pentru până la 9 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Suport de rezervor de podea UT4 pentru până la 12 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Module OBO/Ackermann pentru sisteme de rezervoare de podea UT3, UT4, accesorii',
        text: 'Modulele pentru inserții sunt nepulverizate și zincate prin imersiune la cald pentru motive de împământare. Plăcile de montaj sunt fixate pe placa suport cu două șuruburi, câmpurile plăcii suport care nu sunt utilizate pot fi acoperite cu plăci de acoperire pentru praf.',
        datasheets: [
          {
            name: 'Placă de montaj pentru 4 module RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Placă de montaj pentru 3 module RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adaptor șină DIN',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Carcasă compactă pentru module pe șină DIN pentru 1 modul',
        text: 'Carcasă de module pe șină DIN pentru module preLink<sup>®</sup> / fixLink<sup>®</sup>, potrivită pentru dulapuri de distribuție fără capac pentru siguranțe automate.',
        datasheets: [
          {
            name: 'Carcasă compactă pentru module pe șină DIN pentru 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Adaptoare Keystone F-Kupplung și modul gol de acoperire',
        text: 'Adaptoare F-Kupplung și modul gol de acoperire ca înlocuitor de modul\nAdaptoarele F-Kupplung sunt utilizate pentru integrarea cablurilor coaxiale în patch panel-urile preLink<sup>®</sup> / fixLink<sup>®</sup> și în programul de prize. Modulul gol de acoperire preLink<sup>®</sup>/fixLink<sup>®</sup> poate fi utilizat atât în câmpul de distribuție, cât și în prize pentru a acoperi porturile neutilizate.\n\nModul gol pentru SC-S sau LC-D ca înlocuitor de modul\nModul gol pentru adaptoare SC-S sau LC-D în câmpul de distribuție sau priză.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Carcasă de module pe șină DIN pentru 1 modul',
        text: 'Carcasă de module pe șină DIN pentru module preLink<sup>®</sup> / fixLink<sup>®</sup>, potrivită pentru dulapuri de distribuție cu capac pentru siguranțe automate.',
        datasheets: [
          {
            name: 'Carcasă de module pe șină DIN pentru 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panou',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panou 19" 1HE pentru 24 module Keystone ieșire dreaptă',
        text: 'Câmpul de distribuție 19" 1HE poate fi echipat cu până la 24 module Keystone RJ45 preLink<sup>®</sup> / fixLink<sup>®</sup>. Este construit în două niveluri, astfel încât modulele sunt aliniate cu panoul frontal. Câmpul de distribuție poate fi comandat fie din oțel inoxidabil, fie din tablă de fier acoperită cu pulbere.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panou 19" 1HE pentru 48 module Keystone ieșire dreaptă',
        text: 'Câmpul de distribuție 19" 1HE poate fi echipat cu până la 48 module Keystone RJ45 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+. Este construit în două niveluri, astfel încât modulele sunt aliniate cu panoul frontal. Câmpul de distribuție este realizat din tablă de fier acoperită cu pulbere.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panou 19" 0,5HE pentru 24 module Keystone ieșire dreaptă',
        text: 'Câmpul de distribuție preLink<sup>®</sup> / fixLink<sup>®</sup> 19" 0,5HE poate fi echipat cu până la 24 module Keystone RJ45 fixLink<sup>®</sup>. Acestea sunt aliniate cu panoul frontal.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panou 19" 1HE pentru 24 module Keystone inclusiv fereastră de etichetare',
        text: 'Câmpul de distribuție 19" 1HE poate fi echipat cu până la 24 module Keystone RJ45 preLink<sup>®</sup> / fixLink<sup>®</sup>. Este construit în două niveluri, astfel încât modulele sunt aliniate cu panoul frontal. Câmpul de distribuție poate fi comandat fie din oțel inoxidabil, fie din tablă de fier acoperită cu pulbere. În plus, există posibilitatea de a eticheta fiecare port folosind o foaie de etichetare perforată 12x33 inclusă.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Panou înclinat la 30° 19" 1HE pentru 24 module Keystone',
        text: 'La panoul înclinat preLink<sup>®</sup> / fixLink<sup>®</sup>, utilizator ul are posibilitatea de a fixa 24 module Keystone la un unghi de 30° în panou, astfel încât cablurile de patch conectate să curgă automat spre partea laterală a dulapului și să fie fixate doar pe marginea laterală a panoului de patch. Astfel, utilizatorul poate renunța la panouri de gestionare suplimentare pentru cabluri. În funcționare, panourile înclinate cu partea frontală plană și cablurile de patch înclinate ocupă mai puțin spațiu în față decât panourile de patch tradiționale cu porturi montate vertical.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      baugruppentraeger: 'Suport de module',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Suport de module 19" 3HE pentru utilizarea modulelor de inserție',
        text: 'Suport de module 19" 3HE pentru utilizarea modulelor de inserție de 7TE în cupru și fibră optică. Acestea sunt fixate pe suportul de module cu două șuruburi de pe partea frontală a panoului. Modulele de inserție pot fi complet extrase din suportul de module. Pot fi fixate maximum 12 module de inserție de 7TE în suportul de module.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Modul de inserție Keystone pentru suport de module',
        text: 'Modul de inserție Keystone pentru echiparea suporturilor de module. Modulul de inserție Keystone poate fi echipat cu până la 6 module Keystone preLink<sup>®</sup> / fixLink<sup>®</sup>. Modulul de inserție Keystone este construit în două niveluri, astfel încât modulele sunt aliniate cu panoul frontal. Suportul de cablu este realizat direct pe carcasa modulului de inserție. Pot fi fixate 6 cabluri individuale sau un cablu trunchiat.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Suport de module (BGT) 19" 1HE pentru utilizarea modulelor de inserție',
        text: 'Suport de module (BGT) pentru utilizarea modulelor de inserție de 7TE în cupru și fibră optică. Acestea sunt fixate pe suportul de module cu două șuruburi de pe partea frontală a panoului. Modulele de inserție pot fi complet extrase din suportul de module. Pot fi fixate maximum 3 module de inserție de 7TE (=18 porturi) în suportul de module.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Suport de module lateral 3HE/14TE pentru utilizarea modulelor de inserție',
        text: 'Suport de module lateral (S-BGT) pentru utilizarea modulelor de inserție de 7TE în cupru și fibră optică. Modulele de inserție sunt fixate pe suportul de module cu două șuruburi de pe partea frontală a panoului. Modulele de inserție pot fi complet extrase din suportul de module. Pot fi fixate maximum 2 module de inserție de 7TE (=12 porturi) în suportul de module. Fixarea suportului de module lateral (S-BGT) se face pe perforațiile laterale ale dulapului. Astfel, este posibilă o montare simplă și rapidă. Prin montarea laterală în dulap, este posibilă realizarea unei cablări economisitoare de spațiu, foarte prietenoasă cu climatul, fără a perturba fluxul de aer.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      consolidation_point_panel: 'Panou punct de consolidare, șine DIN etc.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Panou de punct de consolidare subteran 19" sau 10" 1HE',
        text: 'Panou de punct de consolidare subteran cu plăci frontale interschimbabile pentru a găzdui diferite tipuri de conexiuni în cupru și fibră optică. Opțional, panoul de punct de consolidare subteran poate fi securizat cu un lacăt pentru a preveni accesul neautorizat.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
        datasheets: [
          {
            name: 'Panou de punct de consolidare 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Panou de punct de consolidare 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Panou de punct de consolidare de suprafață pentru 4-12 module Keystone',
        text: 'Panou de punct de consolidare de suprafață pentru module Keystone RJ45 preLink<sup>®</sup> / fixLink<sup>®</sup>, utilizabil ca panou de consolidare, montare directă sau cu ajutorul unui set de montaj pe șină DIN în distribuțiile electrice. Panoul de punct de consolidare de suprafață este construit în două niveluri. Astfel, modulele sunt aliniate cu panoul frontal.\n\nMaterialele de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      trunkkabel: 'Cabluri trunchiate',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'Sistem de împărțire SL CTBC Cabluri Trunchiate Copper-Trunk-Bundled-Cable, Clasa EA ISO/IEC',
        text: 'Sistemul de împărțire CTBC este ideal pentru utilizarea flexibilă în cablarea structurală a clădirilor și în centrele de date. Cablurile legate într-un manșon împletit sunt asamblate și testate din fabrică cu module Keystone fixLink<sup>®</sup>. Legarea se poate face atât cu cabluri de instalare, cât și cu cabluri flexibile. Lungimea maximă a cablului legat este de până la 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'Sistem de împărțire SL CTCC Cabluri Trunchiate Copper-Trunk-Compact-Cable, Clasa EA ISO/IEC',
        text: 'Sistemul de împărțire CTCC este ideal pentru utilizarea flexibilă în cablarea structurală a clădirilor și în centrele de date datorită diametrului său minim al cablului și a sarcinii reduse de incendiu corespunzătoare. Lungimea maximă a cablului multiplu este de 60-90 m, în funcție de tip. Sistemul de împărțire ca soluție de 10 Gbit pentru cablarea centrelor de date îndeplinește cel puțin cerințele clasei EA.',
      },
      hds_innengabel: 'Cablu interior H.D.S.',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'Cablu interior H.D.S. Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'Cablu interior H.D.S. Kat. 7 S/FTP pentru o cablare ușoară de la rack la rack în centrele de date și conexiuni multiple de la camerele de distribuție la subdistribuții. Structura cablului constă din 24 de elemente de cablu ecranate, fiecare 4 perechi fiind grupate într-un element de cablu. Fiecare element de cablu este marcat cu o bandă de identificare pentru o identificare mai ușoară. Elementul central de protecție împotriva tracțiunii protejează cablul în mod special în timpul tragerii. Învelișul este realizat dintr-un material flexibil LSOH.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'Cablu interior H.D.S. Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'Cablu interior H.D.S. Kat. 7 S/FTP pentru o cablare ușoară de la rack la rack în centrele de date și conexiuni multiple de la camerele de distribuție la subdistribuții. Structura cablului constă din 24 de elemente de cablu ecranate, fiecare 4 perechi fiind grupate într-un element de cablu. Fiecare element de cablu este marcat cu o bandă de identificare pentru o identificare mai ușoară. Elementul central de protecție împotriva tracțiunii protejează cablul în mod special în timpul tragerii. Învelișul este realizat dintr-un material flexibil LSOH.',
      },
      generic: {
        download: 'Descărcare',
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Protecția Datelor',
  },

  footer: {
    dataProtection: 'Protecția Datelor',
    imprint: 'Impressum',
    contact: 'Contact',
  },

  UI: {
    autocomplete: {
      searchError: 'Căutarea nu a putut fi executată.',
      searchEmpty: 'Niciun rezultat',
    },
    dropdown: {
      emptySelection: '--- te rog alege ---',
    },
  },
}

export const langKey = 'MD'

const Default = {
  translation,
  langKey,
}

export default Default

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import bosnischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_bosnisch_korrektur.svg'
import kroatischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_kroatisch_korrektur.svg'
import albanischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_albanisch.svg'
import bulgarischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_bulgarisch.svg'
import deutschImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_deutsch.svg'
import englischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_englisch.svg'
import franzoesischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_franzoesisch.svg'
import marokkanischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_marokkanisch.svg'
import moldawischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_moldawisch.svg'
import polnischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_polnisch.svg'
import rumaenischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_rumaenisch.svg'
import russischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_russisch.svg'
import serbischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_serbisch.svg'
import slowakischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_slowakisch.svg'
import tschechischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_tschechisch.svg'
import tunseischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_tunseisch.svg'
import tuerkischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_tuerkisch.svg'
import ukrainischImage from '../../../assets/image/easyLan/guarantee/lang/zvk_landingpage_unten_01_ukrainisch.svg'
import wire from '../../../assets/image/easyLan/guarantee/wire.svg'
import { getUUID } from '../../../utility'

type Item = {
  title: string
  text: string[]
}

const Guarantee = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const currentLang = useSelector((s) => s[props.CURRENTLANG])

  const getImage = () => {
    console.log('currentLang', currentLang)

    switch (currentLang.toLowerCase()) {
      case 'de':
        return deutschImage
      case 'en':
        return englischImage
      case 'fr':
        return franzoesischImage
      case 'tr':
        return tuerkischImage
      case 'ar':
        return tunseischImage
      case 'ru':
        return russischImage
      case 'pl':
        return polnischImage
      case 'ro':
        return rumaenischImage
      case 'sr':
        return serbischImage
      case 'sk':
        return slowakischImage
      case 'cz':
        return tschechischImage
      case 'ua':
        return ukrainischImage
      case 'bg':
        return bulgarischImage
      case 'hr':
        return kroatischImage
      case 'bs':
        return bosnischImage
      case 'al':
        return albanischImage
      case 'ma':
        return marokkanischImage
      case 'md':
        return moldawischImage
      default:
        return englischImage
    }
  }

  return (
    <div className="pb-32 pt-10 md:pt-20 bg-white">
      <div className="hidden md:block">
        <img src={getImage()} className="w-full" alt="Garantie" />
      </div>
      <Container>
        <div className=" md:hidden">
          <img src={wire} className="w-full" alt="Garantie" />
          <div className="flex flex-col justify-between gap-12 mt-5">
            {t.easylan.guarantee.map((g: Item, i: number) => (
              <div className="flex flex-col items-center gap-5 flex-1" key={getUUID()}>
                <div className="text-center font-bold">{g.title}</div>
                <div className={`text-sm text-center`}>
                  {g.text.map((text, j) => {
                    return (
                      <div key={getUUID()}>
                        {j > 0 && <span className="text-danger text-center text-sm" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />}
                        {text}
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Guarantee

export const translation = {
  header: {
    items: [
      {
        name: 'Home',
        href: '/',
      },
      {
        name: 'System',
        href: '#system',
      },
      {
        name: 'Module',
        href: '#module',
      },
      {
        name: 'Assembly',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Language',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Simplicity',
        text2: 'in Connection',
      },
      subtitle: 'Your Link to the Future',
      cta: 'more',
    },
    handling: {
      title: 'INTUITIVE HANDLING MEETS ADVANCED TECHNOLOGY',
      vorreiterPrefix: 'The combination of precision and functional design makes ',
      vorreiterSuffix: ' the leading choice in networking technology.',
      experte: ' offers efficient 360° solutions that optimally combine design and performance to minimize complexity.',
      pionier: ' is a pioneer in transforming workspaces, combining expertise with intuitive handling.',
    },
    download: {
      prefix: 'Overview ',
      suffix: ' - Download System',
      cta: 'Download',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Product Overview',
      title: 'FIXLINK<sup>®</sup> - SYSTEM',
    },
    module: {
      overview: 'Download Overview',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT Certificates',
          text: 'We rely on GHMT and their Premium Verification Program for independent quality assurance of our network products. Thus, based on 20 years of expertise, we guarantee the highest product standards for our customers.',
        },
        datasheets: {
          title: 'Datasheets',
          text: 'Here you will find all the necessary datasheets. Download them and have all the information at a glance.',
        },
        compatibilitySheets: {
          title: 'Compatibility Lists',
          text: '',
        },
        excelTemplates: {
          title: 'Labeling Window as Excel Template',
          text: '',
        },
      },
    },
    video: {
      title: 'ASSEMBLY INSTRUCTIONS',
      text: 'Watch the video or simply download the PDF here',
      manual: {
        title: 'ASSEMBLY INSTRUCTIONS:',
      },
    },
    separator: {
      title: 'More than Just Connection',
      title2: 'Discover the EasyLan Universe',
      text: 'Discover the variety of innovative network solutions at EasyLan. Tailored to you. The GameChanger for your network: Our cabling systems and cable management solutions.',
      cta: 'more',
    },
    combination: {
      bau: {
        prefix: 'Experience maximum customization and quality with ',
        text: " EasyLan's module carriers ",
        suffix: 'directly from Europe.',
      },
      intelligent: {
        prefix: 'Your network',
        text: ' tailored ',
        suffix: 'with the configurable EasyLan splice box',
      },
      fixlink: {
        prefix: 'With our ',
        suffix: ' products you can improve your office buildings in the long term.',
      },
      hds: {
        prefix: 'The',
        text: ' EasyLan® H.D.S. - System ',
        suffix: 'combines installation and maintenance friendliness like no other trunk cabling system on the market.',
      },
    },
    recommended: {
      titleLabel: 'Recommended',
      title: 'fixLink<sup>®</sup> - SYSTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Product Guarantee',
        text: [
          'The product guarantee includes all components of a ',
          '- fiber optic or copper cabling system, which were installed and tested by a qualified installer according to the guidelines of ',
          ' and the applicable installation standards EN50174.',
        ],
      },
      {
        title: 'Performance Guarantee',
        text: [
          'The performance guarantee, in addition to the application (Ethernet, Fiber Channel, ATM, etc.), also guarantees the system properties including the transmission speeds (10MBit, 100MBit, 1GBit, and 10GBit).',
        ],
      },
      {
        title: 'Application Guarantee',
        text: [
          'The application guarantee ensures all specified applications (Ethernet, Fiber Channel, ATM, etc.), which are based on the cabling standards ISO/IEC 11801, EN50173, and EIA/TIA 568B valid at the time of installation.',
        ],
      },
    ],
    end: {
      title: 'CONNECTIONS HAVE NEVER BEEN SO EASY.',
      text: 'Intuitive handling meets advanced technology; discover the seamless connection of the future. fixLink.',
    },
    categoryData: {
      modules: 'Connection modules',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-Modul Kat. 6A ISO/IEC, geschirmt',
        text: 'With the fixLink<sup>®</sup> Pro RJ45 Keystone module, users receive an RJ45 socket module of the highest quality. The fixLink<sup>®</sup> Pro RJ45 Keystone module can be assembled with side cutters in under a minute. Thanks to the freely accessible contact zone, perfect assembly quality and thus guaranteed transmission performance is ensured. The special contact geometry in conjunction with a 30 μ" gold plating ensures 4PPoE according to IEEE 802.3bt under all conditions. The housing material is made of nickel-plated zinc die-cast, thus meeting the highest environmental and mechanical requirements. Combined with the design-capable module holder, a combination with all switch programs is possible. They are delivered in an environmentally friendly cardboard packaging.',
        ghmtCertificates: [
          {
            name: 'PVP Cat.6A Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanent Link Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, shielded',
        text: 'With the fixLink<sup>®</sup> Extender, cables can be extended without much effort. Furthermore, decommissioned lines can be reactivated and extended to the next required connection. The scarce space in the cable duct is thus optimally used. The extender is simply screwed into the duct or onto the wall at the end of the installed cable. After installation, the extended line can be used for 10-gigabit Ethernet transmissions up to the maximum link length and supports PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC shielded',
        text: 'With the fixLink<sup>®</sup> RJ45 plug module, users receive a field-assemblable RJ45 plug module of the highest quality and flexibility. The special contact geometry in conjunction with a 50 μ" gold plating ensures 4PPoE according to IEEE 802.3bt under all conditions. The housing material is made of nickel-plated zinc die-cast, thus meeting the highest environmental and mechanical requirements. Various certifications (current listing: www.easylan.de) from independent testing laboratories round off the highest quality claim of the fixLink<sup>®</sup> system.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-Modul Kat. 6A EIA/TIA, unshielded',
        text: 'With the fixLink<sup>®</sup> RJ45 Keystone module (PoE+ capable), users receive an RJ45 socket module of the highest quality. The fixLink<sup>®</sup> RJ45 Keystone module can be assembled with side cutters in under a minute. Thanks to the freely accessible contact zone, perfect assembly quality and thus guaranteed transmission performance is ensured. The housing material is made of polycarbonate, thus meeting the highest environmental and mechanical requirements. Combined with the design-capable module holder, a combination with all switch programs is possible. Various certifications from independent testing laboratories round off the highest quality claim of the fixLink<sup>®</sup> SL Keystone module. They are delivered in an environmentally friendly cardboard packaging.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-Modul Kat. 6A ISO/IEC, shielded',
        text: 'With the fixLink<sup>®</sup> SL RJ45 Keystone module, users receive an RJ45 socket module of the highest quality. The fixLink<sup>®</sup> SL RJ45 Keystone module can be assembled with side cutters in under a minute. Thanks to the freely accessible contact zone, perfect assembly quality and thus guaranteed transmission performance is ensured. For 360° shield removal, only the integrated wedge is locked, eliminating the time-consuming attachment of a cable tie. The special contact geometry in conjunction with a 50 μ" gold plating ensures 4PPoE according to IEEE 802.3bt under all conditions. The housing material is made of nickel-plated zinc die-cast, thus meeting the highest environmental and mechanical requirements. Combined with the design-capable module holder, a combination with all switch programs is possible. Various certifications from independent testing laboratories round off the highest quality claim of the fixLink<sup>®</sup> SL Keystone module. They are delivered in an environmentally friendly cardboard packaging.',
        ghmtCertificates: [
          {
            name: 'PVP Cat.6A Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Test report 4PoE according to DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanent Link Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-Modul angled Kat. 6A ISO/IEC, shielded',
        text: 'With the fixLink<sup>®</sup> SL RJ45 Keystone module, users receive an RJ45 socket module of the highest quality, specially designed for applications with limited space. Due to its 75° cable exit, the module is particularly suitable for use in data outlets and cavity cabling. A minimum installation depth of 27 mm allows for almost unlimited application diversity. The fixLink<sup>®</sup> SL RJ45 Keystone module can be assembled with side cutters in under a minute. Thanks to the freely accessible contact zone, perfect assembly quality and thus guaranteed transmission performance is ensured. The special contact geometry in conjunction with a 50 μ" gold plating ensures PoE+ according to IEEE 802.3at under all conditions. The housing material is made of nickel-plated zinc die-cast, thus meeting the highest environmental and mechanical requirements. Combined with the design-capable module holder, a combination with all switch programs is possible. Various certifications from independent testing laboratories round off the highest quality claim of the fixLink<sup>®</sup> SL Keystone module. They are delivered in an environmentally friendly cardboard packaging.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Cat.6A Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Tools and Accessories',
        text: [
          'Stripping tool for removing the cable jacket and the metal foils of a data cable. Color blue.',
          'Copper tape for fixing the braid shield. Pre-cut to 35 x 9 mm. Packaging unit: 20 pieces/bag, 1500 pieces/roll',
          'Colored dust protection covers for preLink® SL and fixLink® SL Keystone modules.',
          'Packaging unit: 50 pieces/poly bag',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Cat.6A Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Installation Cable',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'EasyLan® Gold S/FTP Data Cable AWG22/1 1500 MHz Cat. 7A',
        text: [
          'Data cable for transmitting analog and digital signals in primary, secondary, and tertiary cabling. Suitable for applications up to Class FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'GHMT Permanent Link Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Data Sheet',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'EasyLan® Silver S/FTP Data Cable AWG23/1 1200 MHz Cat. 7A',
        text: [
          'Data cable for transmitting analog and digital signals in primary, secondary, and tertiary cabling. Suitable for applications up to Class FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Certificate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Data Sheet',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'EasyLan® Bronze S/FTP Data Cable AWG23/1 1000 MHz Cat. 7',
        text: 'Data cable for transmitting analog and digital signals in primary, secondary, and tertiary cabling. Suitable for applications up to Class FA.',
        datasheets: [
          {
            name: 'Data Sheet',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Data outlets',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Surface-mount housing and frame for aforementioned module holders',
        text: [
          'Surface-mount housing IP20, 80x80x42mm for the installation of EasyLan module holders',
          'Intermediate frame for surface-mount housing to increase height by 10mm',
          'Single frame 80x80mm',
          'Double frame 80x150mm',
        ],
        datasheets: [
          {
            name: 'Surface-mount housing',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Depth extension for surface-mount housing',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'SL module holder, 45° outlet UP, BK, AP, BT 1-fold, 2-fold, 3-fold',
        text: 'The SL module holders are particularly suitable for installation in cable ducts (EK), for flush-mounting (UP), and for surface-mounting (AP). By breaking away two mounting tabs, floor tank mounting (BT) is also possible. The SL module holder can securely and permanently accommodate one to three EasyLan keystones. It has a large, centrally located labeling window for easy identification. The central plate (50x50 mm) and the frame (80x80 mm) are made of UV-resistant and extremely break-resistant plastic. This ensures a long-lasting and color-true appearance.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Designable module holder SL, 45° outlet UP, BK, AP, BT 1-fold, 2-fold',
        text: 'The design-capable module holder consists of a zinc die-cast frame. It is suitable for installation in a cable duct (EK), for flush-mounting (UP), or with a separate frame for surface-mounting (AP). By breaking away two mounting tabs, floor tank mounting (BT) is also possible. The design-capable data outlet can be combined with almost all available switch programs on the market. Due to the UAE compatibility, the design-capable data outlet does not accommodate a dust cover.',
        datasheets: [
          {
            name: '1-/2-way Keystone, without central plate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-Way Keystone, with central plate 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-way with central plate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Module holder IP44 surface-mounted, 1-fold, 2-fold',
        text: 'The robust surface-mount housing is suitable for the installation of two preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone modules. For the introduction of two cables with a diameter of 5.0 - 9.5mm each, there are two easily pierceable points at the back of the box. The module holder achieves protection class IP20 when plugged in, and IP44 when closed.',
      },
      DoseUPleer2xKS: {
        name: 'Module holder, straight outlet, 2-fold',
        text: 'The module holder with a straight Keystone outlet consists of a zinc die-cast frame and is suitable for installation in a cable duct (EK), for flush-mounting (UP), or with a separate frame for surface-mounting (AP).',
      },
      bodentank: 'Floor tank',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann floor tank systems UT3, UT4',
        text: 'The carrier plate replaces the device carriers and thus offers the maximum possible space in the cable feed. The angled feed and discharge allow for secure cable routing even at very low intermediate floors. A combination with mounting plates for 4x preLink<sup>®</sup>/fixLink<sup>®</sup> modules or 1x H.D.S. is provided. The mounting plates are fixed to the carrier plate with two screws, fields of the carrier plate not needed can be dust-proof covered with blind plates.',
        datasheets: [
          {
            name: 'Carrier plate for UT3 without mounting plate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Carrier plate for UT4 without mounting plate',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann floor tank carrier Basic UT3, UT4',
        text: 'The Keystone floor tank carrier replaces the device carrier and thus offers the maximum possible space in the cable feed. The angled feed and discharge allow for secure cable routing even at very low intermediate floors. The mounting plates are fixed to the carrier plate with two screws, fields of the floor tank carrier not needed can be dust-proof covered with blind plates. Using an adapter, the floor tank carrier can also be used for Electraplan device carriers.',
        datasheets: [
          {
            name: 'Floor tank carrier UT3 for up to 6 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Floor tank carrier UT4 for up to 9 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Floor tank carrier UT4 for up to 12 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK floor tank carrier Basic UT4',
        text: 'The Keystone floor tank carrier replaces the device carrier and thus offers the maximum possible space in the cable feed. The angled feed and discharge allow for secure cable routing even at very low intermediate floors. The mounting plates are fixed to the carrier plate with two screws, fields of the floor tank carrier not needed can be dust-proof covered with blind plates. The floor tank carriers are compatible with the PUK floor tank system.',
        datasheets: [
          {
            name: 'Floor tank carrier UT4 for up to 9 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Floor tank carrier UT4 for up to 12 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann inserts for floor tank systems UT3, UT4, accessories',
        text: 'The inserts for modules are unpowdered and hot-dip galvanized for grounding reasons. The mounting plates are fixed to the carrier plate with two screws, fields of the carrier plate not needed can be dust-proof covered with blind plates.',
        datasheets: [
          {
            name: 'Mounting plate for 4x RJ45 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Mounting plate for 3x RJ45 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'DIN rail adapters',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Compact DIN rail module housing for 1 module',
        text: 'DIN rail module housing for preLink<sup>®</sup> / fixLink<sup>®</sup> modules, suitable for distribution cabinets without cover for circuit breakers.',
        datasheets: [
          {
            name: 'Compact DIN rail module housing for 1 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone F-coupling and blank cover Keystone empty module',
        text: 'F-coupling and blank cover as module set\nThe F-coupling is used to integrate coaxial cables into the preLink<sup>®</sup> / fixLink<sup>®</sup> patch panels and into the outlet program. The preLink<sup>®</sup>/fixLink<sup>®</sup> blank cover can be used in both the distribution field and the outlets to cover unused ports.\n\nEmpty module for SC-S or LC-D as module set\nEmpty module for SC-S or LC-D couplings in distribution field or outlet.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'DIN rail module housing for 1 module',
        text: 'DIN rail module housing for preLink<sup>®</sup> / fixLink<sup>®</sup> modules, suitable for distribution cabinets with covers for circuit breakers.',
        datasheets: [
          {
            name: 'DIN rail module housing for 1 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1U for 24 Keystone modules straight outlet',
        text: 'The distribution panel 19" 1U can be equipped with up to 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modules. It is built in two levels, thus the modules are flush in the front panel. The distribution panel can be ordered either in stainless steel or powder-coated sheet steel.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1U for 48 Keystone modules, straight outlet',
        text: 'The 19" 1U distribution panel can be connected with up to 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 keystone modules. It is constructed on two levels, so the modules are flush the front panel. The distribution panel is made of powder-coated sheet iron.\nThe mounting material and the keystone modules are not included in the scope of delivery.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0.5U for 24 Keystone modules straight outlet',
        text: 'The preLink<sup>®</sup> / fixLink<sup>®</sup> distribution panel 19" 0.5U can be equipped with up to 24 fixLink<sup>®</sup> RJ45 Keystone modules. They are flush in the front panel.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1U for 24 Keystone modules including labeling window',
        text: 'The distribution panel 19" 1U can be equipped with up to 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modules. It is built in two levels, thus the modules are flush in the front panel. The distribution panel can be ordered either in stainless steel or powder-coated sheet steel. Additionally, it is possible to label the individual ports using a provided, 12x33 punched labeling sheet.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Angled panel 30° 19" 1U for 24 Keystone modules',
        text: 'With the preLink<sup>®</sup> / fixLink<sup>®</sup> angled panel, the user has the option to snap 24 Keystone modules into the panel at a 30° angle, causing the connected patch cables to automatically run to the side of the cabinet and only need to be secured beside the patch level. This allows the user to dispense with additional cable management panels. In operation, the angled panels with the flat front side and the angled patch cables also require less space towards the front than conventional patch panels with vertically installed ports.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      baugruppentraeger: 'Module carrier',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Module carrier 19" 3U for the use of plug-in modules',
        text: 'Module carrier 19" 3U for the use of 7TE plug-in modules in copper and fiber. They are fixed to the module carrier with 2 screws from the panel side. The plug-in modules can be completely removed from the module carrier. A maximum of 12x 7TE plug-in modules can be attached in the module carrier.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Keystone plug-in module for module carrier',
        text: 'Keystone plug-in module for equipping module carriers. The Keystone plug-in module can be equipped with up to 6 preLink<sup>®</sup> or fixLink<sup>®</sup> Keystone modules. The Keystone plug-in module is built in two levels, thus the modules are flush to the front panel. Cable retention is directly on the plug-in module housing. 6 individual cables or 1 trunk cable can be secured.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Module carrier (BGT) 19" 1U for the use of plug-in modules',
        text: 'Module carrier (BGT) for the use of 7TE plug-in modules in copper and fiber optic. They are fixed to the module carrier with 2 screws from the panel side. The plug-in modules can be completely removed from the module carrier. A maximum of 3x 7TE plug-in modules (=18 ports) can be attached in the module carrier.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Side module carrier 3U/14TE for the use of plug-in modules',
        text: 'Side module carrier (S-BGT) for the use of 7TE plug-in modules in copper and fiber optic. The plug-in modules are fixed to the module carrier with 2 screws from the panel side. The plug-in modules can be completely removed from the module carrier. A maximum of 2x 7TE plug-in modules (=12 ports) can be attached in the module carrier. The side module carrier (S-BGT) is mounted on the side cabinet punching. This allows for an easy and fast installation. The side cabinet mounting makes it possible to realize space-saving, especially climate-friendly cabling without disturbing the airflow.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      consolidation_point_panel: 'Consolidations Point Panel, DIN rails etc.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Underfloor Consolidation Point Panel 19" or 10" 1U',
        text: 'Underfloor Consolidation Point Panel with interchangeable front plates for accommodating a wide variety of connections in copper and fiber optic. Optionally, the underfloor Consolidation Point Panel can be secured against unauthorized access with a lock.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
        datasheets: [
          {
            name: 'Consolidation Point Panel 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Consolidation Point Panel 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Surface-mount Consolidation Point Panel for 4-12 Keystone modules',
        text: 'Surface-mount Consolidation Point Panel for preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modules, for use as a Consolidation Panel, direct mounting, or via mounting set on DIN rails in electrical distribution installations. The surface-mount Consolidation Point Panel is built in two levels. Thus, the modules are flush in the front panel.\n\nMounting material and Keystone modules are not included in the scope of delivery.',
      },
      trunkkabel: 'Trunk cable',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC distribution system copper trunk bundled cable, class EA ISO/IEC',
        text: 'The CTBC division system is suitable for flexible use in structured building cabling and in data centers. The cables bundled in a braided sleeve are factory-assembled with fixLink<sup>®</sup> Keystone modules and tested. The bundling can be done with both installation cables and flexible cables. The maximum length of the bundled cable is up to 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC distribution system copper trunk compact cable, class EA ISO/IEC',
        text: 'The CTCC division system is suitable for flexible use in structured building cabling and in data centers due to its minimal cable diameter and the correspondingly low fire load. The maximum length of the multicore cable varies depending on the type between 60-90 m. The division system as a 10-Gbit solution for data center cabling meets at least the requirements of category EA.',
      },
      hds_innengabel: 'H.D.S. Internal cable',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. Internal cable Cat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. Internal cable Cat. 7 S/FTP for easy cabinet-to-cabinet cabling in data centers and multiconnections from distribution rooms to sub-distributions. The cable structure consists of 24 pair-shielded cable elements, 4 pairs are combined into one stranding element. Each stranding element is labeled with a numbering tape for easier identification. The flexible central strain relief element provides particularly good protection for the cable during pulling. The jacket is made of a flexible LSOH material.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. Internal cable Cat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. Internal cable Cat. 7 S/FTP for easy cabinet-to-cabinet cabling in data centers and multiconnections from distribution rooms to sub-distributions. The cable structure consists of 24 pair-shielded cable elements, 4 pairs are combined into one stranding element. Each stranding element is labeled with a numbering tape for easier identification. The flexible central strain relief element provides particularly good protection for the cable during pulling. The jacket is made of a flexible LSOH material.',
      },
      generic: {
        download: 'Download',
      },
    },
  },

  imprint: {
    headline: 'Legal Notice',
  },

  dataProtection: {
    headline: 'Data Protection',
  },

  footer: {
    dataProtection: 'Data Protection',
    imprint: 'Legal Notice',
    contact: 'Contact',
  },

  UI: {
    autocomplete: {
      searchError: 'Search could not be performed.',
      searchEmpty: 'No results',
    },
    dropdown: {
      emptySelection: '--- please select ---',
    },
  },
}

export const langKey = 'EN'

const Default = {
  translation,
  langKey,
}

export default Default

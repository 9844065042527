import de from '../translations/de'
import en from '../translations/en'
import fr from '../translations/fr'
import ua from '../translations/ua'
import tr from '../translations/tr'
import tn from '../translations/tn'
import sr from '../translations/sr'
import sk from '../translations/sk'
import ru from '../translations/ru'
import ro from '../translations/ro'
import pl from '../translations/pl'
import md from '../translations/md'
import ma from '../translations/ma'
import hr from '../translations/hr'
import cz from '../translations/cz'
import bs from '../translations/bs'
import bg from '../translations/bg'
import al from '../translations/al'

type Translation = { [key: string]: any }

export type Lang = {
  langKey: LangKey
  translation: Translation
}

export type LangKey = string

export type DefaultState = {
  TRANSLATION: Translation
  CURRENTLANG: LangKey
  LANGUAGES: Lang[]
}

const defaultState: DefaultState = {
  TRANSLATION: de.translation,
  CURRENTLANG: de.langKey,
  LANGUAGES: [de, en, fr, ua, tr, tn, sr, sk, ru, ro, pl, md, ma, hr, cz, bs, bg, al],
}

export default defaultState

import { IBaseTheme, IComponentPackageStyling } from './theme.d'

const red = 'rgb(248,19,65)'
const petrol = 'rgb(0,170,169)'

const theme: IBaseTheme = {
  font: {
    size: {
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '28px',
      xlg: '38px',
    },
  },

  color: {
    font: {
      primary: '#000000',
      secondary: 'white',
      danger: red,
      highlight: petrol,
    },
    background: {
      primary: 'rgb(34,33,33)',
      secondary: 'white',
      dark: 'rgb(34,33,33)',
      light: 'rgb(238,238,238)',
      danger: red,
      highlight: petrol,
      subMenu: 'rgb(142,142,142)',
      subMenuHint: 'rgb(240,240,240)',
      subMenuHintInverted: 'rgba(255,255,255, 0.3)',
    },
    border: {
      primary: 'rgb(34,33,33)',
      secondary: 'white',
    },
    gray: {
      primary: 'rgb(152, 175, 186)',
      secondary: 'rgb(215,222,226)',
      thirdary: 'rgb(245,245,245)',
    },
    headerLine: {
      blue: 'rgb(0,149,248)',
      red,
      darkred: 'rgb(42,6,22)',
      petrol,
    },
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1320px',
  },
  border: {
    radius: '20px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
  headerHeight: '100px',
  headerHeightMobile: '75px',
  productHeaderHeight: '65px',
  subMenuHeight: '50px',
  subMenuHintHeight: '100px',
}

const adjustedComponentStyling: IComponentPackageStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: ``,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: ``,
    title: ``,
    hint: ``,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `width: 100%;
    `,
    select: ``,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: ``,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: ``,
    background: ``,
    core: ``,
    header: ``,
    title: `
    font-family: bold;
    `,
    close: ``,
    content: `
    `,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: ``,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
}

const exportableTheme = { ...theme, components: adjustedComponentStyling }

export default exportableTheme

import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import useGetSelectedCategoryItem from '../useGetSelectedCategoryItem'
import Embed from './Embed'
// import QR from 'qrcode.react'

const Video = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const selectedCategoryItem = useGetSelectedCategoryItem()

  if (!selectedCategoryItem || (!selectedCategoryItem.item.videoEmbedLinks && !selectedCategoryItem.item.manual)) return <></>
  return (
    <div className="bg-white" id="montage">
      <Container>
        <div className="flex flex-col lg:flex-row pt-[15vh] gap-5 lg:gap-10">
          <div className="lg:w-2/6">
            <div className="text-danger mt-1">{selectedCategoryItem.item.name}</div>
            <div className="text-lg font-bold mt-1 leading-8">{t.easylan.video.title}</div>
            {selectedCategoryItem.item.manual && (
              <>
                <div className="mt-4">{t.easylan.video.text}</div>
                <a className="mt-4 text-danger w-fit inline-block uppercase" href="/" target="_blank">
                  {t.easylan.video.manual.title}
                </a>
                <div className="flex flex-row gap-3 mt-2">
                  {Object.keys(selectedCategoryItem.item.manual).map((v: string, i: number) => (
                    <a
                      key={i}
                      href={selectedCategoryItem.item.manual[v]}
                      target="_blank"
                      rel="noreferrer"
                      className={`cursor-pointer text-white w-fit p-1 px-2 text-sm text-center shadow-md bg-dark`}
                    >
                      {v}
                    </a>
                  ))}
                </div>
              </>
            )}
            {/* <QR
              id="qrcode-canvas"
              value={'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}
              size={125}
              bgColor={'transparent'}
              fgColor={'#000000'}
              level={'H'}
              className="mt-4 hidden lg:block"
            /> */}
          </div>
          <div className="lg:w-4/6">
            <div className="flex flex-col gap-3">
              {selectedCategoryItem.item.videoEmbedLinks &&
                selectedCategoryItem.item.videoEmbedLinks.map((video: string, i: number) => (
                  <Embed key={i} disableCover={selectedCategoryItem.item.videoEmbedLinks.length > 1} video={video} />
                ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Video

export const translation = {
  header: {
    items: [
      {
        name: 'Acasă',
        href: '/',
      },
      {
        name: 'Sistem',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montaj',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Limbă',
  },
  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Simplitate',
        text2: 'în conexiune',
      },
      subtitle: 'Link-ul tău pentru viitor',
      cta: 'mai mult',
    },
    handling: {
      title: 'MANIPULARE INTUITIVĂ ÎNTÂLNEȘTE TEHNOLOGIA AVANSATĂ',
      vorreiterPrefix: 'Combinația dintre precizie și design funcțional face ca ',
      vorreiterSuffix: ' să fie alegerea principală în tehnologia de rețea.',
      experte: ' oferă soluții eficiente de 360°, care combină designul și performanța optimă pentru a minimiza complexitatea.',
      pionier: ' este un pionier în reconfigurarea spațiilor de lucru, îmbinând expertiza cu manipularea intuitivă.',
    },
    download: {
      prefix: 'Prezentare generală ',
      suffix: ' - Descărcați sistemul',
      cta: 'Descărcare',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Prezentare produs',
      title: 'FIXLINK<sup>®</sup> - SISTEM',
    },
    module: {
      overview: 'Descărcați prezentarea generală',
      accordion: {
        ghmtCertificates: {
          title: 'Certificări GHMT',
          text: 'Ne bazăm pe GHMT și pe Programul său de Verificare Premium pentru asigurarea independentă a calității produselor noastre de rețea. Astfel, garantăm cele mai înalte standarde de produs pentru clienții noștri, bazându-ne pe 20 de ani de expertiză.',
        },
        datasheets: {
          title: 'Fișe tehnice',
          text: 'Aici găsiți toate fișele tehnice necesare. Descărcați-le și aveți toate informațiile la îndemână.',
        },
        compatibilitySheets: {
          title: 'Liste de compatibilitate',
          text: '',
        },
        excelTemplates: {
          title: 'Ferestre de etichetare ca șablon Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'INSTRUCȚIUNI DE MONTAJ',
      text: 'Vizionați videoclipul sau descărcați aici simplu PDF-ul',
      manual: {
        title: 'INSTRUCȚIUNI DE MONTAJ:',
      },
    },
    separator: {
      title: 'Mai mult decât o conexiune',
      title2: 'Descoperă universul EasyLan',
      text: 'Descoperă diversitatea soluțiilor inovatoare de rețea pe EasyLan.de: Personalizate pentru tine. Schimbătorul de joc pentru rețeaua ta: Sistemele noastre de cablare și soluțiile de management al cablurilor.',
      cta: 'mai mult',
    },
    combination: {
      bau: {
        prefix: 'Experimentează ajustarea maximă și calitatea,',
        text: ' Tragerul de module EasyLan ',
        suffix: 'direct din Europa.',
      },
      intelligent: {
        prefix: 'Rețeaua ta',
        text: ' personalizată ',
        suffix: 'cu cutia de splicing configurabilă EasyLan',
      },
      fixlink: {
        prefix: 'Cu produsele noastre ',
        suffix: ' puteți îmbunătăți pe termen lung clădirile de birouri.',
      },
      hds: {
        prefix: 'Sistemul',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: 'îmbină ușurința instalării și a întreținerii ca niciun alt sistem de cablare trunchiată de pe piață.',
      },
    },
    recommended: {
      titleLabel: 'Recomandate',
      title: 'fixLink<sup>®</sup> - SISTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor sit' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor sit' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor sit' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor sit' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor sit' },
      ],
    },
    guarantee: [
      {
        title: 'Garanție produs',
        text: [
          'Garanția produsului include toate componentele unui ',
          '- sistem de cablare din fibră optică sau cupru, instalate și testate de un instalator calificat în conformitate cu specificațiile de la ',
          ' și normele de instalare valabile EN50174.',
        ],
      },
      {
        title: 'Garanție de performanță',
        text: [
          'Garanția de performanță garantează, pe lângă aplicație (Ethernet, Fiber Channel, ATM etc.), caracteristicile sistemului, inclusiv vitezele de transmisie (10MBit, 100MBit, 1GBit și 10GBit).',
        ],
      },
      {
        title: 'Garanție aplicații',
        text: [
          'Garanția aplicațiilor garantează toate aplicațiile specificate (Ethernet, Fiber Channel, ATM etc.) care se bazează pe standardele de cablare în vigoare la momentul instalării conform ISO/IEC 11801, EN50173 și EIA/TIA 568B.',
        ],
      },
    ],
    end: {
      title: 'CONEXIUNILE NU AU FOST NICIODATĂ ATÂT DE SIMPLE.',
      text: 'Manipulare intuitivă întâlnește tehnologia avansată; descoperă conexiunea perfectă a viitorului: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Module de conectare',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Modul Pro RJ45 Keystone Cat. 6A ISO/IEC, ecranat',
        text: 'Cu modulul fixLink<sup>®</sup> Pro RJ45 Keystone, utilizatorul obține un modul de priză RJ45 de cea mai înaltă calitate. Modulul fixLink<sup>®</sup> Pro RJ45 Keystone poate fi asamblat în mai puțin de un minut folosind un cutter lateral. Datorită zonei de contact accesibile, se asigură o calitate perfectă a asamblării și, prin urmare, o performanță sigură a transmisiei. Geometria specială a contactului în combinație cu un strat de aur de 30 μ" asigură 4PPoE conform IEEE 802.3bt în orice condiții. Materialul carcasei este realizat din zinc turnat placat cu nichel, îndeplinind cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul cu design, este posibilă o combinație cu toate programele de comutatoare. Ele sunt livrate într-un ambalaj de carton ecologic.',
        ghmtCertificates: [
          {
            name: 'Certificat PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Certificat Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Cat. 6A ISO/IEC, ecranat',
        text: 'Cu extender-ul fixLink<sup>®</sup>, cablurile pot fi prelungite fără prea mult efort. În plus, cablurile dezafectate pot fi reactivate și prelungite până la următorul punct de conexiune necesar. Spațiul limitat în canalul de cablu este astfel utilizat optim. Extender-ul este pur și simplu înșurubat la capătul cablului instalat în canal sau pe perete. După instalare, cablul prelungit astfel poate fi utilizat pentru transmisii Ethernet de până la 10 gigabiți și suportă PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'Plug RJ45 Cat. 6A ISO/IEC ecranat',
        text: 'Cu modulul de conectare fixLink<sup>®</sup> RJ45, utilizatorul obține un modul de priză RJ45 de cea mai înaltă calitate și flexibilitate. Geometria specială a contactului în combinație cu un strat de aur de 50 μ" asigură 4PPoE conform IEEE 80.3bt în orice condiții. Materialul carcasei este realizat din zinc turnat placat cu nichel, îndeplinind cele mai înalte cerințe de mediu și mecanice. Diverse certificări (listă actuală: www.easylan.de) de la laboratoare de testare independente completează cerințele de calitate maximă ale sistemului fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'Modul RJ45 Keystone Cat. 6A EIA/TIA, neecranat',
        text: 'Cu modulul fixLink<sup>®</sup> RJ45 Keystone (compatibil PoE+), utilizatorul obține un modul de priză RJ45 de cea mai înaltă calitate. Modulul fixLink<sup>®</sup> RJ45 Keystone poate fi asamblat în mai puțin de un minut folosind un cutter lateral. Datorită zonei de contact accesibile, se asigură o calitate perfectă a asamblării și, prin urmare, o performanță sigură a transmisiei. Materialul carcasei este realizat din policarbonat, îndeplinind cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul cu design, este posibilă o combinație cu toate programele de comutatoare. Diverse certificări de la laboratoare de testare independente completează cerințele de calitate maximă ale modulului fixLink<sup>®</sup> SL Keystone. Ele sunt livrate într-un ambalaj de carton ecologic.',
      },
      fixLink_SL_02: {
        name: 'Modul RJ45 Keystone SL Cat. 6A ISO/IEC, ecranat',
        text: 'Cu modulul fixLink<sup>®</sup> SL RJ45 Keystone, utilizatorul obține un modul de priză RJ45 de cea mai înaltă calitate. Modulul fixLink<sup>®</sup> SL RJ45 Keystone poate fi asamblat în mai puțin de un minut folosind un cutter lateral. Datorită zonei de contact accesibile, se asigură o calitate perfectă a asamblării și, prin urmare, o performanță sigură a transmisiei. Pentru o preluare completă a ecranului la 360°, este suficient să se fixeze clema integrată, eliminând necesitatea utilizării unei legături de cablu. Geometria specială a contactului în combinație cu un strat de aur de 50 μ" asigură 4PPoE conform IEEE 802.3bt în orice condiții. Materialul carcasei este realizat din zinc turnat placat cu nichel, îndeplinind cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul cu design, este posibilă o combinație cu toate programele de comutatoare. Diverse certificări de la laboratoare de testare independente completează cerințele de calitate maximă ale modulului fixLink<sup>®</sup> SL Keystone. Ele sunt livrate într-un ambalaj de carton ecologic.',
        ghmtCertificates: [
          {
            name: 'Certificat PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Raport de testare 4PoE conform DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'Certificat Permanent Link GHMT',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'Modul RJ45 Keystone SL Cat. 6A ISO/IEC, ecranat, unghiular',
        text: 'Cu modulul fixLink<sup>®</sup> SL RJ45 Keystone, utilizatorul obține un modul de priză RJ45 de cea mai înaltă calitate, special dezvoltat pentru aplicații cu spațiu limitat. Datorită ieșirii de cablu la un unghi de 75°, modulul este potrivit în special pentru utilizarea în prize de date și cablaje în perete gol. Adâncimea minimă de instalare de 27 mm permite o diversitate aproape nelimitată de aplicații. Modulul fixLink<sup>®</sup> SL RJ45 Keystone poate fi asamblat în mai puțin de un minut folosind un cutter lateral. Datorită zonei de contact accesibile, se asigură o calitate perfectă a asamblării și, prin urmare, o performanță sigură a transmisiei. Geometria specială a contactului în combinație cu un strat de aur de 50 μ" asigură PoE+ conform IEEE 80.3at în orice condiții. Materialul carcasei este realizat din zinc turnat placat cu nichel, îndeplinind cele mai înalte cerințe de mediu și mecanice. În combinație cu suportul de modul cu design, este posibilă o combinație cu toate programele de comutatoare. Diverse certificări de la laboratoare de testare independente completează cerințele de calitate maximă ale modulului fixLink<sup>®</sup> SL Keystone. Ele sunt livrate într-un ambalaj de carton ecologic.',
        ghmtCertificates: [
          {
            name: 'Certificat PVP GHMT Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certificat Permanent Link GHMT',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Instrumente și accesorii',
        text: [
          'Instrument de dezizolat pentru îndepărtarea mantalei exterioare și a foliilor metalice ale unui cablu de date. Culoare albastră.',
          'Bandă de cupru pentru fixarea ecranului. Tăiată în prealabil la 35 x 9 mm. Unitatea de ambalare: 20 bucăți/pungă 1500 bucăți/rolă',
          'Capace de protecție împotriva prafului colorate pentru modulele preLink® SL și fixLink® SL Keystone.',
          'Unitate de ambalare 50 bucăți/pungă de plastic',
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Cablu de instalare',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Cablu de date EasyLan® Gold S/FTP AWG22/1 1500 MHz Cat. 7A',
        text: [
          'Cablu de date pentru transmiterea semnalelor analogice și digitale în cablajul din domeniile primar, secundar și terțiar. Potrivit pentru aplicații de până la clasa FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fișa tehnică',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Cablu de date EasyLan® Silber S/FTP AWG23/1 1200 MHz Cat. 7A',
        text: [
          'Cablu de date pentru transmiterea semnalelor analogice și digitale în cablajul din domeniile primar, secundar și terțiar. Potrivit pentru aplicații de până la clasa FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fișa tehnică',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Cablu de date EasyLan® Bronze S/FTP AWG23/1 1000 MHz Cat. 7',
        text: 'Cablu de date pentru transmiterea semnalelor analogice și digitale în cablajul din domeniile primar, secundar și terțiar. Potrivit pentru aplicații de până la clasa FA.',
        datasheets: [
          {
            name: 'Fișa tehnică',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Prize de date',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Carcasă și cadru de suprafață pentru suporturile de module menționate anterior',
        text: [
          'Carcasă de suprafață IP20, 80x80x42mm pentru instalarea suporturilor de module EasyLan',
          'Cadru intermediar pentru carcasa de suprafață pentru extinderea înălțimii cu 10mm',
          'Cadru de acoperire 1-port 80x80mm',
          'Cadru de acoperire 2-port 80x150mm',
        ],
        datasheets: [
          {
            name: 'Carcasă de suprafață',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Extinderea adâncimii carcasei de suprafață',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Suport de module SL, ieșire la 45°, UP, BK, AP, BT 1-port, 2-port, 3-port',
        text: 'Suporturile de module SL sunt deosebit de potrivite pentru instalarea în canalul de cablu (EK), montajul sub tencuială (UP) și pentru montajul de suprafață (AP). Prin ruperea a două urechi de montaj, este posibilă și montarea în tancul de podea (BT). Suportul de module SL poate găzdui unul până la trei module Keystone EasyLan absolut sigur și durabil. Dispune de o fereastră mare de etichetare centrală pentru o identificare ușoară. Placa centrală (50x50 mm) și cadrul (80x80 mm) sunt realizate din plastic rezistent la UV și foarte rezistent la impact. Astfel, se asigură un aspect durabil și fidel culorii.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Suport de module designabil SL, ieșire la 45°, UP, BK, AP, BT 1-port, 2-port',
        text: 'Suportul de module designabil este realizat dintr-un cadru de zinc turnat. Este potrivit pentru instalarea într-un canal de cablu (EK), pentru montajul sub tencuială (UP) sau cu un cadru separat și pentru montajul de suprafață (AP). Prin ruperea a două urechi de montaj, este posibilă și montarea în tancul de podea (BT). Priza de date designabilă este compatibilă cu aproape toate programele de comutatoare disponibile pe piață. Datorită compatibilității UAE, priza de date designabilă nu oferă spațiu pentru o clapetă de protecție împotriva prafului.',
        datasheets: [
          {
            name: '1-/2-port Keystone, fără placă centrală',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-port Keystone, cu placă centrală 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-port cu placă centrală',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Suport de module IP44 montaj de suprafață, 1-port, 2-port',
        text: 'Carcasa robustă de suprafață este potrivită pentru instalarea a două module preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone prin intermediul unui adaptor. Pentru introducerea a două cabluri cu diametrul de 5,0 - 9,5mm fiecare, pe spatele carcasei se află două puncte ușor de străpuns. Suportul de module atinge clasa de protecție IP20 în stare conectată, iar în stare închisă clasa de protecție IP44.',
      },
      DoseUPleer2xKS: {
        name: 'Suport de module cu ieșire dreaptă, 2-port',
        text: 'Suportul de module cu ieșire dreaptă Keystone este realizat dintr-un cadru de zinc turnat și este potrivit pentru instalarea într-un canal de cablu (EK), pentru montajul sub tencuială (UP) sau cu un cadru separat și pentru montajul de suprafață (AP).',
      },
      bodentank: 'Tanc de podea',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Sisteme de tanc de podea OBO/Ackermann UT3, UT4',
        text: 'Placa de transport înlocuiește suportul de dispozitiv și oferă astfel cel mai mare spațiu posibil pentru cabluri. Introducerea și evacuarea înclinate asigură o conducere sigură a cablurilor chiar și în caz de podele intermediare foarte joase. Este prevăzută o combinație cu plăci de instalare pentru 4x module preLink<sup>®</sup>/fixLink<sup>®</sup> sau 1x H.D.S. Plăcile de instalare sunt fixate pe placa de transport cu două șuruburi, iar câmpurile neutilizate ale plăcii de transport pot fi acoperite cu plăci oarbe pentru protecție împotriva prafului.',
        datasheets: [
          {
            name: 'Placă de transport pentru UT3 fără placă de instalare',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Placă de transport pentru UT4 fără placă de instalare',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Suport de tanc de podea OBO/Ackermann Basic UT3, UT4',
        text: 'Suportul Keystone pentru tanc de podea înlocuiește suportul de dispozitiv și oferă astfel cel mai mare spațiu posibil pentru cabluri. Introducerea și evacuarea înclinate asigură o conducere sigură a cablurilor chiar și în caz de podele intermediare foarte joase. Plăcile de instalare sunt fixate pe placa de transport cu două șuruburi, iar perforațiile neutilizate ale suportului pentru tanc de podea pot fi acoperite cu plăci oarbe pentru protecție împotriva prafului. Prin intermediul unui adaptor, suportul pentru tanc de podea poate fi utilizat și pentru suporturi de dispozitive Electraplan.',
        datasheets: [
          {
            name: 'Suport de tanc de podea UT3 pentru până la 6 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Suport de tanc de podea UT4 pentru până la 9 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Suport de tanc de podea UT4 pentru până la 12 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Suport de tanc de podea PUK Basic UT4',
        text: 'Suportul Keystone pentru tanc de podea înlocuiește suportul de dispozitiv și oferă astfel cel mai mare spațiu posibil pentru cabluri. Introducerea și evacuarea înclinate asigură o conducere sigură a cablurilor chiar și în caz de podele intermediare foarte joase. Plăcile de instalare sunt fixate pe placa de transport cu două șuruburi, iar perforațiile neutilizate ale suportului pentru tanc de podea pot fi acoperite cu plăci oarbe pentru protecție împotriva prafului. Suporturile pentru tanc de podea sunt compatibile cu sistemul de tanc de podea PUK.',
        datasheets: [
          {
            name: 'Suport de tanc de podea UT4 pentru până la 9 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Suport de tanc de podea UT4 pentru până la 12 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Inserții OBO/Ackermann pentru sisteme de tanc de podea UT3, UT4, accesorii',
        text: 'Inserțiile pentru module sunt neacoperite și galvanizate pentru motive de împământare. Plăcile de instalare sunt fixate pe placa de transport cu două șuruburi, iar câmpurile neutilizate ale plăcii de transport pot fi acoperite cu plăci oarbe pentru protecție împotriva prafului.',
        datasheets: [
          {
            name: 'Placă de instalare pentru 4x module RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Placă de instalare pentru 3x module RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adaptor pentru șină de montare',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Carcasă compactă pentru modul pe șină de montare pentru 1 modul',
        text: 'Carcasă pentru modul pe șină de montare pentru module preLink<sup>®</sup> / fixLink<sup>®</sup>, potrivită pentru dulapuri de distribuție fără capac pentru automate de siguranță.',
        datasheets: [
          {
            name: 'Carcasă compactă pentru modul pe șină de montare pentru 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Adaptor F-Keystone și capac orb pentru modul Keystone gol',
        text: 'Adaptor F-Keystone și capac orb ca înlocuitor de modul\nAdaptorul F-Keystone servește pentru integrarea cablurilor coaxiale în patchpanourile preLink<sup>®</sup> / fixLink<sup>®</sup> și în programul de prize. Capacul orb preLink<sup>®</sup>/fixLink<sup>®</sup> poate fi utilizat atât în câmpul de distribuție, cât și în prize pentru acoperirea porturilor neutilizate.\n\nModul gol pentru SC-S sau LC-D ca înlocuitor de modul\nModul gol pentru cuplaje SC-S sau LC-D în câmpul de distribuție sau priză.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Carcasă pentru modul pe șină de montare pentru 1 modul',
        text: 'Carcasă pentru modul pe șină de montare pentru module preLink<sup>®</sup> / fixLink<sup>®</sup>, potrivită pentru dulapuri de distribuție cu capac pentru automate de siguranță.',
        datasheets: [
          {
            name: 'Carcasă pentru modul pe șină de montare pentru 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panou',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panou 19" 1U pentru 24 module Keystone cu ieșire dreaptă',
        text: 'Câmpul de distribuție 19" 1U poate fi echipat cu până la 24 module preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone. Este construit în două niveluri, astfel încât modulele sunt la nivelul plăcii frontale. Câmpul de distribuție poate fi comandat fie din oțel inoxidabil, fie din tablă de oțel acoperită cu pulbere.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panou 19" 1U pentru 48 module Keystone cu ieșire dreaptă',
        text: 'Câmpul de distribuție 19" 1U poate fi echipat cu până la 48 module fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone. Este construit în două niveluri, astfel încât modulele sunt la nivelul plăcii frontale. Câmpul de distribuție este realizat din tablă de oțel acoperită cu pulbere.\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panou 19" 0,5U pentru 24 module Keystone cu ieșire dreaptă',
        text: 'Câmpul de distribuție preLink<sup>®</sup> / fixLink<sup>®</sup> 19" 0,5U poate fi echipat cu până la 24 module fixLink<sup>®</sup> RJ45 Keystone. Ele sunt la nivelul plăcii frontale.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panou 19" 1U pentru 24 module Keystone inclusiv fereastră de etichetare',
        text: 'Câmpul de distribuție 19" 1U poate fi echipat cu până la 24 module preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone. Este construit în două niveluri, astfel încât modulele sunt la nivelul plăcii frontale. Câmpul de distribuție poate fi comandat fie din oțel inoxidabil, fie din tablă de oțel acoperită cu pulbere. În plus, există posibilitatea de a eticheta fiecare port cu ajutorul unei foi de etichetare 12x33 inclusă.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Panou înclinat la 30° 19" 1U pentru 24 module Keystone',
        text: 'Cu panoul înclinat preLink<sup>®</sup> / fixLink<sup>®</sup>, utilizatorul are posibilitatea de a fixa 24 module Keystone sub un unghi de 30° în panou, astfel încât cablurile patch conectate curg automat spre partea laterală a dulapului și trebuie doar să fie fixate lateral lângă planul de patching. Astfel, utilizatorul poate renunța la panourile suplimentare de gestionare a cablurilor pentru ghidarea acestora. În funcționare, panourile înclinate cu partea frontală plană și cablurile patch înclinate necesită mai puțin spațiu în față decât panourile patch tradiționale cu porturi instalate vertical.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      baugruppentraeger: 'Suporturi de module',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Suport de module 19" 3U pentru utilizarea modulelor de inserție',
        text: 'Suport de module 19" 3U pentru utilizarea modulelor de inserție 7TE în cupru și fibră optică. Ele sunt fixate de partea frontală a modulului cu două șuruburi. Modulele de inserție pot fi scoase complet din suportul de module. Pot fi fixate până la 12 module de inserție 7TE în suportul de module.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Modul de inserție Keystone pentru suportul de module',
        text: 'Modul de inserție Keystone pentru echiparea suporturilor de module. Modulul de inserție Keystone poate fi echipat cu până la 6 module preLink<sup>®</sup> sau fixLink<sup>®</sup> Keystone. Modulul de inserție Keystone este construit în două niveluri, astfel încât modulele sunt la nivelul plăcii frontale. Fixarea cablurilor se realizează direct pe carcasa modulului de inserție. Pot fi fixate 6 cabluri individuale sau 1 cablu trunchiat.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Suport de module (BGT) 19" 1U pentru utilizarea modulelor de inserție',
        text: 'Suport de module (BGT) pentru utilizarea modulelor de inserție 7TE în cupru și LWL. Ele sunt fixate de partea frontală a modulului cu două șuruburi. Modulele de inserție pot fi scoase complet din suportul de module. Pot fi fixate până la 3 module de inserție 7TE (=18 porturi) în suportul de module.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Suport de module lateral 3U/14TE pentru utilizarea modulelor de inserție',
        text: 'Suport de module lateral (S-BGT) pentru utilizarea modulelor de inserție 7TE în cupru și LWL. Modulele de inserție sunt fixate de partea frontală a modulului cu două șuruburi. Modulele de inserție pot fi scoase complet din suportul de module. Pot fi fixate până la 2 module de inserție 7TE (=12 porturi) în suportul de module. Fixarea suportului de module lateral (S-BGT) se realizează pe perforația laterală a dulapului. Astfel, este posibilă o instalare simplă și rapidă. Prin montarea laterală a dulapului, este posibilă realizarea unei cablări economisitoare de spațiu și prietenoasă cu climatul, fără a perturba fluxul de aer.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      consolidation_point_panel: 'Panou punct de consolidare, șine DIN etc.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Panou de consolidare sub pardoseală 19" sau 10" 1U',
        text: 'Panou de consolidare sub pardoseală cu plăci frontale interschimbabile pentru a găzdui diverse conexiuni în cupru și LWL. Opțional, panoul de consolidare sub pardoseală poate fi asigurat împotriva accesului neautorizat cu un lacăt.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
        datasheets: [
          {
            name: 'Panou de consolidare 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Panou de consolidare 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Panou de consolidare montat pe suprafață pentru 4-12 module Keystone',
        text: 'Panou de consolidare montat pe suprafață pentru module preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone, utilizabil ca panou de consolidare, montare directă sau, de asemenea, prin setul de montare pe șină de montare în distribuțiile electrice. Panoul de consolidare montat pe suprafață este construit în două niveluri. Astfel, modulele sunt la nivelul plăcii frontale.\n\nMaterialul de montaj și modulele Keystone nu sunt incluse în pachet.',
      },
      trunkkabel: 'Cablu trunchiat',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'Sistem de împărțire CTBC Cablu-trunchiat-Cupru, Clasa EA ISO/IEC',
        text: 'Sistemul de împărțire CTBC este potrivit pentru utilizarea flexibilă în cablarea structurată a clădirilor și în centrele de date. Cablurile împachetate într-o manșon de împletitură sunt asamblate și testate din fabrică cu module Keystone fixLink<sup>®</sup>. Împachetarea poate fi realizată atât cu cabluri de instalare, cât și cu cabluri flexibile. Lungimea maximă a cablului împachetat este de până la 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'Sistem de împărțire CTCC Cablu-trunchiat-Compact, Clasa EA ISO/IEC',
        text: 'Sistemul de împărțire CTCC este potrivit datorită diametrului minim al cablului și a sarcinii de incendiu corespunzătoare reduse pentru utilizarea flexibilă în cablarea structurată a clădirilor și în centrele de date. Lungimea maximă a cablului multiplu este de 60-90 m, în funcție de tip. Sistemul de împărțire ca soluție de 10-Gbit pentru cablările RZ îndeplinește cel puțin cerințele clasei EA.',
      },
      hds_innengabel: 'Cablu interior H.D.S.',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'Cablu interior H.D.S. Cat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'Cablu interior H.D.S. Cat. 7 S/FTP pentru cablarea ușoară de la rack la rack în centrele de date și conexiuni multiple între camere de distribuție și subdistribuții. Structura cablului constă din 24 elemente de cablu ecranate pereche, fiecare 4 perechi fiind grupate într-un element de împletitură. Fiecare element de împletitură este marcat cu o bandă de identificare pentru identificare mai ușoară. Elementul de rezistență central flexibil protejează cablul în mod special la tragerea acestuia. Mantaua este realizată dintr-un material flexibil LSOH.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'Cablu interior H.D.S. Cat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'Cablu interior H.D.S. Cat. 7 S/FTP pentru cablarea ușoară de la rack la rack în centrele de date și conexiuni multiple între camere de distribuție și subdistribuții. Structura cablului constă din 24 elemente de cablu ecranate pereche, fiecare 4 perechi fiind grupate într-un element de împletitură. Fiecare element de împletitură este marcat cu o bandă de identificare pentru identificare mai ușoară. Elementul de rezistență central flexibil protejează cablul în mod special la tragerea acestuia. Mantaua este realizată dintr-un material flexibil LSOH.',
      },
      generic: {
        download: 'Descărcare',
      },
    },
  },

  imprint: {
    headline: 'Imprint',
  },

  dataProtection: {
    headline: 'Protecția datelor',
  },

  footer: {
    dataProtection: 'Protecția datelor',
    imprint: 'Imprint',
    contact: 'Contact',
  },

  UI: {
    autocomplete: {
      searchError: 'Căutarea nu a putut fi efectuată.',
      searchEmpty: 'Niciun rezultat',
    },
    dropdown: {
      emptySelection: '--- vă rugăm să alegeți ---',
    },
  },
}

export const langKey = 'RO'

const Default = {
  translation,
  langKey,
}

export default Default

import styled from 'styled-components'
import { Animated } from '../../helper/Animated/Animated.Styled'

export const SlideInContent = styled(Animated)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  margin-left: 15vw;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin: auto;
  }
`

export const ImageWrapper = styled(Animated)`
  text-align: center;
  position: relative;
`

export const Layer = styled.div<{ zIndex?: number; img?: string; centerContent?: boolean }>`
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  z-index: ${({ zIndex }) => zIndex || 1};
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center 20vh;
  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
  align-items: center;
  justify-content: center;
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: 90%;
    background-position: center 15vh;
  }
`

export const BackgroundImage = styled(Animated)<{ img: string; animation: string }>`
  background-image: ${({ img }) => `url(${img})`};
  height: 100vh;
  width: 101%;
  background-size: cover;
  background-position: center center;
`

import { useState } from 'react'
import Section from './Section/Section'

export type Item = { title: React.ReactNode | string; content: React.ReactNode | string }

type Props = {
  items: Item[]
  className?: string
  fitTitle?: boolean
}

const Accordion: React.FC<Props> = ({ items, className = '', fitTitle = false }) => {
  const [openIndex, setOpenIndex] = useState<number>(null)

  const toggle = (index: number) => () => setOpenIndex(openIndex === index ? null : index)

  return (
    <div className={className}>
      {items.map(({ title, content }, i) => (
        <Section
          key={i}
          open={openIndex === i}
          title={title}
          content={content}
          clickedCallback={toggle(i)}
          isLast={i === items.length - 1}
          isFirst={i === 0}
          fitTitle={fitTitle}
        />
      ))}
    </div>
  )
}

export default Accordion

export const translation = {
  header: {
    items: [
      {
        name: 'Accueil',
        href: '/',
      },
      {
        name: 'Système',
        href: '#system',
      },
      {
        name: 'Module',
        href: '#module',
      },
      {
        name: 'Montage',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Langue',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Simplicité',
        text2: 'dans la connexion',
      },
      subtitle: 'Votre lien pour l’avenir',
      cta: 'plus',
    },
    handling: {
      title: 'MANIPULATION INTUITIVE RENCONTRE LA TECHNOLOGIE AVANCÉE',
      vorreiterPrefix: 'La combinaison de précision et de design fonctionnel fait de ',
      vorreiterSuffix: ' le choix principal en technologie de mise en réseau.',
      experte: ' offre des solutions 360° efficaces qui combinent design et performance pour minimiser la complexité.',
      pionier: ' est un pionnier dans la transformation des espaces de travail, alliant expertise et manipulation intuitive.',
    },
    download: {
      prefix: 'Aperçu ',
      suffix: ' - Télécharger le système',
      cta: 'Télécharger',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Aperçu du produit',
      title: 'FIXLINK<sup>®</sup> - SYSTÈME',
    },
    module: {
      overview: "Télécharger l'aperçu",
      accordion: {
        ghmtCertificates: {
          title: 'Certificats GHMT',
          text: 'Nous faisons confiance à GHMT et à son programme de vérification Premium pour l’assurance qualité indépendante de nos produits de réseau. Nous garantissons ainsi, grâce à 20 ans d’expertise, les normes de qualité les plus élevées pour nos clients.',
        },
        datasheets: {
          title: 'Fiches techniques',
          text: 'Vous trouverez ici toutes les fiches techniques nécessaires. Téléchargez-les et ayez toutes les informations à portée de main.',
        },
        compatibilitySheets: {
          title: 'Listes de compatibilité',
          text: '',
        },
        excelTemplates: {
          title: 'Fenêtres d’étiquetage en modèle Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'GUIDE DE MONTAGE',
      text: 'Regardez la vidéo ou téléchargez simplement le PDF ici',
      manual: {
        title: 'GUIDES DE MONTAGE :',
      },
    },
    separator: {
      title: 'Plus que de la connexion',
      title2: 'Découvrez le cosmos EasyLan',
      text: 'Découvrez la diversité des solutions réseau innovantes sur EasyLan.de : Personnalisées pour vous. Le GameChanger pour votre réseau : Nos systèmes de câblage et solutions de gestion des câbles.',
      cta: 'plus',
    },
    combination: {
      bau: {
        prefix: 'Découvrez l’adaptation maximale et la qualité avec ',
        text: ' le châssis de groupe EasyLan ',
        suffix: 'directement d’Europe.',
      },
      intelligent: {
        prefix: 'Votre réseau',
        text: ' sur mesure ',
        suffix: 'avec la boîte de jonction configurable EasyLan',
      },
      fixlink: {
        prefix: 'Avec nos produits ',
        suffix: ' vous pouvez améliorer durablement vos bâtiments de bureaux.',
      },
      hds: {
        prefix: 'Le',
        text: ' système EasyLan<sup>®</sup> H.D.S. ',
        suffix: 'allie facilité d’installation et de maintenance comme aucun autre système de câblage trunk sur le marché.',
      },
    },
    recommended: {
      titleLabel: 'Recommandé',
      title: 'fixLink<sup>®</sup> - SYSTÈME',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Garantie Produit',
        text: [
          'La garantie produit comprend tous les composants d’un ',
          ' - système de câblage en fibre optique ou en cuivre, installés et testés par un installateur qualifié selon les directives de ',
          ' ainsi que les normes d’installation EN50174 en vigueur.',
        ],
      },
      {
        title: 'Garantie de Performance',
        text: [
          'La garantie de performance garantit, en plus de l’application (Ethernet, Fiber Channel, ATM, etc.), les caractéristiques du système, y compris les vitesses de transmission (10MBit, 100MBit, 1GBit et 10GBit).',
        ],
      },
      {
        title: 'Garantie d’Application',
        text: [
          'La garantie d’application garantit toutes les applications spécifiées (Ethernet, Fiber Channel, ATM, etc.) basées sur les normes de câblage en vigueur au moment de l’installation selon ISO/IEC 11801, EN50173 et EIA/TIA 568B.',
        ],
      },
    ],
    end: {
      title: 'LES CONNEXIONS N’ONT JAMAIS ÉTÉ SI FACILES.',
      text: 'Une manipulation intuitive rencontre une technologie avancée ; découvrez la connexion sans faille de l’avenir : fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Modules de connexion',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Module Pro RJ45 Keystone Cat. 6A ISO/IEC, blindé',
        text: 'Avec le module RJ45 Keystone fixLink<sup>®</sup> Pro, l’utilisateur obtient un module de prise RJ45 de la plus haute qualité. Le module RJ45 Keystone fixLink<sup>®</sup> Pro peut être confectionné en moins d’une minute à l’aide d’une pince coupante. Grâce à la zone de contact librement accessible, une qualité de confection parfaite et donc une performance de transmission assurée sont garanties. La géométrie de contact spéciale en combinaison avec un placage or de 30 μ" garantit 4PPoE selon IEEE 802.3bt dans toutes les conditions. Le matériau du boîtier est en zinc moulé sous pression nickelé et répond ainsi aux exigences environnementales et mécaniques les plus élevées. En combinaison avec le support de module designable, une combinaison avec tous les programmes d’interrupteurs est possible. Ils sont livrés dans un emballage carton respectueux de l’environnement.',
        ghmtCertificates: [
          {
            name: 'Certificat PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Certificat Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Cat. 6A ISO/IEC, blindé',
        text: 'Avec l’extendeur fixLink<sup>®</sup>, les câbles peuvent être prolongés sans effort. De plus, les câbles abandonnés peuvent être réactivés et prolongés jusqu’à la prochaine prise nécessaire. L’espace limité dans le canal de câblage est ainsi optimisé. L’extendeur est simplement vissé à la fin du câble installé dans le canal ou au mur. Après l’installation, le câble prolongé peut être utilisé jusqu’à la longueur maximale de liaison, même pour les transmissions Ethernet 10 Gigabit et prend en charge PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'Plug RJ45 Cat. 6A ISO/IEC blindé',
        text: 'Avec le module de prise RJ45 fixLink<sup>®</sup>, l’utilisateur obtient un module de prise RJ45 de la plus haute qualité et flexibilité. La géométrie de contact spéciale en combinaison avec un placage or de 50 μ" garantit 4PPoE selon IEEE 80.3bt dans toutes les conditions. Le matériau du boîtier est en zinc moulé sous pression nickelé et répond ainsi aux exigences environnementales et mécaniques les plus élevées. Diverses certifications (liste actuelle : www.easylan.de) de laboratoires d’essais indépendants complètent la plus haute exigence de qualité du système fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'Module RJ45 Keystone Cat. 6A EIA/TIA, non blindé',
        text: 'Avec le module RJ45 Keystone fixLink<sup>®</sup> (PoE+ capable), l’utilisateur obtient un module de prise RJ45 de la plus haute qualité. Le module RJ45 Keystone fixLink<sup>®</sup> peut être confectionné en moins d’une minute à l’aide d’une pince coupante. Grâce à la zone de contact librement accessible, une qualité de confection parfaite et donc une performance de transmission assurée sont garanties. Le matériau du boîtier est en polycarbonate et répond ainsi aux exigences environnementales et mécaniques les plus élevées. En combinaison avec le support de module designable, une combinaison avec tous les programmes d’interrupteurs est possible. Diverses certifications de laboratoires d’essais indépendants complètent la plus haute exigence de qualité du module Keystone fixLink<sup>®</sup> SL. Ils sont livrés dans un emballage carton respectueux de l’environnement.',
      },
      fixLink_SL_02: {
        name: 'Module RJ45 Keystone SL Cat. 6A ISO/IEC, blindé',
        text: "Le module fixLink<sup>®</sup> SL RJ45 Keystone peut être assemblé en moins d'une minute en utilisant une pince coupante. La zone de contact librement accessible assure une qualité d'assemblage parfaite et les performances de transmission garanties associées. La connectivité à 360° nécessite uniquement d'encliqueter le couvercle intégré à son emplacement, éliminant ainsi la nécessité de fixation fastidieuse d'un serre-câble. La géométrie spéciale du contact, en association avec le placage or 50 p\" assure 4PPoE selon IEEE 802.3bt dans toutes les cir-constances. Le matériau du boîtier est composé de zinc moulé plaqué en nickel et respecte donc les exigences mécaniques et environnementales les plus élevées. L'utilisation avec le support de module multi-design permet une combinaison avec toutes les gammes de produits de commutateurs. Différentes certifications (liste actuelle : www.easylan.de) de laboratoires de test indépendants soulignent les exigences élevées en matière de qualité du module fixLink<sup>®</sup> SL Keystone. Les modules fixLink<sup>®</sup> SL Keystone sont livrés dans un carton respectueux de l'environnement.",

        ghmtCertificates: [
          {
            name: 'Certificat PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Rapport d’essai 4PoE selon DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'Module RJ45 Keystone SL Cat. 6A ISO/IEC, blindé, coudé',
        text: 'Avec le module RJ45 Keystone SL fixLink<sup>®</sup>, l’utilisateur obtient un module de prise RJ45 de la plus haute qualité, spécialement développé pour les applications nécessitant peu d’espace. Grâce à sa sortie de câble à 75°, le module convient particulièrement pour une utilisation dans les plastrons et les câblages en cavité. Une profondeur d’installation minimale de 27 mm permet une variété d’applications presque illimitée. Le module RJ45 Keystone SL fixLink<sup>®</sup> peut être confectionné en moins d’une minute à l’aide d’une pince coupante. Grâce à la zone de contact librement accessible, une qualité de confection parfaite et donc une performance de transmission assurée sont garanties. La géométrie de contact spéciale en combinaison avec un placage or de 50 μ" garantit PoE+ selon IEEE 80.3at dans toutes les conditions. Le matériau du boîtier est en zinc moulé sous pression nickelé et répond ainsi aux exigences environnementales et mécaniques les plus élevées. En combinaison avec le support de module designable, une combinaison avec tous les programmes d’interrupteurs est possible. Diverses certifications de laboratoires d’essais indépendants complètent la plus haute exigence de qualité du module Keystone SL fixLink<sup>®</sup>. Ils sont livrés dans un emballage carton respectueux de l’environnement.',
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Outils et accessoires',
        text: [
          "Outil de dénudage pour enlever la gaine extérieure et les feuilles métalliques d'un câble de données. Couleur bleue.",
          "Ruban de cuivre pour fixer l'écran de blindage. Prédécoupé en 35 x 9 mm. Unité d'emballage : 20 pièces/sachet 1500 pièces/rouleau",
          'Couvercles de protection contre la poussière colorés pour les modules Keystone preLink® SL et fixLink® SL.',
          "Unité d'emballage 50 pièces/sachet en polyéthylène",
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP Cat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Câble de données',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Câble de données EasyLan® Gold S/FTP AWG22/1 1500 MHz Cat. 7A',
        text: [
          "Câble de données pour la transmission de signaux analogiques et numériques dans le câblage des domaines primaire, secondaire et tertiaire. Convient aux applications jusqu'à la classe FA.",
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certificat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fiche technique',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Câble de données EasyLan® Silber S/FTP AWG23/1 1200 MHz Cat. 7A',
        text: [
          "Câble de données pour la transmission de signaux analogiques et numériques dans le câblage des domaines primaire, secondaire et tertiaire. Convient aux applications jusqu'à la classe FA.",
        ],
        ghmtCertificates: [
          {
            name: 'Certificat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fiche technique',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Câble de données EasyLan® Bronze S/FTP AWG23/1 1000 MHz Cat. 7',
        text: "Câble de données pour la transmission de signaux analogiques et numériques dans le câblage des domaines primaire, secondaire et tertiaire. Convient aux applications jusqu'à la classe FA.",
        datasheets: [
          {
            name: 'Fiche technique',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Plastrons',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Boîtier AP et cadre pour supports de modules mentionnés ci-dessus',
        text: [
          'Boîtier de surface IP20, 80x80x42mm pour l’installation de supports de modules EasyLan',
          'Cadre intermédiaire pour boîtier de surface pour une augmentation de hauteur de 10 mm',
          'Cadre de couverture 1x 80x80 mm',
          'Cadre de couverture 2x 80x150 mm',
        ],
        datasheets: [
          {
            name: 'Boîtier de surface',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Extension de profondeur du boîtier de surface',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Support de module SL, sortie 45° UP, BK, AP, BT 1x, 2x, 3x',
        text: 'Les supports de modules SL sont particulièrement adaptés à l’installation dans les canaux de câbles (EK), les installations encastrées (UP) et les installations en surface (AP). En cassant deux languettes de montage, une installation dans des réservoirs de sol (BT) est également possible. Le support de module SL peut contenir un à trois Keystones EasyLan de manière absolument sûre et permanente. Il possède une grande fenêtre d’étiquetage centrale pour un étiquetage facile. La plaque centrale (50x50 mm) et le cadre (80x80 mm) sont en plastique résistant aux UV et extrêmement incassable. Cela garantit une apparence durable et fidèle à la couleur.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Support de module designable SL, sortie 45° UP, BK, AP, BT 1x, 2x',
        text: 'Le support de module designable est composé d’un cadre en zinc moulé sous pression. Il est adapté pour une installation dans un canal de câbles (EK), pour une installation encastrée (UP) ou, avec un cadre séparé, également pour une installation en surface (AP). En cassant deux languettes de montage, une installation dans des réservoirs de sol (BT) est également possible. La prise de données designable est compatible avec presque tous les programmes d’interrupteurs disponibles sur le marché. Grâce à la compatibilité UAE, la prise de données designable ne laisse pas de place pour un cache anti-poussière.',
        datasheets: [
          {
            name: 'Keystone 1x/2x, sans plaque centrale',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Keystone 2x, avec plaque centrale 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1x avec plaque centrale',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Support de module IP44 en surface, 1x, 2x',
        text: 'Le boîtier de surface robuste est adapté à l’installation de deux modules Keystone preLink<sup>®</sup> / fixLink<sup>®</sup> à l’aide d’un adaptateur. Pour l’introduction de deux câbles d’un diamètre de 5,0 à 9,5 mm chacun, il y a deux points facilement perforables à l’arrière du boîtier. Le support de module atteint la classe de protection IP20 en état connecté et la classe de protection IP44 en état fermé.',
      },
      DoseUPleer2xKS: {
        name: 'Support de module sortie droite, 2x',
        text: 'Le support de module avec sortie Keystone droite est en zinc moulé sous pression et est adapté pour une installation dans un canal de câbles (EK), une installation encastrée (UP) ou, avec un cadre séparé, également pour une installation en surface (AP).',
      },
      bodentank: 'Boîtier de sol',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Systèmes de réservoirs de sol OBO/Ackermann UT3, UT4',
        text: 'La plaque porteuse remplace les porte-appareils et offre ainsi un espace maximal pour le passage des câbles. L’introduction et la sortie inclinées permettent une gestion sécurisée des câbles même avec des planchers intermédiaires très bas. Une combinaison avec des plaques d’insertion pour 4x modules preLink<sup>®</sup>/fixLink<sup>®</sup> ou 1x H.D.S. est prévue. Les plaques d’insertion sont fixées à la plaque porteuse avec deux vis, les champs non utilisés de la plaque porteuse peuvent être couverts de manière étanche à la poussière avec des plaques aveugles.',
        datasheets: [
          {
            name: 'Plaque porteuse pour UT3 sans plaque d’insertion',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Plaque porteuse pour UT4 sans plaque d’insertion',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Porte-boîtier de sol OBO/Ackermann Basic UT3, UT4',
        text: 'Le porte-boîtier de sol Keystone remplace le porte-appareils et offre ainsi un espace maximal pour le passage des câbles. L’introduction et la sortie inclinées permettent une gestion sécurisée des câbles même avec des planchers intermédiaires très bas. Les plaques d’insertion sont fixées à la plaque porteuse avec deux vis, les ouvertures Keystone non utilisées du porte-boîtier de sol peuvent être couvertes de manière étanche à la poussière avec des plaques aveugles. À l’aide d’un adaptateur, le porte-boîtier de sol peut également être utilisé pour les porte-appareils Electraplan.',
        datasheets: [
          {
            name: 'Porte-boîtier de sol UT3 pour jusqu’à 6 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Porte-boîtier de sol UT4 pour jusqu’à 9 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Porte-boîtier de sol UT4 pour jusqu’à 12 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Porte-boîtier de sol PUK Basic UT4',
        text: 'Le porte-boîtier de sol Keystone remplace le porte-appareils et offre ainsi un espace maximal pour le passage des câbles. L’introduction et la sortie inclinées permettent une gestion sécurisée des câbles même avec des planchers intermédiaires très bas. Les plaques d’insertion sont fixées à la plaque porteuse avec deux vis, les ouvertures Keystone non utilisées du porte-boîtier de sol peuvent être couvertes de manière étanche à la poussière avec des plaques aveugles. Les porte-réservoirs de sol sont compatibles avec le système de réservoirs de sol PUK.',
        datasheets: [
          {
            name: 'Porte-boîtier de sol UT4 pour jusqu’à 9 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Porte-boîtier de sol UT4 pour jusqu’à 12 modules',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Inserts OBO/Ackermann pour systèmes de réservoirs de sol UT3, UT4, accessoires',
        text: 'Les inserts pour modules sont non enduits et galvanisés à chaud pour des raisons de mise à la terre. Les plaques d’insertion sont fixées à la plaque porteuse avec deux vis, les champs non utilisés de la plaque porteuse peuvent être couverts de manière étanche à la poussière avec des plaques aveugles.',
        datasheets: [
          {
            name: 'Plaque d’insertion pour 4x modules RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Plaque d’insertion pour 3x modules RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adaptateur de rail DIN',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Boîtier compact de module de rail DIN pour 1 module',
        text: 'Boîtier de module de rail DIN pour modules preLink<sup>®</sup> / fixLink<sup>®</sup>, adapté aux armoires de distribution sans couverture pour disjoncteurs.',
        datasheets: [
          {
            name: 'Boîtier compact de module de rail DIN pour 1 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Couplage Keystone-F et module vide de couverture Keystone',
        text: 'Couplage F et couverture vide comme remplacement de module\nLe couplage F sert à l’intégration de câbles coaxiaux dans les panneaux de brassage preLink<sup>®</sup> / fixLink<sup>®</sup> et dans le programme de prises. La couverture vide preLink<sup>®</sup>/fixLink<sup>®</sup> peut être utilisée aussi bien dans le champ de distribution que dans les prises pour couvrir les ports inutilisés.\n\nModule vide pour SC-S ou LC-D comme remplacement de module\nModule vide pour les couplages SC-S ou LC-D dans le champ de distribution ou la prise.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Boîtier de module de rail DIN pour 1 module',
        text: 'Boîtier de module de rail DIN pour modules preLink<sup>®</sup> / fixLink<sup>®</sup>, adapté aux armoires de distribution avec couverture pour disjoncteurs.',
        datasheets: [
          {
            name: 'Boîtier de module de rail DIN pour 1 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panneau',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panneau 19" 1HE pour 24 modules Keystone, sortie droite',
        text: 'Le panneau de distribution 19" 1HE peut être équipé de jusqu’à 24 modules RJ45 Keystone preLink<sup>®</sup> / fixLink<sup>®</sup>. Il est structuré en deux niveaux, les modules sont donc alignés avec la face avant. Le panneau de distribution peut être commandé en acier inoxydable ou en tôle d’acier enduite de poudre.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panneau 19" 1HE pour 48 modules Keystone, sortie droite',
        text: 'Le panneau de distribution 19" 1HE peut être équipé de jusqu’à 48 modules RJ45 Keystone fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+. Il est structuré en deux niveaux, les modules sont donc alignés avec la face avant. Le panneau de distribution est en tôle d’acier enduite de poudre.\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panneau 19" 0,5HE pour 24 modules Keystone, sortie droite',
        text: 'Le panneau de distribution 19" 0,5HE preLink<sup>®</sup> / fixLink<sup>®</sup> peut être équipé de jusqu’à 24 modules RJ45 Keystone fixLink<sup>®</sup>. Ils sont alignés avec la face avant.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panneau 19" 1HE pour 24 modules Keystone, y compris fenêtre d’étiquetage',
        text: 'Le panneau de distribution 19" 1HE peut être équipé de jusqu’à 24 modules RJ45 Keystone preLink<sup>®</sup> / fixLink<sup>®</sup>. Il est structuré en deux niveaux, les modules sont donc alignés avec la face avant. Le panneau de distribution peut être commandé en acier inoxydable ou en tôle d’acier enduite de poudre. De plus, il est possible d’étiqueter les différents ports à l’aide d’une feuille d’étiquettes perforée 12x33 fournie.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Panneau d’angle 30° 19" 1HE pour 24 modules Keystone',
        text: 'Avec le panneau d’angle preLink<sup>®</sup> / fixLink<sup>®</sup>, l’utilisateur peut insérer 24 modules Keystone dans le panneau à un angle de 30°, de sorte que les câbles de raccordement connectés courent automatiquement vers le côté de l’armoire et doivent seulement être fixés sur le côté à côté du niveau de brassage. L’utilisateur peut ainsi se passer de panneaux de gestion de câbles supplémentaires. En fonctionnement, les panneaux d’angle avec la face avant plate et les câbles de raccordement inclinés nécessitent également moins d’espace vers l’avant que les panneaux de brassage classiques avec des ports installés verticalement.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      baugruppentraeger: 'Support de groupe',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Support de module 19" 3HE pour l’utilisation de modules enfichables',
        text: 'Support de module 19" 3HE pour l’utilisation de modules enfichables 7TE en cuivre et fibre optique. Ils sont fixés au support de module avec 2 vis du côté du panneau. Les modules enfichables peuvent être complètement retirés du support de module. Jusqu’à 12 modules enfichables 7TE peuvent être fixés au support de module.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Module enfichable Keystone pour support de module',
        text: 'Module enfichable Keystone pour l’équipement des supports de modules. Le module enfichable Keystone peut être équipé de jusqu’à 6 modules RJ45 Keystone preLink<sup>®</sup> ou fixLink<sup>®</sup>. Le module enfichable Keystone est structuré en deux niveaux, les modules sont donc alignés avec la face avant. La fixation des câbles se fait directement sur le boîtier du module enfichable. Jusqu’à 6 câbles individuels ou 1 câble trunk peuvent être fixés.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Support de module (BGT) 19" 1HE pour l’utilisation de modules enfichables',
        text: 'Support de module (BGT) pour l’utilisation de modules enfichables 7TE en cuivre et fibre optique. Ils sont fixés au support de module avec 2 vis du côté du panneau. Les modules enfichables peuvent être complètement retirés du support de module. Jusqu’à 3 modules enfichables 7TE (=18 ports) peuvent être fixés au support de module.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Support de module latéral 3HE/14TE pour l’utilisation de modules enfichables',
        text: 'Support de module latéral (S-BGT) pour l’utilisation de modules enfichables 7TE en cuivre et fibre optique. Les modules enfichables sont fixés au support de module avec 2 vis du côté du panneau. Les modules enfichables peuvent être complètement retirés du support de module. Jusqu’à 2 modules enfichables 7TE (=12 ports) peuvent être fixés au support de module. Le support de module latéral (S-BGT) est fixé à la perforation latérale de l’armoire. Cela permet une installation simple et rapide. Grâce à la fixation latérale de l’armoire, une installation de câblage peu encombrante et particulièrement respectueuse du climat est possible sans perturber le flux d’air.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      consolidation_point_panel: 'Panneau de point de consolidation, rails DIN, etc.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Panneau de point de consolidation sous-plancher 19" ou 10" 1HE',
        text: 'Panneau de point de consolidation sous-plancher avec plaques frontales interchangeables pour accueillir divers types de connexions en cuivre et fibre optique. En option, le panneau de point de consolidation sous-plancher peut être sécurisé contre tout accès non autorisé par une serrure.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
        datasheets: [
          {
            name: 'Panneau de point de consolidation 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Panneau de point de consolidation 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Panneau de point de consolidation de surface pour 4-12 modules Keystone',
        text: 'Panneau de point de consolidation de surface pour modules RJ45 Keystone preLink<sup>®</sup> / fixLink<sup>®</sup>, à utiliser comme panneau de consolidation, montage direct ou également avec un kit de montage sur rails DIN dans les distributions électriques. Le panneau de point de consolidation de surface est structuré en deux niveaux. Les modules sont donc alignés avec la face avant.\n\nLe matériel de montage et les modules Keystone ne sont pas inclus.',
      },
      trunkkabel: 'Câble de tronc',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'Système de distribution CTBC Copper-Trunk-Bundled-Cable SL, Classe EA ISO/IEC',
        text: 'Le système de distribution CTBC est adapté à une utilisation flexible dans le câblage structuré des bâtiments et dans les centres de données. Les câbles regroupés dans une gaine tressée sont fabriqués en usine avec des modules Keystone fixLink<sup>®</sup> et testés. Le regroupement peut être effectué avec des câbles d’installation ainsi que des câbles flexibles. La longueur maximale du câble regroupé est de 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'Système de distribution CTCC Copper-Trunk-Compact-Cable SL, Classe EA ISO/IEC',
        text: 'Le système de distribution CTCC est adapté à une utilisation flexible dans le câblage structuré des bâtiments et dans les centres de données en raison de son diamètre de câble minimal et de la charge incendie réduite correspondante. La longueur maximale du câble multiple est de 60 à 90 m selon le type. Le système de distribution en tant que solution 10 Gbit pour les câblages de centres de données répond au minimum à la norme Classe EA.',
      },
      hds_innengabel: 'Câble intérieur H.D.S.',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'Câble intérieur H.D.S. Cat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'Câble intérieur H.D.S. Cat. 7 S/FTP pour un câblage simple d’armoire à armoire dans les centres de données et les connexions multiples entre les salles de distribution et les sous-distributions. La structure du câble se compose de 24 éléments de câble blindés par paires, chaque 4 paires sont regroupées en un élément torsadé. Chaque élément torsadé est marqué avec une bande de numérotation pour une identification plus facile. L’élément de décharge de traction central flexible protège particulièrement bien le câble lors de l’insertion. La gaine est en matériau LSOH flexible.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'Câble intérieur H.D.S. Cat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'Câble intérieur H.D.S. Cat. 7 S/FTP pour un câblage simple d’armoire à armoire dans les centres de données et les connexions multiples entre les salles de distribution et les sous-distributions. La structure du câble se compose de 24 éléments de câble blindés par paires, chaque 4 paires sont regroupées en un élément torsadé. Chaque élément torsadé est marqué avec une bande de numérotation pour une identification plus facile. L’élément de décharge de traction central flexible protège particulièrement bien le câble lors de l’insertion. La gaine est en matériau LSOH flexible.',
      },
      generic: {
        download: 'Télécharger',
      },
    },
  },

  imprint: {
    headline: 'Notice du site',
  },

  dataProtection: {
    headline: 'Politique de confidentialité',
  },

  footer: {
    dataProtection: 'Politique de confidentialité',
    imprint: 'Notice du site',
    contact: 'Contact',
  },

  UI: {
    autocomplete: {
      searchError: 'La recherche n’a pas pu être effectuée.',
      searchEmpty: 'Aucun résultat',
    },
    dropdown: {
      emptySelection: '--- veuillez choisir ---',
    },
  },
}

export const langKey = 'FR'

const Default = {
  translation,
  langKey,
}

export default Default

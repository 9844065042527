import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import getCategoryData, { Category } from '../getCategoryData'
import useGetSelectedCategoryItem from '../useGetSelectedCategoryItem'
import useSelectCategoryItemID from '../useSelectCategoryItemID'
import { GridContainer, StyledItem } from './System.Styled'
import MediumContainer from '../../UI/Container/MediumContainer'

const Item: React.FC<{ category: Category; large?: boolean; index: number }> = ({ category, index, large = false }) => {
  const selectedCategoryItem = useGetSelectedCategoryItem()
  const selectCategory = useSelectCategoryItemID()
  const isSelected = selectedCategoryItem ? category.name === selectedCategoryItem.category.name : false

  return (
    <StyledItem large={large} highlight={isSelected} className="cursor-pointer" onClick={() => selectCategory(category.items[0].id)}>
      <div className="flex flex-col items-center grow-0 h-full justify-between">
        {index > 1 && (
          <>
            {!!large && (
              <div className="p-5 h-full shrink grow-0 overflow-hidden flex items-center justify-center">
                <img src={category.img} alt={category.name} className="max-h-full max-w-full" />
              </div>
            )}
            {!large && (
              <div className="p-5 h-full shrink grow-0 overflow-hidden flex items-center justify-center">
                <img src={category.img} alt={category.name} className="max-h-full max-w-full" />
              </div>
            )}
          </>
        )}
        {index <= 1 && (
          <div className="p-5 pr-0 w-full h-full shrink grow-0 overflow-hidden flex items-center justify-center">
            <img src={category.img} alt={category.name} className="max-h-full max-w-full" />
          </div>
        )}
        <div className={`font-bold text-xs text-center my-2 ${isSelected ? 'text-white' : ''}`}>{category.name}</div>
      </div>
    </StyledItem>
  )
}

const System = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-white relative py-20" id="system">
      <MediumContainer>
        <div className="lg:w-8/12 mx-auto leading-7">
          <div className="text-danger text-center">{t.easylan.system.titleLabel}</div>
          <div className="font-bold uppercase text-lg text-center mt-2" dangerouslySetInnerHTML={{ __html: t.easylan.system.title }} />
        </div>

        <div className="mt-16">
          <GridContainer className="w-full">
            {getCategoryData(t).map((c, i) => (
              <Item key={c.name} index={i} category={c} large={i <= 1} />
            ))}
          </GridContainer>
        </div>
      </MediumContainer>
    </div>
  )
}

export default System

import { v4 as uuidv4 } from 'uuid'
import currencyFormatter from 'currency-formatter'
import prettyBytes from 'pretty-bytes'
import FileDownload from 'js-file-download'
import validator from 'validator'

export const backendDomain = process.env.NODE_ENV === 'production' ? 'https://backend-bfs-zvk-easylan.think-internet.de' : 'http://localhost:8100'

export const s3PublicStoragePrefix = 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan.storage/'

export const getUUID = () => uuidv4()

export const removeUnnessesary = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    //add if array is not empty

    if (Array.isArray(obj[key]) && obj[key].length > 0) newObj[key] = obj[key]
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) newObj[key] = removeUnnessesary(obj[key])
    if (typeof obj[key] === 'string' && obj[key].length > 0) newObj[key] = obj[key]
    if (typeof obj[key] === 'number') newObj[key] = obj[key]
  })
  return newObj
}

export const getDateInputFormat = (date) => {
  const fill = (number: number) => {
    const numberAsString = `${number}`
    return numberAsString.length === 1 ? `0${number}` : number
  }

  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const getQueryString = (string, requiredString) => {
  if (typeof string === 'string' && string.length > 0 && typeof requiredString === 'string') {
    string = string.substring(1)
    const split = string.split('=')
    return !string.includes('&') && split.length === 2 && split[0] === requiredString && split[1].length > 0 ? decodeURIComponent(split[1]) : false
  } else {
    return false
  }
}

export const includesAllProps = (propsArray, object) =>
  propsArray.reduce((acc, el) => {
    if (object?.[el] && acc) return true
    return false
  }, true)

export const frontendDomain = process.env.NODE_ENV === 'production' ? 'https://zvk-easylan.blackflagship.de' : 'http://localhost:3000'

export const addMissingZero = (input) => {
  if (`${input}`.length === 1) {
    return `0${input}`
  }
  return input
}

export const formatFileSize = (sizeInBytes, locale = 'de') => {
  return prettyBytes(sizeInBytes, { locale })
}

export const formatPrice = (priceInEuro, hideDecimalIfPossible = false, symbol = '€', locale = 'de-DE') => {
  return currencyFormatter.format(priceInEuro, {
    locale,
    symbol,
    decimal: ',',
    thousand: '.',
    format: '%v %s',
    precision: hideDecimalIfPossible ? 0 : 2,
  })
}

export const maxMobileWidth = 992

export const presignedUploadURLEndpoint = '/upload/get-presigned-url'

export const triggerClientSideDownload = (data, filename) => FileDownload(data, filename)

export const downloadFile = (name: string, blobData: Blob) => {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blobData)
  link.download = name
  link.click()
}

export const downloadFileFromURL = (name: string, url: string) => {
  var link = document.createElement('a')
  link.href = url
  link.download = name
  link.dispatchEvent(new MouseEvent('click'))
}

export const formatEUR = (amount) => {
  return currencyFormatter.format(amount, {
    locale: 'de-DE',
    symbol: '€',
    decimal: ',',
    thousand: '.',
    format: '%v %s',
  })
}

export const formatNumber = (value) => {
  return new Intl.NumberFormat('de-DE').format(value)
}

export const getLocationString = (addressComponents, order) => {
  if (!Array.isArray(addressComponents)) return 'kein Standort angegeben'
  const address = []
  addressComponents.forEach((component) => {
    component.types.forEach((type) => {
      if (order.includes(type)) address.push({ name: component.long_name, type })
    })
  })

  return address
    .sort((a, b) => (order.indexOf(a.type) < order.indexOf(b.type) ? -1 : 1))
    .map((i) => i.name)
    .join(', ')
}

export const validateMail = (mail) => {
  const mailRegex = new RegExp('^\\S+@\\S+\\.\\S+$')
  return mailRegex.test(mail)
}

export const cookieLiveTimeInMillis = 1000 * 60 * 60 * 24 * 365

const fill = (number: Number) => {
  const numberAsString = `${number}`
  return numberAsString.length === 1 ? `0${numberAsString}` : numberAsString
}

export const getPrettyTime = (date: Date) => {
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${hour}:${minute}`
}
export const getPrettyDateTime = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const calculateSquareMeters = (length: number, height: number) => {
  if (length === 0 || height === 0) return 0
  return length * height
}

export const getPercentageOfPrice = (price: number, percentage: number) => {
  return (price / 100) * percentage
}

export const maxAgeOfOffersInDays = 5

export const minArtistStoryLength = 150

export const minPublicRatingTextLength = 150

export const isValidPhoneNumber = (phone: string | number) => validator.isMobilePhone(`${phone}`, 'de-DE', { strictMode: true })
export const isValidIBAN = (iban: string | number) => validator.isIBAN(`${iban}`, { whitelist: ['DE'] })

export const getCurrentMonthName = () =>
  new Date().toLocaleString('default', {
    month: 'long',
  })

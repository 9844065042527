import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Easylan from './components/Easylan/Easylan'
import Imprint from './components/Imprint/Imprint'
import DataProtection from './components/DataProtection/DataProtection'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            {/* public rountes */}
            <Route index element={<Easylan />} />
            <Route path={':categoryItemID'} element={<Easylan />} />

            {/* legal */}
            <Route path={'imprint'} element={<Imprint />} />
            <Route path={'data-protection'} element={<DataProtection />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer autoClose={10000} />
      </ThemeProvider>
    </div>
  )
}

export default App

import { StyledHeader } from './Header.Styled'
import logoImage from '../../../../assets/image/logo.svg'
import Container from '../../Container/Container'
import Navigation from './Navigation'
import Item from './Item'

type Props = {
  logo?: string
  lineColor?: string
}

const Header: React.FC<Props> = ({ logo, lineColor = 'blue' }) => {
  return (
    <StyledHeader lineColor={lineColor}>
      <div className="flex items-center h-full">
        <Container>
          <div className=" flex justify-between items-center">
            <Item href={'/'}>
              <img src={logo || logoImage} className="h-10" alt="EasyLan" />
            </Item>
            <Navigation />
          </div>
        </Container>
      </div>
    </StyledHeader>
  )
}

export default Header

import styled from 'styled-components'

export const StyledHeader = styled.div<{ lineColor?: string }>`
  width: 100vw;
  height: ${({ theme }) => theme.headerHeight};
  z-index: 20;
  top: 0;
  position: fixed;
  width: 100%;
  background: ${({ theme }) => theme.color.background.primary};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-top: 3px solid ${({ theme, lineColor }) => theme.color.headerLine[lineColor]};
`

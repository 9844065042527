import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button from '../Button/Button'
import Form from '../Form/Form'
import { CheckIcon } from '@heroicons/react/24/solid'

export enum TYPE {
  WARNING = 'warning',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

type Props = {
  type?: TYPE
  show: boolean
  onClose: () => void
  title?: string
  children?: React.ReactNode
  text?: string
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm?: () => void
  noPadding?: boolean
}

const ModalSimple: React.FC<Props> = ({ type, show, onClose, title, children, text, onConfirm, noPadding = false }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50 box-border" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-dark text-left shadow-xl w-full transition-all sm:my-8 sm:max-w-xl">
                <Form onSubmit={onConfirm}>
                  <div className={`bg-dark ${!noPadding && 'px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}`}>
                    <div className={!!type ? 'sm:flex sm:items-start' : ''}>
                      <div className="text-center sm:mt-0 sm:text-left">
                        {!!title && (
                          <Dialog.Title as="h3" className="mb-2 text-base font-semibold leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>
                        )}
                        <div>
                          {!!text && <p className="text-sm text-gray-500">{text}</p>}
                          {!!children && children}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!!onConfirm && (
                    <div className="flex justify-end gap-1 bg-gray-50 py-3 px-3">
                      <Button type="submit" className="!bg-danger rounded-lg">
                        <CheckIcon className="h-5" />
                      </Button>
                    </div>
                  )}
                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalSimple

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import MediumContainer from '../../UI/Container/MediumContainer'
import links from '../../../assets/image/easyLan/handling/links_design.svg'
import mitte from '../../../assets/image/easyLan/handling/mitte_360Grad.svg'
import rechts from '../../../assets/image/easyLan/handling/rechts_Pionier.svg'
import { Gradient } from '../Gradient/Gradient.Styled'

const Handling = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="bg-white relative py-20">
      <Gradient className="absolute top-0 left-0 w-full h-10" />
      <MediumContainer>
        <div className="lg:w-8/12 mx-auto leading-7">
          <div className="text-danger text-center" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
          <div className="font-bold uppercase text-lg text-center mt-2">{t.easylan.handling.title}</div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between mt-10 gap-12 lg:gap-12">
          <div className="flex flex-col items-center gap-5 flex-1">
            <img src={links} alt="" className="h-10 w-10 object-contain" />
            <div className="text-sm text-center">
              {t.easylan.handling.vorreiterPrefix}
              <span className="text-danger text-sm font-bold" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
              {t.easylan.handling.vorreiterSuffix}
            </div>
          </div>
          <div className="flex flex-col items-center gap-5 flex-1">
            <img src={mitte} alt="" className="h-10 w-10 object-contain" />
            <div className="text-sm text-center">
              <span className="text-danger text-sm font-bold" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
              {t.easylan.handling.experte}
            </div>
          </div>
          <div className="flex flex-col items-center gap-5 flex-1">
            <img src={rechts} alt="" className="h-10 w-10 object-contain" />
            <div className="text-sm text-center">
              <span className="text-danger text-sm font-bold" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
              {t.easylan.handling.pionier}
            </div>
          </div>
        </div>
      </MediumContainer>
    </div>
  )
}

export default Handling

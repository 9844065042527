import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import '../UI/LegalDocumentStyling/LegalDocument.scss'

const DataProtection = () => {
  return (
    <Layout>
      <Container>data protection</Container>
    </Layout>
  )
}

export default DataProtection

export const translation = {
  header: {
    items: [
      {
        name: 'Головна',
        href: '/',
      },
      {
        name: 'Система',
        href: '#system',
      },
      {
        name: 'Модуль',
        href: '#module',
      },
      {
        name: 'Монтаж',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Мова',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Простота',
        text2: "у з'єднанні",
      },
      subtitle: "Твій з'вязок із майбутнім",
      cta: 'докладніше',
    },
    handling: {
      title: 'ПЕРЕДОВІ ТЕХНОЛОГІЇ ТА ІНТУЇТИВНИЙ ДИЗАЙН',
      vorreiterPrefix: 'Поєднання високої якості та функціональності робить систему ',
      vorreiterSuffix: ' найкращим вибором у сфері мережевих технологій.',
      experte: ' - це ефективні технічні рішення, які ідеально поєднують дизайн та продуктивність.',
      pionier: ' є піонером у створенні оптимальної сетевой інфраструктури, поєднуючи досвід з інтуїтивно зрозумілим функціоналом.',
    },
    download: {
      prefix: 'Огляд ',
      suffix: ' - Завантажити брошюру',
      cta: 'Завантажити',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Огляд продуктів',
      title: 'FIXLINK<sup>®</sup> - СИСТЕМА',
    },
    module: {
      overview: 'Огляд завантаження',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Сертифікати',
          text: 'Ми покладаємось на незалежну лабораторію GHMT та її програму Premium Verification Program (PVP). Згідно з умовами програми GHMT проводить регулярні контрольні закупівлі компонентів на ринку та їх тестування для контролю якості. Таким чином, ми гарантуємо найвищі стандарти продукції для наших клієнтів на основі 20 років досвіду.',
        },
        datasheets: {
          title: 'Технічні паспорти',
          text: 'Тут ти знайдеш усі необхідні технічні паспорти. Завантаж їх та отримай усю інформацію в одному місці.',
        },
        compatibilitySheets: {
          title: 'Списки сумісності',
          text: '',
        },
        excelTemplates: {
          title: 'Шаблони написів як Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'ІНСТРУКЦІЯ ПО МОНТАЖУ',
      text: 'Подивись відео або завантаж PDF інструкцію тут',
      manual: {
        title: 'ІНСТРУКЦІЇ ПО МОНТАЖУ:',
      },
    },
    separator: {
      title: "Більше ніж просто з'єднання",
      title2: 'Відкрийте всесвіт EasyLan',
      text: 'Відкрийте різноманітність інноваційних мережевих рішень на EasyLan.de: Індивідуально для вас. GameChanger для вашої мережі: наші кабельні системи та рішення з управління кабелями.',
      cta: 'докладніше',
    },
    combination: {
      bau: {
        prefix: 'Відчуйте максимальну адаптацію та якість з ',
        text: ' групоносієм EasyLan ',
        suffix: ' безпосередньо з Європи.',
      },
      intelligent: {
        prefix: 'Ваша мережа',
        text: ' налаштована ',
        suffix: ' з конфігурованою сплайс-боксом EasyLan',
      },
      fixlink: {
        prefix: 'З нашими продуктами ',
        suffix: ' ви можете покращити ваші офісні будівлі на тривалий термін.',
      },
      hds: {
        prefix: 'Система',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: ' поєднує легкість установки та обслуговування як жодна інша система магістрального кабелювання на ринку.',
      },
    },
    recommended: {
      titleLabel: 'Рекомендовано',
      title: 'fixLink<sup>®</sup> - СИСТЕМА',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Гарантія продукту',
        text: [
          'Гарантія на продукт включає всі компоненти волоконно-оптичної або мідної кабельної системи, встановлені кваліфікованим інсталятором згідно з вимогами та чинними нормами установки EN50174.',
        ],
      },
      {
        title: 'Гарантія продуктивності',
        text: [
          'Гарантія продуктивності додатково гарантує системні характеристики, включаючи швидкість передачі (10MBit, 100MBit, 1GBit і 10GBit), для відповідних застосувань (Ethernet, Fiber Channel, ATM тощо).',
        ],
      },
      {
        title: 'Гарантія на застосування',
        text: [
          'Гарантія на застосування гарантує всі специфіковані застосування (Ethernet, Fiber Channel, ATM тощо), які базуються на чинних на момент установки стандартах кабелювання ISO/IEC 11801, EN50173 та EIA/TIA 568B.',
        ],
      },
    ],
    end: {
      title: "З'ЄДНАННЯ НІКОЛИ НЕ БУЛИ ТАКИМИ ПРОСТИМИ.",
      text: "Інтуїтивне управління поєднується з передовими технологіями; відкрийте для себе бездоганне з'єднання майбутнього: fixLink<sup>®</sup>",
    },
    categoryData: {
      modules: 'Модулі Keystone',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-модуль Кат. 6A ISO/IEC, екранований',
        text: [
          'Екранований RJ45 PRO модуль fixLink<sup>®</sup> - це ще більш вдосконалений модуль Keystone серії fixLink<sup>®</sup>. Переваги модулів fixLink<sup>®</sup> PRO:',
          'Ще більш компактний дизайн, більш швидкий і простий монтаж без допомоги спеціального інструменту, порівняно зі звичайним fixLink SL, завдяки контактам заземлення кабелю удосконаленої конструкції на тильній частині модуля.',
          'Спеціальна геометрія контактів з 30 μ" золотим покриттям забезпечує гарантовану якість передачи даних і відпоівдність стандарту 4PPoE відповідно до IEEE 802.3bt за будь-яких умов.',
          'Корпус виготовлений із нікельованого цинкового сплаву, що відповідає найвищим екологічним і механічним вимогам.',
          'Змінна кольорова пилозахисна шторка',
          'Сертифікати від незалежних лабораторій підтверджують найвищу якість модулів fixLink<sup>®</sup> PRO.',
        ],
        ghmtCertificates: [
          {
            name: 'PVP Кат.6A Сертифікат',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Постійний лінк сертифікат',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Подовжувач Кат. 6A ISO/IEC, екранований',
        text: "З подовжувачем fixLink<sup>®</sup> кабелю можна без зайвих зусиль наростити довжину кабелю або відновити пошкоджений кабель. Крім того, можна встановити подовжувачі на інстальовані кабелі та подовжити їх у майбутньому до необхідного з'єднання. Таким чином, подовжувачі можуть бути використані в якості точки консолідації. Подовжувач просто прикручується за допомогою саморізів в кабельному каналі або на стіні. У майбутньому подовжений таким чином кабель можна використовувати для передавання даних зі швидкістю до 10GB Ethernet, з підтримкою PoE+ (Power over Ethernet).",
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Кат. 6A ISO/IEC екранований',
        text: [
          'Екранований RJ45 коннектор fixLink® призначений для встановлення в польових умовах на екранований кабель кат.6а - кат.7 AWG26-AWG22 у такий самий спосіб як і модуль fixLink®. Монтаж може бути виконаний менш ніж за хвилину без допомоги спеціального інструменту.',
          'Спеціальна геометрія контактів з 50 μ" золотим покриттям забезпечує гарантовану якість передачи даних і відпоівдність стандарту 4PPoE відповідно до IEEE 802.3bt за будь-яких умов.',
          'Корпус виготовлений із нікельованого цинкового сплаву, що відповідає найвищим екологічним і механічним вимогам.',
        ],
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-модуль Кат. 6A EIA/TIA, неекранований',
        text: [
          'еекранований RJ45 модуль fixLink<sup>®</sup> - це модуль Keystone найвищої якості.',
          'Спеціальна геометрія контактів з 30 μ" золотим покриттям забезпечує гарантовану якість передачи даних і відпоівдність стандарту PoE+ according to IEEE 802.3at.',
          'Корпус виготовлений з полікарбонату, що відповідає найвищим екологічним і механічним вимогам.',
          'Компактний дизайн',
          'Монтаж менш ніж за хвилину без допомоги спеціального інструменту.',
          'Змінна кольорова пилозахисна шторка',
        ],
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-модуль Кат. 6A ISO/IEC, екранований',
        text: [
          'Екранований RJ45 модуль fixLink<sup>®</sup> - це модуль Keystone найвищої якості.',
          'Спеціальна геометрія контактів з 50 μ" золотим покриттям забезпечує гарантовану якість передачи даних і відпоівдність стандарту 4PPoE відповідно до IEEE 802.3bt за будь-яких умов.',
          'Корпус виготовлений із нікельованого цинкового сплаву, що відповідає найвищим екологічним і механічним вимогам.',
          'Компактний дизайн (SL, Slim)',
          'Монтаж менш ніж за хвилину без допомоги спеціального інструменту.',
          'Заземлення контактами на тильній частині усуває необхідність у закріпленні кабелю кабельною стяжкою.',
          'Змінна кольорова пилозахисна шторка',
          'Сертифікати від незалежних лабораторій підтверджують найвищу якість модулів fixLink<sup>®</sup>.',
        ],
        ghmtCertificates: [
          {
            name: 'PVP Кат.6A Сертифікат',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Звіт про тестування 4PoE згідно DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Постійний лінк сертифікат',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-модуль Кат. 6A ISO/IEC, екранований, з виходом кабелю 75°',
        text: [
          'Екранований RJ45 кутовий модуль fixLink<sup>®</sup> - це модуль Keystone найвищої якості, спеціально розроблений для застосування в умовах обмеженого простору.',
          'Завдяки 75° відходу кабелю модуль ідеально підходить для використання в розетках і кабельних каналах. Мінімальна глибина монтажу всього 27 мм.',
          'Спеціальна геометрія контактів з 50 μ" золотим покриттям забезпечує гарантовану якість передачи даних і відпоівдність стандарту 4PPoE відповідно до IEEE 802.3bt за будь-яких умов.',
          'Корпус виготовлений із нікельованого цинкового сплаву, що відповідає найвищим екологічним і механічним вимогам.',
          'Компактний дизайн (SL, Slim)',
          'Монтаж менш ніж за хвилину без допомоги спеціального інструменту.',
          'Заземлення контактами на тильній частині усуває необхідність у закріпленні кабелю кабельною стяжкою.',
          'Змінна кольорова пилозахисна шторка',
          'Сертифікати від незалежних лабораторій підтверджують найвищу якість модулів fixLink<sup>®</sup>.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Кат.6A Сертифікат',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Постійний лінк сертифікат',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Інструменти та аксесуари',
        text: [
          'Інструмент для зняття оболонки для зняття зовнішньої оболонки та металевих плівок кабелю передачі даних. Колір синій.',
          'Мідна стрічка для фіксації екрануючої оплетки. Попередньо нарізана на 35 x 9 мм. Одиниця упаковки: 20 штук/пакет 1500 штук/рулон',
          'Кольорові кришки для захисту від пилу для модулів preLink® SL і fixLink<sup>®</sup> SL Keystone.',
          'Одиниця упаковки 50 штук/поліетиленовий пакет',
        ],
        ghmtCertificates: [
          {
            name: 'Сертифікат GHMT PVP Кат.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Сертифікат GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Монтажний кабель',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Кабель для передачі даних EasyLan® Gold S/FTP AWG22/1 1500 MHz Кат. 7A',
        text: [
          'Кабель для передачі даних для передачі аналогових і цифрових сигналів при кабелюванні в первинній, вторинній та третинній області. Підходить для застосувань до класу FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Сертифікат GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Сертифікат GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Технічний паспорт',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Кабель для передачі даних EasyLan® Silber S/FTP AWG23/1 1200 MHz Кат. 7A',
        text: [
          'Кабель для передачі даних для передачі аналогових і цифрових сигналів при кабелюванні в первинній, вторинній та третинній області. Підходить для застосувань до класу FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Сертифікат GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Технічний паспорт',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Кабель для передачі даних EasyLan® Bronze S/FTP AWG23/1 1000 MHz Кат. 7',
        text: 'Кабель для передачі даних для передачі аналогових і цифрових сигналів при кабелюванні в первинній, вторинній та третинній області. Підходить для застосувань до класу FA.',
        datasheets: [
          {
            name: 'Технічний паспорт',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Розетки',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Монтажна коробка і рамки 80х80мм для розеток',
        text: [
          'Монтажна коробка IP20, 80x80x42мм для накладного монтажу розеток',
          'Розширювач глибини 10мм для монтажної коробки',
          'Однократна рамка 80x80мм',
          'Двократна рамка 80х150мм',
        ],
        datasheets: [
          {
            name: 'Накладний корпус',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Поглиблення накладного корпусу',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Розетка кутова 45° для модулів Keystone SL, 1-кратна, 2-кратна, 3-кратна',
        text: 'Розетка складається з супорта, виготовленого з цинкового сплаву, і пластикової накладки, дозволяє розмістити від одного до трех модулів Keystone SL. Центральна пластина (50x50 мм) і рамка (80x80 мм) виготовлені зі стійкого до ультрафіолету і надзвичайно міцного пластику. Це гарантує довговічність і збереження кольору. Розетка підходить для встановлення в кабельному каналі, для прихованого монтажу або з використанням додаткової монтажної коробки також для накладного монтажу. Передбачено можливість відламування двох монтажних вушок для монтажу в підлоговий люк.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Розетка кутова дизанерська 45° для модулів Keystone SL, 1-кратна, 2-кратна',
        text: 'Дизайнерська розетка забезпечує сумісність із багатьма виробниками електроінсталяції, як-от JUNG, LEGRAND, PEHA, Schneider Electric, GIRA та іншими (див. таблицю сумісності). Розетка складається з супорта, виготовленого з цинкового сплаву, і пластикової накладки. Підходить для встановлення в кабельному каналі, для прихованого монтажу або з використанням додаткової монтажної коробки також для накладного монтажу. Передбачено можливість відламування двох монтажних вушок для монтажу в підлоговий люк. Для забезпечення сумісності з UAE, розетка не передбачає місця для кришки захисту модуля від пилу.',
        datasheets: [
          {
            name: '1-/2-кратний Keystone, без центральної пластини',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-кратний Keystone, з центральною пластиною 50x50мм',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-кратний з центральною пластиною',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Утримувач модуля IP44 накладний, 1-кратний, 2-кратний',
        text: 'Міцний накладний корпус з адаптером підходить для установки двох preLink<sup>®</sup>/fixLink<sup>®</sup> Keystone модулів. Для введення двох кабелів діаметром по 5,0 - 9,5 мм ззаду на корпусі є дві легко проколювані точки. У встановленому стані утримувач модуля досягає захисного класу IP20, у закритому стані - захисного класу IP44.',
      },
      DoseUPleer2xKS: {
        name: 'Розетка IP44, для накладного монтажу, 2-кратна',
        text: 'Міцний накладний корпус з адаптером підходить для установки двох preLink®/fixLink® Keystone модулів. Для введення двох кабелів діаметром по 5,0 - 9,5 мм ззаду на корпусі є два отвори, що легко виламуються. У выдкритому стані розетка має захисний клас IP20, у закритому стані - IP44.',
      },
      bodentank: 'Підлогові люки',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Системи підлогових баків OBO/Ackermann UT3, UT4',
        text: 'Тримач панелі замінює тримачі пристроїв і забезпечує максимально можливий простір для прокладання кабелів. Нахилене прокладання дозволяє безпечне прокладання кабелів навіть при дуже низьких міжповерхових відстанях. Можлива комбінація з монтажними пластинами для 4x preLink<sup>®</sup>/fixLink<sup>®</sup> модулів або 1x H.D.S. Монтажні пластини закріплюються двома гвинтами на тримачі панелі, не використані поля тримача панелі можна закрити пилозахисними пластинами.',
        datasheets: [
          {
            name: 'Тримач панелі для UT3 без монтажної пластини',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Тримач панелі для UT4 без монтажної пластини',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Тримач панелі підлогового бака OBO/Ackermann Basic UT3, UT4',
        text: 'Тримач підлогового бака Keystone замінює тримач пристрою і забезпечує максимально можливий простір для прокладання кабелів. Нахилене прокладання дозволяє безпечне прокладання кабелів навіть при дуже низьких міжповерхових відстанях. Монтажні пластини закріплюються двома гвинтами на тримачі панелі, не використані виїмки для Keystone тримача панелі можна закрити пилозахисними пластинами. За допомогою адаптера тримач панелі підлогового бака також може бути використаний для тримачів пристроїв Electraplan.',
        datasheets: [
          {
            name: 'Тримач панелі підлогового бака UT3 для до 6 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Тримач панелі підлогового бака UT4 для до 9 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Тримач панелі підлогового бака UT4 для до 12 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Тримач панелі підлогового бака PUK Basic UT4',
        text: 'Тримач підлогового бака Keystone замінює тримач пристрою і забезпечує максимально можливий простір для прокладання кабелів. Нахилене прокладання дозволяє безпечне прокладання кабелів навіть при дуже низьких міжповерхових відстанях. Монтажні пластини закріплюються двома гвинтами на тримачі панелі, не використані виїмки для Keystone тримача панелі можна закрити пилозахисними пластинами. Тримачі підлогового бака сумісні з системою підлогових баків PUK.',
        datasheets: [
          {
            name: 'Тримач панелі підлогового бака UT4 для до 9 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Тримач панелі підлогового бака UT4 для до 12 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Вставки OBO/Ackermann для систем підлогових баків UT3, UT4, аксесуари',
        text: 'Вставки для модулів не покриті порошком і оцинковані гарячим способом з метою заземлення. Монтажні пластини закріплюються двома гвинтами на тримачі панелі, не використані поля тримача панелі можна закрити пилозахисними пластинами.',
        datasheets: [
          {
            name: 'Монтажна пластина для 4x RJ45 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Монтажна пластина для 3x RJ45 модулів',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Адаптер для монтажної рейки',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Компактний корпус модуля для монтажної рейки для 1 модуля',
        text: 'Корпус модуля для монтажної рейки для preLink<sup>®</sup>/fixLink<sup>®</sup> модулів, підходить для розподільчих шаф без кришки для автоматичних вимикачів.',
        datasheets: [
          {
            name: 'Компактний корпус модуля для монтажної рейки для 1 модуля',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: "Keystone-F-роз'єм і заглушка для модуля",
        text: "F-роз'єм і заглушка для заміни модуля\nF-роз'єм призначений для інтеграції коаксіальних кабелів у preLink<sup>®</sup>/fixLink<sup>®</sup> патчпанелі та розетки. Заглушка preLink<sup>®</sup>/fixLink<sup>®</sup> може бути використана для закриття незайнятих портів як у розподільному полі, так і в розетках.\n\nПорожній модуль для SC-S або LC-D як заміна модуля\nПорожній модуль для SC-S або LC-D роз'ємів у розподільному полі або розетці.",
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Корпус модуля для монтажної рейки для 1 модуля',
        text: 'Корпус модуля для монтажної рейки для preLink<sup>®</sup>/fixLink<sup>®</sup> модулів, підходить для розподільчих шаф з кришкою для автоматичних вимикачів.',
        datasheets: [
          {
            name: 'Корпус модуля для монтажної рейки для 1 модуля',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Патч-панелі',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Патч-панелі 19" 1HE для 24 Keystone-модулів з прямим виходом',
        text: 'Розподільне поле 19" 1HE може бути оснащене до 24 preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone-модулями. Воно має два рівні, завдяки чому модулі врівень із передньою панеллю. Розподільне поле можна замовити з нержавіючої сталі або порошково фарбованого заліза.\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Патч-панелі 19" 1HE для 48 Keystone-модулів з прямим виходом',
        text: 'Розподільне поле 19" 1HE може бути оснащене до 48 fixLink<sup>®</sup> Pro/fixLink<sup>®</sup> Pro+ RJ45 Keystone-модулями. Воно має два рівні, завдяки чому модулі врівень із передньою панеллю. Розподільне поле складається з порошково фарбованого заліза.\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Патч-панелі 19" 0,5HE для 24 Keystone-модулів з прямим виходом',
        text: 'Розподільне поле 19" 0,5HE може бути оснащене до 24 fixLink<sup>®</sup> RJ45 Keystone-модулями. Вони врівень із передньою панеллю.\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Патч-панелі 19" 1HE для 24 Keystone-модулів з вікнами для маркування',
        text: 'Розподільне поле 19" 1HE може бути оснащене до 24 preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone-модулями. Воно має два рівні, завдяки чому модулі врівень із передньою панеллю. Розподільне поле можна замовити з нержавіючої сталі або порошково фарбованого заліза. Крім того, можна позначити окремі порти за допомогою наданого листа для маркування, вирізаного на 12x33.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Кутова Патч-панелі 30° 19" 1HE для 24 Keystone-модулів',
        text: 'На кутовій панелі preLink<sup>®</sup>/fixLink<sup>®</sup> користувач має можливість вставити 24 Keystone-модулі під кутом 30°, завдяки чому підключені патч-корди автоматично спрямовуються до боку шафи і потрібно лише закріпити їх збоку від патч-поля. Таким чином, користувач може обійтися без додаткових панелей для управління кабелями. У роботі кутові панелі з плоскою передньою стороною і нахиленими патч-кордами займають менше місця вперед, ніж звичайні патч-панелі з вертикально встановленими портами.\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      baugruppentraeger: 'Тримачі груп',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Тримач груп 19" 3HE для використання з вставними модулями',
        text: 'Тримач модулів 19" 3HE для використання з вставними модулями 7TE в мідному і волоконно-оптичному варіантах. Вони закріплюються двома гвинтами з боку панелі на тримачі модуля. Вставні модулі можна повністю витягнути з тримача модуля. У тримачі модуля можна закріпити до 12x 7TE-вставних модулів.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Keystone-вставний модуль для тримачів модулів',
        text: 'Keystone-вставний модуль для оснащення тримачів модулів. Keystone-вставний модуль може бути оснащений до 6 preLink<sup>®</sup> або fixLink<sup>®</sup> Keystone-модулями. Keystone-вставний модуль має два рівні, завдяки чому модулі врівень із передньою панеллю. Фіксація кабелю здійснюється безпосередньо на корпусі вставного модуля. Можна закріпити 6 окремих кабелів або 1 магістральний кабель.\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Тримач модуля (BGT) 19" 1HE для використання з вставними модулями',
        text: 'Тримач модуля (BGT) для використання з вставними модулями 7TE в мідному і волоконно-оптичному варіантах. Вони закріплюються двома гвинтами з боку панелі на тримачі модуля. Вставні модулі можна повністю витягнути з тримача модуля. У тримачі модуля можна закріпити до 3x 7TE-вставних модулів (=18 портів).\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Бічний тримач модуля 3HE/14TE для використання з вставними модулями',
        text: 'Бічний тримач модуля (S-BGT) для використання з вставними модулями 7TE в мідному і волоконно-оптичному варіантах. Вставні модулі закріплюються двома гвинтами з боку панелі на тримачі модуля. Вставні модулі можна повністю витягнути з тримача модуля. У бічному тримачі модуля (S-BGT) можна закріпити до 2x 7TE-вставних модулів (=12 портів). Закріплення бічного тримача модуля (S-BGT) здійснюється на бічному отворі шафи. Це дозволяє легкий і швидкий монтаж. Завдяки бічному кріпленню шафи можна реалізувати компактне, особливо кліматично сприятливе кабелювання, не заважаючи повітряному потоку.\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.',
      },
      consolidation_point_panel: 'Патч-панелі консолідації, DIN-рейки тощо.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Підлоговий корпус точки консолідації 19" або 10" 1U',
        text: "Підлоговий корпус точки консолідації з змінними передніми панелями для організації різноманітних з'єднань в мідному і волоконно-оптичному варіантах. Додатково може бути оснащений замком для захисту від несанкціонованого доступу.\n\nМонтажний матеріал та Keystone-модулі не входять у комплект поставки.",
        datasheets: [
          {
            name: 'Патч-панелі точки консолідації 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Патч-панелі точки консолідації 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Корпус точки консолідації для 4-12 Keystone-модулів',
        text: 'Корпус точки консолідації для preLink®/fixLink® RJ45 Keystone-модулів RJ45 призначений для організації точок консолідації, монтаж на поверхню.\n\nМонтажний матеріал і Keystone-модулі не входять до комплекту постачання.',
      },
      trunkkabel: 'Магістральний кабель',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'Система розподілу SL CTBC Copper-Trunk-Bundled-Cable, клас EA ISO/IEC',
        text: 'Система розподілу CTBC підходить для гнучкого використання в структурованому кабелюванні будівель і дата-центрах. Кабелі, зібрані в плетений рукав, оснащені та перевірені на заводі fixLink<sup>®</sup> Keystone-модулями. Збірка може бути виконана як з прокладеними кабелями, так і з гнучкими кабелями. Максимальна довжина зібраного кабелю становить до 20 м.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'Система розподілу SL CTCC Copper-Trunk-Compact-Cable, клас EA ISO/IEC',
        text: 'Система розподілу CTCC підходить завдяки своєму мінімальному діаметру кабелю та відповідно низькому пожежному навантаженню для гнучкого використання в структурованому кабелюванні будівель і дата-центрах. Максимальна довжина багатоканального кабелю становить залежно від типу 60-90 м. Система розподілу як рішення для 10-гігабітного кабелювання дата-центрів щонайменше відповідає вимогам класу EA.',
      },
      hds_innengabel: 'H.D.S. внутрішній кабель',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. внутрішній кабель Кат. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. внутрішній кабель Кат. 7 S/FTP для простого кабелювання від шафи до шафи в дата-центрах і багатоканального підключення від розподільних до підрозподільних щитів. Конструкція кабелю складається з 24 парозахищених елементів кабелю, кожні 4 пари зібрані в один вузол. Кожен вузол має номерну стрічку для полегшення ідентифікації. Гнучкий центральний елемент захисту від натягу особливо добре захищає кабель при затягуванні. Оболонка складається з гнучкого матеріалу LSOH.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. внутрішній кабель Кат. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. внутрішній кабель Кат. 7 S/FTP для простого кабелювання від шафи до шафи в дата-центрах і багатоканального підключення від розподільних до підрозподільних щитів. Конструкція кабелю складається з 24 парозахищених елементів кабелю, кожні 4 пари зібрані в один вузол. Кожен вузол має номерну стрічку для полегшення ідентифікації. Гнучкий центральний елемент захисту від натягу особливо добре захищає кабель при затягуванні. Оболонка складається з гнучкого матеріалу LSOH.',
      },
      generic: {
        download: 'Завантажити',
      },
    },
  },

  imprint: {
    headline: 'Вихідні дані',
  },

  dataProtection: {
    headline: 'Захист даних',
  },

  footer: {
    dataProtection: 'Захист даних',
    imprint: 'Вихідні дані',
    contact: 'Контакт',
  },

  UI: {
    autocomplete: {
      searchError: 'Пошук не вдався.',
      searchEmpty: 'Немає результатів',
    },
    dropdown: {
      emptySelection: '--- виберіть ---',
    },
  },
}

export const langKey = 'UA'

const Default = {
  translation,
  langKey,
}

export default Default

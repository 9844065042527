export const translation = {
  header: {
    items: [
      {
        name: 'Početna',
        href: '/',
      },
      {
        name: 'Sistem',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montaža',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Jezik',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Jednostavnost',
        text2: 'u vezi',
      },
      subtitle: 'Tvoj link za budućnost',
      cta: 'više',
    },
    handling: {
      title: 'INTUITIVNO RUKOVANJE SRETNE NAPREDNU TEHNOLOGIJU',
      vorreiterPrefix: 'Kombinacija preciznosti i funkcionalnog dizajna čini ',
      vorreiterSuffix: ' vodećim izborom u tehnologiji povezivanja.',
      experte: ' nudi efikasna 360° rješenja koja optimalno spajaju dizajn i performanse, kako bi se smanjila složenost.',
      pionier: ' je pionir u transformaciji radnih prostora, spajajući stručnost s intuitivnim rukovanjem.',
    },
    download: {
      prefix: 'Pregled ',
      suffix: ' - Preuzmi sistem',
      cta: 'Preuzimanje',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Pregled proizvoda',
      title: 'FIXLINK<sup>®</sup> - SISTEM',
    },
    module: {
      overview: 'Preuzmite pregled',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Certifikati',
          text: 'Vjerujemo u GHMT i njihov Premium Verification Program za nezavisno osiguranje kvaliteta naših mrežnih proizvoda. Tako garantujemo najviše standarde proizvoda za naše kupce, bazirano na 20 godina stručnosti.',
        },
        datasheets: {
          title: 'Tehnički listovi',
          text: 'Ovdje možete pronaći sve potrebne tehničke listove. Preuzmite ih i imajte sve informacije na jednom mjestu.',
        },
        compatibilitySheets: {
          title: 'Liste kompatibilnosti',
          text: '',
        },
        excelTemplates: {
          title: 'Excel predlošci za označavanje',
          text: '',
        },
      },
    },
    video: {
      title: 'UPUTSTVO ZA MONTAŽU',
      text: 'Pogledajte video ili preuzmite PDF ovdje',
      manual: {
        title: 'UPUTSTVA ZA MONTAŽU:',
      },
    },
    separator: {
      title: 'Više od samo veze',
      title2: 'Otkrijte EasyLan svijet',
      text: 'Otkrijte raznolikost inovativnih mrežnih rješenja na EasyLan.de: Prilagođeno vama. Promjena igre za vašu mrežu: Naši sistemi kabliranja i rješenja za upravljanje kablovima.',
      cta: 'više',
    },
    combination: {
      bau: {
        prefix: 'Iskusite maksimalnu prilagodbu i kvalitetu,',
        text: ' EasyLan-ov nosač modula ',
        suffix: 'direktno iz Europe.',
      },
      intelligent: {
        prefix: 'Vaša mreža',
        text: ' prilagođena ',
        suffix: 's konfigurisanim EasyLan Splice Boxom',
      },
      fixlink: {
        prefix: 'S našim ',
        suffix: ' proizvodima možete dugoročno unaprijediti vaše poslovne zgrade.',
      },
      hds: {
        prefix: 'EasyLan<sup>®</sup> H.D.S. - sistem ',
        text: ' nudi jednostavnu instalaciju i održavanje kao nijedan drugi sistem trunk kabliranja na tržištu.',
        suffix: '',
      },
    },
    recommended: {
      titleLabel: 'Preporučeno',
      title: 'fixLink<sup>®</sup> - SISTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Garancija proizvoda',
        text: [
          'Garancija proizvoda uključuje sve komponente ',
          ' - optičkih i bakrenih kablovskih sistema, koje instalira kvalificirani instalater prema smjernicama ',
          ' i važećim instalacijskim normama EN50174.',
        ],
      },
      {
        title: 'Garancija performansi',
        text: [
          'Garancija performansi, pored aplikacije (Ethernet, Fiber Channel, ATM, itd.), garantuje svojstva sistema uključujući brzine prijenosa (10MBit, 100MBit, 1GBit i 10GBit).',
        ],
      },
      {
        title: 'Garancija aplikacija',
        text: [
          'Garancija aplikacija garantuje sve specificirane aplikacije (Ethernet, Fiber Channel, ATM, itd.) koje se baziraju na važećim standardima kabliranja prema ISO/IEC 11801, EN50173 i EIA/TIA 568B u trenutku instalacije.',
        ],
      },
    ],
    end: {
      title: 'VEZE NIKADA NISU BILE TAKO JEDNOSTAVNE.',
      text: 'Intuitivno rukovanje sreće naprednu tehnologiju; otkrijte besprijekornu vezu budućnosti: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Priključni moduli',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone modul Kat. 6A ISO/IEC, oklopljen',
        text: 'S fixLink<sup>®</sup> Pro RJ45 Keystone modulom, korisnik dobiva RJ45 utični modul najviše kvalitete. fixLink<sup>®</sup> Pro RJ45 Keystone modul može se sastaviti uz pomoć bočnih rezača za manje od jedne minute. Slobodna dostupnost kontaktne zone osigurava savršenu kvalitetu sastavljanja i time osigurava performanse prijenosa. Specijalna geometrija kontakta u kombinaciji s 30 μ" zlatnim premazom osigurava 4PPoE prema IEEE 802.3bt u svim uvjetima. Materijal kućišta je od niklanog tlačno lijevanog cinka, što zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajnerskim držačem modula, moguće je kombinirati s svim programima prekidača. Isporučuju se u ekološki prihvatljivom kartonskom pakiranju.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, oklopljen',
        text: 'S fixLink<sup>®</sup> Extenderom, kablovi se mogu lako produžiti. Također, neaktivni kablovi se mogu reaktivirati i produžiti do sljedećeg potrebnog priključka. Time se optimalno koristi prostor u kablovskom kanalu. Extender se jednostavno pričvršćuje na kraju instaliranog kabla u kanal ili na zid. Nakon instalacije, tako produženi kabl može se koristiti za 10-gigabitni Ethernet prijenos i podržava PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC, oklopljen',
        text: 'S fixLink<sup>®</sup> RJ45 priključnim modulom, korisnik dobiva modularni RJ45 priključak najviše kvalitete i fleksibilnosti. Specijalna geometrija kontakta u kombinaciji s 50 μ" zlatnim premazom osigurava 4PPoE prema IEEE 80.3bt u svim uvjetima. Materijal kućišta je od niklanog tlačno lijevanog cinka, što zadovoljava najviše ekološke i mehaničke zahtjeve. Razne certifikacije (aktualni popis: www.easylan.de) od nezavisnih laboratorija za ispitivanje potvrđuju najviši zahtjev za kvalitetom fixLink<sup>®</sup> sistema.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone modul Kat. 6A EIA/TIA, neoklopljen',
        text: 'S fixLink<sup>®</sup> RJ45 Keystone modulom (PoE+ kompatibilnim), korisnik dobiva RJ45 utični modul najviše kvalitete. fixLink<sup>®</sup> RJ45 Keystone modul može se sastaviti uz pomoć bočnih rezača za manje od jedne minute. Slobodna dostupnost kontaktne zone osigurava savršenu kvalitetu sastavljanja i time osigurava performanse prijenosa. Materijal kućišta je od polikarbonata, što zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajnerskim držačem modula, moguće je kombinirati s svim programima prekidača. Razne certifikacije od nezavisnih laboratorija za ispitivanje potvrđuju najviši zahtjev za kvalitetom fixLink<sup>®</sup> SL Keystone modula. Isporučuju se u ekološki prihvatljivom kartonskom pakiranju.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone modul Kat. 6A ISO/IEC, oklopljen',
        text: 'S fixLink<sup>®</sup> SL RJ45 Keystone modulom, korisnik dobiva RJ45 utični modul najviše kvalitete. fixLink<sup>®</sup> SL RJ45 Keystone modul može se sastaviti uz pomoć bočnih rezača za manje od jedne minute. Slobodna dostupnost kontaktne zone osigurava savršenu kvalitetu sastavljanja i time osigurava performanse prijenosa. Za 360° zaštitu je dovoljno pričvrstiti integrirani klin, čime se izbjegava vremenski zahtjevno postavljanje vezice. Specijalna geometrija kontakta u kombinaciji s 50 μ" zlatnim premazom osigurava 4PPoE prema IEEE 802.3bt u svim uvjetima. Materijal kućišta je od niklanog tlačno lijevanog cinka, što zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajnerskim držačem modula, moguće je kombinirati s svim programima prekidača. Razne certifikacije od nezavisnih laboratorija za ispitivanje potvrđuju najviši zahtjev za kvalitetom fixLink<sup>®</sup> SL Keystone modula. Isporučuju se u ekološki prihvatljivom kartonskom pakiranju.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Izvještaj o ispitivanju 4PoE prema DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone modul zakrivljen Kat. 6A ISO/IEC, oklopljen',
        text: 'S fixLink<sup>®</sup> SL RJ45 Keystone modulom, korisnik dobiva RJ45 utični modul najviše kvalitete, posebno razvijen za aplikacije s ograničenim prostorom. Svojim kablovskim izlazom od 75°, modul je posebno pogodan za upotrebu u podatkovnim utičnicama i šupljim kablovskim instalacijama. Minimalna dubina ugradnje od 27 mm omogućava gotovo neograničene primjene. fixLink<sup>®</sup> SL RJ45 Keystone modul može se sastaviti uz pomoć bočnih rezača za manje od jedne minute. Slobodna dostupnost kontaktne zone osigurava savršenu kvalitetu sastavljanja i time osigurava performanse prijenosa. Specijalna geometrija kontakta u kombinaciji s 50 μ" zlatnim premazom osigurava PoE+ prema IEEE 80.3at u svim uvjetima. Materijal kućišta je od niklanog tlačno lijevanog cinka, što zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajnerskim držačem modula, moguće je kombinirati s svim programima prekidača. Razne certifikacije od nezavisnih laboratorija za ispitivanje potvrđuju najviši zahtjev za kvalitetom fixLink<sup>®</sup> SL Keystone modula. Isporučuju se u ekološki prihvatljivom kartonskom pakiranju.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Alati i dodaci',
        text: [
          'Alat za skidanje izolacije za uklanjanje vanjskog omotača i metalnih folija podatkovnog kabla. Plava boja.',
          'Bakarna traka za fiksiranje ekrana. Prethodno izrezana na 35 x 9 mm. Jedinica pakovanja: 20 komada/kesa 1500 komada/rolna',
          'Obojeni poklopci za zaštitu od prašine za preLink® SL i fixLink® SL Keystone module.',
          'Jedinica pakovanja 50 komada/plastična kesa',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP certifikat Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Instalacioni kabel',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabel EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Podatkovni kabel za prenos analognih i digitalnih signala pri kabliranju u primarnom, sekundarnom i tercijarnom području. Pogodan za primjene do klase FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabel EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Podatkovni kabel za prenos analognih i digitalnih signala pri kabliranju u primarnom, sekundarnom i tercijarnom području. Pogodan za primjene do klase FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabel EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Podatkovni kabel za prenos analognih i digitalnih signala pri kabliranju u primarnom, sekundarnom i tercijarnom području. Pogodan za primjene do klase FA.',
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Podatkovne utičnice',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Nadzemna kućišta i okviri za gore navedene module',
        text: [
          'Nadzemno kućište IP20, 80x80x42mm za ugradnju EasyLan modula',
          'Međurazmak za nadzemno kućište za proširenje visine za 10mm',
          'Poklopni okvir 1-fach 80x80mm',
          'Poklopni okvir 2-fach 80x150mm',
        ],
        datasheets: [
          {
            name: 'Nadzemno kućište',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Proširenje dubine nadzemnog kućišta',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Modul za SL, 45°-izlaz UP, BK, AP, BT 1-fach, 2-fach, 3-fach',
        text: 'SL moduli su posebno pogodni za ugradnju u kablovske kanale (EK), ugradnju u zid (UP) i nadzemnu montažu (AP). Uklanjanjem dva montažna držača moguća je i montaža u podni kanal (BT). SL moduli mogu sigurno i trajno primiti jedan do tri EasyLan Keystones. Imaju veliki, središnje smješten prozor za označavanje za jednostavno označavanje. Središnja ploča (50x50 mm) i okvir (80x80 mm) su izrađeni od UV-otpornog i izuzetno otpornog plastičnog materijala. Time je osiguran dugotrajan i postojan izgled.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Dizajnirani modul SL, 45°-izlaz UP, BK, AP, BT 1-fach, 2-fach',
        text: 'Dizajnirani modul se sastoji od okvira od tlačno lijevanog cinka. Pogodan je za ugradnju u kablovski kanal (EK), za ugradnju u zid (UP) ili pomoću zasebnog okvira za nadzemnu montažu (AP). Uklanjanjem dva montažna držača moguća je i montaža u podni kanal (BT). Dizajnirani podatkovni priključak je kompatibilan s gotovo svim dostupnim prekidačima na tržištu. Zbog UAE-kompatibilnosti, dizajnirani podatkovni priključak nema prostor za poklopac za zaštitu od prašine.',
        datasheets: [
          {
            name: '1-/2-fach Keystone, bez središnje ploče',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-fach Keystone, sa središnjom pločom 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-fach sa središnjom pločom',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Modul IP44 nadzemna, 1-fach, 2-fach',
        text: 'Robusno nadzemno kućište je prikladno za ugradnju dva preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone modula pomoću adaptera. Za uvod dva kabla promjera 5,0 - 9,5 mm, na stražnjoj strani kutije nalaze se dva lako probijajuća mjesta. Modul postiže zaštitu IP20 u priključenom stanju, a zaštitu IP44 u zatvorenom stanju.',
      },
      DoseUPleer2xKS: {
        name: 'Modul s ravnim izlazom, 2-fach',
        text: 'Modul s ravnim izlazom Keystone sastoji se od okvira od tlačno lijevanog cinka i pogodan je za ugradnju u kablovski kanal (EK), ugradnju u zid (UP) ili pomoću zasebnog okvira za nadzemnu montažu (AP).',
      },
      bodentank: 'Podni kanali',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann podni kanali UT3, UT4',
        text: 'Nosač ploča zamjenjuje nosače uređaja i tako nudi maksimalan prostor za kablovski ulaz. Kosi ulaz i izlaz omogućavaju sigurnu kablovsku instalaciju čak i kod vrlo niskih međusobnih visina. Predviđena je kombinacija s pločama za ugradnju 4x preLink<sup>®</sup>/fixLink<sup>®</sup> modula ili 1x H.D.S. Pločice za ugradnju pričvršćuju se s dvije vijka na nosač ploče, a neiskorištena polja nosača mogu se pokriti slijepim pločama.',
        datasheets: [
          {
            name: 'Nosač ploča za UT3 bez ploče za ugradnju',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Nosač ploča za UT4 bez ploče za ugradnju',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann osnovni nosač podnih kanala UT3, UT4',
        text: 'Nosač podni kanala Keystone zamjenjuje nosač uređaja i tako nudi maksimalan prostor za kablovski ulaz. Kosi ulaz i izlaz omogućavaju sigurnu kablovsku instalaciju čak i kod vrlo niskih međusobnih visina. Pločice za ugradnju pričvršćuju se s dvije vijka na nosač ploče, a neiskorištena polja nosača mogu se pokriti slijepim pločama. Pomoću adaptera, nosač podnih kanala može se koristiti i za nosače uređaja Electraplan.',
        datasheets: [
          {
            name: 'Nosač podnih kanala UT3 za do 6 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Nosač podnih kanala UT4 za do 9 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Nosač podnih kanala UT4 za do 12 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK osnovni nosač podnih kanala UT4',
        text: 'Nosač podnih kanala Keystone zamjenjuje nosač uređaja i tako nudi maksimalan prostor za kablovski ulaz. Kosi ulaz i izlaz omogućavaju sigurnu kablovsku instalaciju čak i kod vrlo niskih međusobnih visina. Pločice za ugradnju pričvršćuju se s dvije vijka na nosač ploče, a neiskorištena polja nosača mogu se pokriti slijepim pločama. Nosači podnih kanala kompatibilni su s PUK sistemom podnih kanala.',
        datasheets: [
          {
            name: 'Nosač podnih kanala UT4 za do 9 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Nosač podnih kanala UT4 za do 12 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann moduli za podne kanale UT3, UT4, dodatna oprema',
        text: 'Moduli za priključke su necinkovani i pocinčani radi uzemljenja. Pločice za ugradnju pričvršćuju se s dvije vijka na nosač ploče, a neiskorištena polja nosača mogu se pokriti slijepim pločama.',
        datasheets: [
          {
            name: 'Pločica za ugradnju za 4x RJ45 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Pločica za ugradnju za 3x RJ45 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adapter za DIN šine',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktno kućište modula za DIN šine za 1 modul',
        text: 'Kućište modula za DIN šine za preLink<sup>®</sup>- / fixLink<sup>®</sup>-module, pogodno za razvodne ormare bez poklopca za osigurače.',
        datasheets: [
          {
            name: 'Kompaktno kućište modula za DIN šine za 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone-F-spojnica i slijepa pločica Keystone praznog modula',
        text: 'F-spojnica i slijepa pločica kao zamjena za module\nF-spojnica služi za integraciju koaksijalnih kablova u preLink<sup>®</sup> / fixLink<sup>®</sup> patch panela i program utičnica. preLink<sup>®</sup>/fixLink<sup>®</sup> slijepa pločica može se koristiti u razvodnom polju kao i u utičnicama za prekrivanje neiskorištenih portova.\n\nPrazni modul za SC-S odnosno LC-D kao zamjena za module\nPrazni modul za SC-S odnosno LC-D spojnice u razvodnom polju ili utičnici.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Kućište modula za DIN šine za 1 modul',
        text: 'Kućište modula za DIN šine za preLink<sup>®</sup>- / fixLink<sup>®</sup>-module, pogodno za razvodne ormare s poklopcem za osigurače.',
        datasheets: [
          {
            name: 'Kućište modula za DIN šine za 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE za 24 Keystone modula s ravnim izlazom',
        text: 'Razvodno polje 19" 1HE može se popuniti s do 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modula. Sastavljeno je u dvije razine, tako da su moduli u ravnini s prednjom pločom. Razvodno polje može se naručiti u nehrđajućem čeliku ili u praškastom obloženom željeznom limu.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE za 48 Keystone modula s ravnim izlazom',
        text: 'Razvodno polje 19" 1HE može se popuniti s do 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone modula. Sastavljeno je u dvije razine, tako da su moduli u ravnini s prednjom pločom. Razvodno polje je izrađeno od praškasto obloženog željeznog lima.\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE za 24 Keystone modula s ravnim izlazom',
        text: 'preLink<sup>®</sup> / fixLink<sup>®</sup> razvodno polje 19" 0,5HE može se popuniti s do 24 fixLink<sup>®</sup> RJ45 Keystone modula. Svi su u ravnini s prednjom pločom.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE za 24 Keystone modula uključujući prozor za označavanje',
        text: 'Razvodno polje 19" 1HE može se popuniti s do 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modula. Sastavljeno je u dvije razine, tako da su moduli u ravnini s prednjom pločom. Razvodno polje može se naručiti u nehrđajućem čeliku ili u praškastom obloženom željeznom limu. Dodatno, postoji mogućnost označavanja pojedinačnih portova pomoću priloženog, 12x33 perforiranog lista za označavanje.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Kutni panel 30° 19" 1HE za 24 Keystone modula',
        text: 'Kod preLink<sup>®</sup> / fixLink<sup>®</sup> kutnog panela, korisnik ima mogućnost ugraditi 24 Keystone mod 30° u panel, čime se povezani patch kablovi automatski usmjeravaju prema strani ormara i trebaju biti samo pričvršćeni sa strane panele. Na taj način korisnik može izbjeći dodatne panele za vođenje kablova. Kutni paneli s ravnom prednjom stranom i zakrenutim patch kablovima zahtijevaju manje prostora naprijed nego uobičajeni patch paneli s vertikalno ugrađenim portovima.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      baugruppentraeger: 'Nosači modula',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Nosač modula 19" 3HE za upotrebu s ugradnim modulima',
        text: 'Nosač modula 19" 3HE za upotrebu s 7TE ugradnim modulima za bakar i optička vlakna. Pričvršćuju se s 2 vijka s prednje strane nosača. Ugradni moduli mogu se potpuno izvući iz nosača. U nosač se može pričvrstiti maksimalno 12x 7TE ugradnih modula.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Keystone ugradni modul za nosače modula',
        text: 'Keystone ugradni modul za popunjavanje nosača modula. Keystone ugradni modul može se popuniti s do 6 preLink<sup>®</sup> odnosno fixLink<sup>®</sup> Keystone modula. Keystone ugradni modul je dvostruko izveden, tako da su moduli u ravnini s prednjom pločom. Pričvršćivanje kablova vrši se direktno na kućište ugradnog modula. Može se pričvrstiti 6 pojedinačnih kablova ili 1 trunk kabl.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Nosač modula (BGT) 19" 1HE za upotrebu s ugradnim modulima',
        text: 'Nosač modula (BGT) za upotrebu s 7TE ugradnim modulima za bakar i optička vlakna. Pričvršćuju se s 2 vijka s prednje strane nosača. Ugradni moduli mogu se potpuno izvući iz nosača. U nosač se može pričvrstiti maksimalno 3x 7TE ugradnih modula (=18 portova).\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Bočni nosač modula 3HE/14TE za upotrebu s ugradnim modulima',
        text: 'Bočni nosač modula (S-BGT) za upotrebu s 7TE ugradnim modulima za bakar i optička vlakna. Ugradni moduli pričvršćuju se s 2 vijka s prednje strane nosača. Ugradni moduli mogu se potpuno izvući iz nosača. U nosač se može pričvrstiti maksimalno 2x 7TE ugradnih modula (=12 portova). Pričvršćivanje bočnog nosača modula (S-BGT) vrši se na bočnoj perforaciji ormara. Na taj način omogućena je jednostavna i brza montaža. Bočna montaža nosača modula omogućava prostorno štedljivo, posebno klimatski prihvatljivo kabliranje, bez ometanja strujanja zraka.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      consolidation_point_panel: 'Panel tačke konsolidacije, DIN šine itd.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Podni Consolidation Point Panel 19" ili 10" 1HE',
        text: 'Podni Consolidation Point Panel s izmjenjivim prednjim pločama za prihvat različitih priključaka za bakar i optička vlakna. Opcionalno, podni Consolidation Point Panel može se osigurati bravom protiv neovlaštenog pristupa.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
        datasheets: [
          {
            name: 'Consolidation Point Panel 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Consolidation Point Panel 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Nadzemni Consolidation Point Panel za 4-12 Keystone modula',
        text: 'Nadzemni Consolidation Point Panel za preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone module, za upotrebu kao Consolidation Panel, za direktnu montažu ili pomoću montažnog seta za montažu na DIN šine u elektrodistribucijskim ormarima. Nadzemni Consolidation Point Panel je dvostruko izveden. Tako su moduli u ravnini s prednjom pločom.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      trunkkabel: 'Trunk kablovi',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC sistem za raspodjelu Copper-Trunk-Bundled-Cable, klasa EA ISO/IEC',
        text: 'CTBC sistem za raspodjelu pogodan je za fleksibilnu upotrebu u strukturiranom kabliranju zgrada i u podatkovnim centrima. Kablovi, snopljeni u zaštitnu cijev, tvornički su spojeni s fixLink<sup>®</sup> Keystone modulima i testirani. Snop se može sastaviti s instalacijskim kablovima ili fleksibilnim kablovima. Maksimalna dužina snopa kablova iznosi do 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC sistem za raspodjelu Copper-Trunk-Compact-Cable, klasa EA ISO/IEC',
        text: 'CTCC sistem za raspodjelu pogodan je zbog minimalnog promjera kablova i, shodno tome, male vatrene opterećenosti za fleksibilnu upotrebu u strukturiranom kabliranju zgrada i u podatkovnim centrima. Maksimalna dužina višestrukog kabla iznosi ovisno o tipu 60-90 m. Sistem za raspodjelu kao 10-Gbit rješenje za podatkovne centre ispunjava najmanje zahtjeve klase EA.',
      },
      hds_innengabel: 'H.D.S. unutarnji kablovi',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. unutarnji kablovi Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. unutarnji kablovi Kat. 7 S/FTP za jednostavno kabliranje između ormara u podatkovnim centrima i višestruke veze od razvodnih do podrazvodnih ormara. Struktura kabla sastoji se od 24 para zaštićenih kablovskih elemenata, pri čemu su 4 para sastavljena u jedan element. Svaki element je označen brojevima za lakšu identifikaciju. Fleksibilni centralni element za rasterećenje naprezanja posebno štiti kabl prilikom uvlačenja. Plašt je izrađen od fleksibilnog LSOH materijala.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. unutarnji kablovi Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. unutarnji kablovi Kat. 7 S/FTP za jednostavno kabliranje između ormara u podatkovnim centrima i višestruke veze od razvodnih do podrazvodnih ormara. Struktura kabla sastoji se od 24 para zaštićenih kablovskih elemenata, pri čemu su 4 para sastavljena u jedan element. Svaki element je označen brojevima za lakšu identifikaciju. Fleksibilni centralni element za rasterećenje naprezanja posebno štiti kabl prilikom uvlačenja. Plašt je izrađen od fleksibilnog LSOH materijala.',
      },
      generic: {
        download: 'Preuzimanje',
      },
    },
  },

  imprint: {
    headline: 'Impresum',
  },

  dataProtection: {
    headline: 'Zaštita podataka',
  },

  footer: {
    dataProtection: 'Zaštita podataka',
    imprint: 'Impresum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Pretraga nije mogla biti izvršena.',
      searchEmpty: 'Nema rezultata',
    },
    dropdown: {
      emptySelection: '--- molimo odaberite ---',
    },
  },
}

export const langKey = 'BS'

const Default = {
  translation,
  langKey,
}

export default Default

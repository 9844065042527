import './style.css'
import ElasticCarousel from 'react-elastic-carousel'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { useRef } from 'react'

type Props = {
  key: string
  items: React.ReactNode[]
  itemsToShow?: number
  gap?: number
  wideControls?: boolean
  roundArrows?: boolean
  className?: string
}

const Carousel: React.FC<Props> = ({ key, items, itemsToShow = 3, gap = 15, wideControls = false, roundArrows = false, className = '' }) => {
  const carouselRef = useRef(null)

  const breakpoints = [
    {
      width: 0,
      itemsToShow: 1,
    },
    {
      width: 750,
      itemsToShow,
    },
  ]
  const arrows = ({ type, onClick }) => {
    const isPrev = type === 'PREV'
    const pointer = isPrev ? <ChevronLeftIcon className="w-8 fill-gray" /> : <ChevronRightIcon className="w-8 fill-gray" />
    return (
      <div
        className={`absolute z-10 h-full flex items-end ${
          isPrev ? `left-0 ${wideControls ? 'lg:-left-12' : 'lg:left-1'}` : `right-0 ${wideControls ? 'lg:-right-12' : 'lg:right-1'}`
        }`}
      >
        <div
          className={`w-12 aspect-square rounded-full bg-light cursor-pointer flex items-center justify-center ${roundArrows && 'bg-light'}`}
          onClick={onClick}
        >
          {pointer}
        </div>
      </div>
    )
  }

  const pagination = ({ pages, activePage, onClick }) => {
    return (
      <div className="flex flex-row gap-2 pb-5">
        {pages.map((page: number) => {
          const isActivePage = activePage === page
          return (
            <div
              key={page}
              className={`w-10 h-1 cursor-pointer ${isActivePage ? 'bg-danger' : 'bg-light'} transition-all`}
              onClick={() => onClick(page)}
            />
          )
        })}
      </div>
    )
  }

  const getFillerChildren = () => {
    if (items.length < itemsToShow)
      return Array(itemsToShow - 1)
        .fill(null)
        .map((_, i) => <div key={i} className="w-full" />)
    return null
  }

  return (
    // @ts-ignore
    <ElasticCarousel
      ref={carouselRef}
      className={`custom-carousel relative ${className}`}
      breakPoints={breakpoints}
      isRTL={false}
      renderArrow={arrows}
      renderPagination={pagination}
      itemPadding={[0, gap]}
      key={key}
    >
      {items}
      {getFillerChildren()}
    </ElasticCarousel>
  )
}

export default Carousel

export const translation = {
  header: {
    items: [
      {
        name: 'Home',
        href: '/',
      },
      {
        name: 'System',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montage',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Sprache',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Einfachheit',
        text2: 'in Verbindung',
      },
      subtitle: 'Dein Link für die Zukunft',
      cta: 'mehr',
    },
    handling: {
      title: 'INTUITIVE HANDHABUNG TRIFFT AUF FORTSCHRITTLICHE TECHNOLOGIE',
      vorreiterPrefix: 'Die Kombination aus Präzision und funktionalem Design macht ',
      vorreiterSuffix: ' zur führenden Wahl in der Vernetzungstechnologie.',
      experte: ' bietet effiziente 360°-Lösungen, die Design und Leistung optimal verbindet, um Komplexität zu minimieren.',
      pionier: ' ist Pionier in der Umgestaltung von Arbeitsbereichen, der Expertise mit intuitiver Handhabung verbindet.',
    },
    download: {
      prefix: 'Übersicht ',
      suffix: ' - System herunterladen',
      cta: 'Download',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Produktübersicht',
      title: 'FIXLINK<sup>®</sup> - SYSTEM',
    },
    module: {
      overview: 'Übersicht herunterladen',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Zertifikate',
          text: 'Wir vertrauen auf die GHMT und ihr Premium Verification Program zur unabhängigen Qualitätssicherung unserer Netzwerkprodukte. So garantieren wir, basierend auf 20 Jahren Expertise, höchste Produktstandards für unsere Kunden.',
        },
        datasheets: {
          title: 'Datenblätter',
          text: 'Hier findest du alle notwendigen Datenblätter. Lade sie dir runter und habe alle Infos auf einen Blick.',
        },
        compatibilitySheets: {
          title: 'Kompatibilitätslisten',
          text: '',
        },
        excelTemplates: {
          title: 'Beschriftungsfenster als Excel Vorlage',
          text: '',
        },
      },
    },
    video: {
      title: 'MONTAGEANLEITUNG',
      text: 'Schau dir das Video an oder lade dir hier einfach die PDF runter',
      manual: {
        title: 'MONTAGEANLEITUNGEN:',
      },
    },
    separator: {
      title: 'Mehr als nur Verbindung',
      title2: 'Entdecke den EasyLan Kosmos',
      text: 'Entdecke die Vielfalt an innovativen Netzwerklösungen auf EasyLan.de: Individuell auf dich zugeschnitten. Der GameChanger für dein Netzwerk: Unsere Verkabelungssysteme und Kabelmanagementlösungen.',
      cta: 'mehr',
    },
    combination: {
      bau: {
        prefix: 'Erlebe mit maximale Anpassung und Qualität,',
        text: " EasyLan's Baugruppenträger ",
        suffix: 'direkt aus Europa.',
      },
      intelligent: {
        prefix: 'Dein Netzwerk',
        text: ' maßgeschneidert ',
        suffix: 'mit der konfigurierbaren EasyLan Spleißbox',
      },
      fixlink: {
        prefix: 'Mit unseren ',
        suffix: ' Produkten können Sie ihre Bürogebäude langfristig Verbessern.',
      },
      hds: {
        prefix: 'Das',
        text: ' EasyLan<sup>®</sup> H.D.S. - System ',
        suffix: 'vereint Installations- und Wartungsfreundlichkeit wie kein anderes Trunkverkabelungssystem am Markt.',
      },
    },
    recommended: {
      titleLabel: 'Recommended',
      title: 'fixLink<sup>®</sup> - SYSTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Produkt-Garantie',
        text: [
          'Die Produktgarantie beinhaltet sämtliche Komponenten eines ',
          '- Glasfaser - bzw. Kupfer - Verkabelungssystems, welche von einem qualifizierten Installateur gemäß den Vorgaben von ',
          ' sowie den jeweils gültigen Installationsnormen EN50174 installiert und geprüft wurden.',
        ],
      },
      {
        title: 'Performance-Garantie',
        text: [
          'Die Performance - Garantie, garantiert zusätzlich zur Applikation (Ethernet, Fiber Channel, ATM, etc.) die Systemeigenschaften u.a. die Übertragungsgeschwindigkeiten (10MBit, 100MBit, 1GBit und 10GBit).',
        ],
      },
      {
        title: 'Applikations-Garantie',
        text: [
          'Die Applikations - Garantie garantiert sämtliche spezifizierten Anwendungen (Ethernet, Fiber Channel, ATM, etc.), welche auf den folgenden jeweils zum Zeitpunkt der Installation geltenden Verkabelungsstandards nach ISO/IEC 11801, EN50173 und EIA/TIA 568B basieren.',
        ],
      },
    ],
    end: {
      title: 'VERBINDUNGEN WAREN NOCH NIE SO EINFACH.',
      text: 'Intuitive Handhabung trifft auf fortschrittliche Technologie; entdecken Sie die nahtlose Verbindung der Zukunft: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Anschlussmodule',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-Modul Kat. 6A ISO/IEC, geschirmt',
        text: 'Mit dem fixLink<sup>®</sup> Pro RJ45 Keystone-Modul erhält der Anwender ein RJ45-Buchsenmodul höchster Güte. Das fixLink<sup>®</sup> Pro RJ45 Keystone-Modul kann mittels Seitenschneider in unter einer Minute konfektioniert werden. Durch die frei zugängliche Kontaktzone ist eine perfekte Konfektionsqualität und dadurch gesicherte Übertragungsperformance gewährleistet. Die spezielle Kontaktgeometrie in Verbindung mit einer 30 μ" Goldauflage stellt 4PPoE gemäß IEEE 802.3bt unter allen Gegebenheiten sicher. Der Gehäuse- werkstoff besteht aus vernickeltem Zinkdruckguss und erfüllt folglich höchste Umwelt- und mechanische Anforderungen. In Verbindung mit der designfähigen Modulaufnahme ist eine Kombination mit allen Schalterprogrammen möglich. Sie werden in einer umweltfreundlichen Kartonverpackung geliefert.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanent Link Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, geschirmt',
        text: 'Mit dem fixLink<sup>®</sup> Extender können Leitungen ohne viel Aufwand verlängert werden. Des Weiteren können stillgelegte Leitungen reaktiviert und bis zum nächsten benötigten Anschluss verlängert werden. Der knappe Raum im Kabelkanal wird damit optimal genutzt. Der Extender wird einfach am Ende der installierten Leitung in den Kanal oder an die Wand geschraubt. Nach erfolgter Installation kann die so verlängerte Leitung bis zur maximalen Linklänge selbst für 10-Gigabit-Ethernet-Übertragungen genutzt werden und unterstützt PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC geschirmt',
        text: 'Mit dem fixLink<sup>®</sup> RJ45 Steckermodul erhält der Anwender ein feldkonfektionierbares RJ45-Steckermodul höchster Güte und Flexibilität. Die spezielle Kontaktgeometrie in Verbindung mit einer 50 μ" Goldauflage stellt 4PPoE gemäß IEEE 80.3bt unter allen Gegebenheiten sicher. Der Gehäusewerkstoff besteht aus vernickeltem Zinkdruckguss und erfüllt folglich höchste Umwelt- und mechanische Anforderungen. Diverse Zertifizierungen (aktuelle Auflistung: www.easylan.de) von unabhängigen Prüflaboren runden den höchsten Qualitätsanspruch des fixLink<sup>®</sup>-Systems ab.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-Modul Kat. 6A EIA/TIA, ungeschirmt',
        text: 'Mit dem fixLink<sup>®</sup> RJ45 Keystone-Modul (PoE+ fähig) erhält der Anwender ein RJ45-Buchsenmodul höchster Güte. Das fixLink<sup>®</sup> RJ45 Keystone-Modul kann mittels Seitenschneider unter einer Minute konfektioniert werden. Durch die frei zugängliche Kontaktzone ist eine perfekte Konfektionsqualität und dadurch gesicherte Übertragungsperformance gewährleistet. Der Gehäusewerkstoff besteht aus Polycarbonat und erfüllt folglich höchste Umwelt- und mechanische Anforderungen. In Verbindung mit der designfähigen Modulaufnahme ist eine Kombination mit allen Schalterprogrammen möglich. Diverse Zertifizierungen von unabhängigen Prüflaboren runden den höchsten Qualitätsanspruch des fixLink<sup>®</sup> SL Keystone-Moduls ab. Sie werden in einer umweltfreundlichen Kartonverpackung geliefert.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-Modul Kat. 6A ISO/IEC, geschirmt',
        text: 'Mit dem fixLink<sup>®</sup> SL RJ45 Keystone-Modul erhält der Anwender ein RJ45-Buchsenmodul höchster Güte. Das fixLink<sup>®</sup> SL RJ45 Keystone-Modul kann mittels Seitenschneider unter einer Minute konfektioniert werden. Durch die frei zugängliche Kontaktzone ist eine perfekte Konfektionsqualität und dadurch gesicherte Übertragungsperformance gewährleistet. Zur 360°-Schirmabnahme wird lediglich der integrierte Keil verrastet, die zeitaufwendige Anbringung eines Kabelbinders entfällt. Die spezielle Kontaktgeometrie in Verbindung mit einer 50 μ" Goldauflage stellt 4PPoE gemäß IEEE 802.3bt unter allen Gegebenheiten sicher. Der Gehäuse- werkstoff besteht aus vernickeltem Zinkdruckguss und erfüllt folglich höchste Umwelt- und mechanische Anforderungen. In Verbindung mit der designfähigen Modulaufnahme ist eine Kombination mit allen Schalterprogrammen möglich. Diverse Zertifizierungen von unabhängigen Prüflaboren runden den höchsten Qualitätsanspruch des fixLink<sup>®</sup> SL Keystone-Moduls ab. Sie werden in einer umweltfreundlichen Kartonverpackung geliefert.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Prüfbericht 4PoE nach der DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanent Link Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-Modul gewinkelt Kat. 6A ISO/IEC, geschirmt',
        text: 'Mit dem fixLink<sup>®</sup> SL RJ45 Keystone-Modul erhält der Anwender ein RJ45-Buchsenmodul höchster Güte, welches speziell für Anwendungen mit geringem Platzbedarf entwickelt wurde. Durch seinen 75° Kabelabgang eignet sich das Modul insbesondere für den Einsatz in Datendosen und Hohlraum Verkabelungen. Eine minimale Einbautiefe von 27 mm erlaubt eine nahezu unbegrenzte Anwendungsvielfalt. Das fixLink<sup>®</sup> SL RJ45 Keystone-Modul kann mittels Seitenschneider unter einer Minute konfektioniert werden. Durch die frei zugängliche Kontaktzone ist eine perfekte Konfektionsqualität und dadurch gesicherte Übertragungs- performance gewährleistet. Die spezielle Kontaktgeometrie in Verbindung mit einer 50 μ" Goldauflage stellt PoE+ gemäß IEEE 80.3at unter allen  Gegebenheiten sicher. Der Gehäusewerkstoff besteht aus vernickeltem Zinkdruckguss und erfüllt folglich höchste Umwelt- und mechanische Anforderungen. In Verbindung mit der designfähigen Modulaufnahme ist eine Kombination mit allen Schalterprogrammen möglich. Diverse Zertifizierungen von unabhängigen Prüflaboren runden den höchsten Qualitätsanspruch des fixLink<sup>®</sup> SL Keystone-Moduls ab. Sie werden in einer umweltfreundlichen Kartonverpackung geliefert.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Werkzeuge und Zubehör',
        text: [
          'Abmantelwerkzeug zum Absetzen des Kabelmantels und der Metallfolien eines Datenkabels. Farbe blau.',
          'Kupferband zum Fixieren des Geflechtschirms. Vorgeschnitten auf 35 x 9 mm. Verpackungseinheit: 20 Stück/Tüte 1500 Stück/Rolle',
          'Farbige Staubschutzklappen für preLink® SL und fixLink® SL Keystone-Module.',
          'Verpackungseinheit 50 Stück/Polybeutel',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Verlegekabel',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Datenkabel EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Datenkabel zur Übertragung analoger und digitaler Signale bei Verkabelung im Primär-, Sekundär- und Tertiärbereich. Geeignet für Anwendungen bis Klasse FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'GHMT Permanent Link Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Datenblatt',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Datenkabel EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Datenkabel zur Übertragung analoger und digitaler Signale bei Verkabelung im Primär-, Sekundär- und Tertiärbereich. Geeignet für Anwendungen bis Klasse FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Zertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Datenblatt',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Datenkabel EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Datenkabel zur Übertragung analoger und digitaler Signale bei Verkabelung im Primär-, Sekundär- und Tertiärbereich. Geeignet für Anwendungen bis Klasse FA.',
        datasheets: [
          {
            name: 'Datenblatt',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Datendosen',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'AP-Gehäuse und Rahmen für vorgenannte Modulaufnahmen',
        text: [
          'Aufputzgehäuse IP20, 80x80x42mm zum Einbau von EasyLan Modulaufnahmen',
          'Zwischenrahmen für Aufputzgehäuse zur Höhenerweiterung um 10mm',
          'Abdeckrahmen 1-fach 80x80mm',
          'Abdeckrahmen 2-fach 80x150mm',
        ],
        datasheets: [
          {
            name: 'Aufputzgehäuse',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Tiefenerweiterung AP-Gehäuse',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Modulaufnahme SL, 45°-Abgang UP, BK, AP, BT 1-fach, 2-fach, 3-fach',
        text: 'Die Modulaufnahmen SL sind besonders für den Einbau im Kabelkanal (EK), die Unterputzmontage (UP) und zur Aufputzmontage (AP) geeignet. Durch das Wegbrechen von zwei Montagelaschen ist auch eine Bodentankmontage (BT) möglich. Die Modulaufnahme SL kann ein bis drei EasyLan- Keystones absolut sicher und dauerhaft aufnehmen. Sie besitzt ein großes, zentral befindliches Beschriftungsfenster für eine einfache Kennzeichnung. Die Zentralplatte (50x50 mm) und der Rahmen (80x80 mm) bestehen aus UV-unempfindlichem und extrem bruchsicherem Kunststoff. Dadurch ist ein langlebiges und farbtreues Erscheinungsbild sichergestellt.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Designfähige Modulaufnahme SL, 45°-Abgang UP, BK, AP, BT 1-fach, 2-fach',
        text: 'Die designfähige Modulaufnahme besteht aus einen Zinkdruckgussrahmen. Sie eignet sich für den Einbau in einen Kabelkanal (EK), für Unterputzmontage (UP) oder mittels separatem Rahmen auch zur Aufputzmontage (AP). Durch das Wegbrechen von zwei Montagelaschen ist auch eine Bodentankmontage (BT) möglich. Die designfähige Datendose ist mit nahezu allen am Markt erhältlichen Schalterprogrammen kombinierbar. Durch die UAE-Kompatibilität bietet die designfähige Datendose keinen Platz für eine Staubschutzklappe.',
        datasheets: [
          {
            name: '1-/2-fach Keystone, ohne Zentralplatte',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-Fach Keystone, mit Zentralplatte 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-fach mit Zentralplatte',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Modulaufnahme IP44 Aufputz, 1-fach, 2-fach',
        text: 'Das robuste Aufputzgehäuse ist mittels Adaptereinsatz für den Einbau von zwei preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone-Modulen geeignet. Für die Einführung von zwei Kabeln mit einem Durchmesser von je 5,0 - 9,5mm befinden sich hinten an der Dose zwei leicht zu durchstechende Stellen. Die Modulaufnahme erreicht in gestecktem Zustand die Schutzklasse IP20, in geschlossenem Zustand die Schutzklasse IP44.',
      },
      DoseUPleer2xKS: {
        name: 'Modulaufnahme gerader Abgang, 2-fach',
        text: 'Die Modulaufnahme mit geradem Keystone-Abgang besteht aus einem Zinkdruckguss-Rahmen und eignet sich für den Einbau in einen Kabelkanal (EK), für Unterputz- (UP), oder mittels separatem Rahmen auch zur Aufputzmontage (AP).',
      },
      bodentank: 'Bodentank',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann Bodentanksysteme UT3, UT4',
        text: 'Die Trägerplatte ersetzt die Geräteträger und bietet somit ein größtmögliches Platzangebot in der Kabelzuführung. Die schräge Zu- und Abführung ermöglicht eine sichere Kabelführung auch bei sehr niedrigen Zwischenböden. Eine Kombination mit Einbauplatten für 4x preLink<sup>®</sup>/fixLink<sup>®</sup>-Module oder 1x H.D.S. ist vorgesehen. Die Einbauplatten werden mit zwei Schrauben an der Trägerplatte befestigt, nicht benötigte Felder der Trägerplatte können mit Blindplatten staubsicher abgedeckt werden.',
        datasheets: [
          {
            name: 'Trägerplatte für UT3 ohne Einbauplatte',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Trägerplatte für UT4 ohne Einbauplatte',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann Bodentankträger Basic UT3, UT4',
        text: 'Der Keystone-Bodentankträger ersetzt den Geräteträger und bietet somit ein größtmögliches Platzangebot in der Kabelzuführung. Die schräge Zu- und Abführung ermöglicht eine sichere Kabelführung auch bei sehr niedrigen Zwischenböden. Die Einbauplatten werden mit zwei Schrauben an der Trägerplatte befestigt, nicht benötigte Keystoneausbrüche des Bodentankträgers können mit Blindplatten staubsicher abgedeckt werden. Mittels eines Adapters kann der Bodentankträger auch für Electraplan-Geräteträger verwendet werden.',
        datasheets: [
          {
            name: 'Bodentankträger UT3 für bis zu 6 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Bodentankträger UT4 für bis zu 9 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Bodentankträger UT4 für bis zu 12 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK Bodentankträger Basic UT4',
        text: 'Der Keystone-Bodentankträger ersetzt den Geräteträger und bietet somit ein größtmögliches Platzangebot in der Kabelzuführung. Die schräge Zu- und Abführung ermöglicht eine sichere Kabelführung auch bei sehr niedrigen Zwischenböden. Die Einbauplatten werden mit zwei Schrauben an der Trägerplatte befestigt, nicht benötigte Keystoneausbrüche des Bodentankträgers können mit Blindplatten staubsicher abgedeckt werden. Die Bodentankträger sind kompatibel mit dem PUK-Bodentanksystem.',
        datasheets: [
          {
            name: 'Bodentankträger UT4 für bis zu 9 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Bodentankträger UT4 für bis zu 12 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann Einsätze für Bodentanksysteme UT3, UT4, Zubehör',
        text: 'Die Einsätze für Module sind aus Gründen der Erdung ungepulvert und feuerverzinkt. Die Einbauplatten werden mit zwei Schrauben an der Trägerplatte befestigt, nicht benötigte Felder der Trägerplatte können mit Blindplatten staubsicher abgedeckt werden.',
        datasheets: [
          {
            name: 'Einbauplatte für 4x RJ45 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Einbauplatte für 3x RJ45 Module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Hutschienenadapter',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktes Hutschienen-Modulgehäuse für 1 Modul',
        text: 'Hutschienen-Modulgehäuse für preLink<sup>®</sup>- / fixLink<sup>®</sup>-Module, geeignet für Verteilerschränke ohne Abdeckung für Sicherungsautomaten.',
        datasheets: [
          {
            name: 'Kompaktes Hutschienen-Modulgehäuse für 1 Modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone-F-Kupplung und Blindabdeckung Keystone-Leermodul',
        text: 'F-Kupplung und Blindabdeckung als Modulersatz\nDie F-Kupplung dient zur Integration von Koax-Kabeln in die preLink<sup>®</sup> / fixLink<sup>®</sup> Patchpanels und in das Dosenprogramm. Die preLink<sup>®</sup>/fixLink<sup>®</sup> Blindabdeckung kann sowohl im Verteilerfeld als auch in den Dosen zur Abdeckung unbenutzter Ports verwendet werden.\n\nLeermodul für SC-S bzw. LC-D als Modulersatz\nLeermodul für SC-S- bzw. LC-D-Kupplungen in Verteilerfeld oder Dose.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Hutschienen-Modulgehäuse für 1 Modul',
        text: 'Hutschienen-Modulgehäuse für preLink<sup>®</sup>- / fixLink<sup>®</sup>-Module, geeignet für Verteilerschränke mit Abdeckung für Sicherungsautomaten.',
        datasheets: [
          {
            name: 'Hutschienen-Modulgehäuse für 1 Modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE für 24 Keystone-Module gerader Abgang',
        text: 'Das Verteilerfeld 19" 1HE ist beschaltbar mit bis zu 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone-Modulen. Es ist in zwei Ebenen aufgebaut, somit sind die Module bündig in der Frontplatte. Das Verteilerfeld kann wahlweise in Edelstahl oder pulverbeschichtetem Eisenblech bestellt werden.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE für 48 Keystone-Module gerader Abgang',
        text: 'Das Verteilerfeld 19" 1HE ist beschaltbar mit bis zu 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone-Modulen. Es ist in zwei Ebenen aufgebaut, somit sind die Module bündig in der Frontplatte. Das Verteilerfeld besteht aus pulverbeschichtetem Eisenblech bestellt werden.\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE für 24 Keystone-Module gerader Abgang',
        text: 'Das preLink<sup>®</sup> / fixLink<sup>®</sup> Verteilerfeld 19" 0,5HE ist beschaltbar mit bis zu 24 fixLink<sup>®</sup> RJ45 Keystone-Modulen. Sie sind bündig in der Frontplatte.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE für 24 Keystone-Module inklusive Beschriftungsfenster',
        text: 'Das Verteilerfeld 19" 1HE ist beschaltbar mit bis zu 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone-Modulen. Es ist in zwei Ebenen aufgebaut, somit sind die Module bündig in der Frontplatte. Das Verteilerfeld kann wahlweise in Edelstahl oder pulverbeschichtetem Eisenblech bestellt werden. Zusätzlich besteht die Möglichkeit, die einzelnen Ports mit Hilfe eines beigelegten, 12x33 ausgestanzten Beschriftungsbogens, zu kennzeichnen.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Winkelpanel 30° 19" 1HE für 24 Keystone-Module',
        text: 'Beim preLink<sup>®</sup> / fixLink<sup>®</sup> Winkelpanel hat der Anwender die Möglichkeit, 24 Keystone-Module unter einem Winkel von 30° in das Panel einzurasten, dadurch laufen die angeschlossenen Patchkabel automatisch zur Schrankseite und müssen nur noch seitlich neben der Patchebene befestigt werden. Dabei kann der Anwender auf zusätzliche Rangierpanels zur Kabelführung verzichten. Im Betrieb benötigen die Winkelpanels mit der planen Frontseite und den schräg gestellten Patchkabeln zudem weniger Platz nach vorne als herkömmliche Patchpanels mit senkrecht eingebauten Ports.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      baugruppentraeger: 'Baugruppenträger',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Baugruppenträger 19" 3HE für den Einsatz von Einschubmodulen',
        text: 'Modulträger 19" 3HE für den Einsatz von 7TE-Einschubmodulen in Kupfer und Glasfaser. Sie werden über 2 Schrauben von der Panelseite am Modulträger fixiert. Die Einschubmodule lassen sich komplett aus dem Modulträger herausziehen. Es können maximal 12x 7TE-Einschubmodule im Modulträger befestigt werden.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Keystone-Einschubmodul für Modulträger',
        text: 'Keystone-Einschubmodul zur Bestückung von Modulträgern. Das Keystone-Einschubmodul kann mit bis zu 6 preLink<sup>®</sup>- bzw. fixLink<sup>®</sup>-Keystone-Modulen bestückt werden. Das Keystone- Einschubmodul ist in zwei Ebenen aufgebaut, somit sind die Module bündig zur Frontplatte. Die Kabelabfangung erfolgt direkt am Einschubmodul-Gehäuse. Es können 6 Einzelkabel oder 1 Trunkkabel befestigt werden.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Modulträger (BGT) 19" 1HE für den Einsatz von Einschubmodulen',
        text: 'Modulträger (BGT) für den Einsatz von 7TE-Einschubmodulen in Kupfer und LWL. Sie werden über 2 Schrauben von der Panelseite am Modulträger fixiert. Die Einschubmodule lassen sich komplett aus dem Modulträger herausziehen. Es können maximal 3x 7TE-Einschubmodule (=18 Ports) im Modulträger befestigt werden.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Side-Modulträger 3HE/14TE für den Einsatz von Einschubmodulen',
        text: 'Side-Modulträger (S-BGT) für den Einsatz von 7TE-Einschubmodulen in Kupfer und LWL. Die Einschubmodule werden über 2 Schrauben von der Panelseite am Modulträger fixiert. Die Einschubmodule lassen sich komplett aus dem Modulträger herausziehen. Es können maximal 2x 7TE-Einschubmodule (=12 Ports) im Modulträger befestigt werden. Die Befestigung des Side-Modulträgers (S-BGT) erfolgt an der seitlichen Schranklochung. Dadurch ist eine einfache und schnelle Montage möglich. Durch die seitliche Schrankmontage ist es möglich, eine platzsparende, besonders klimafreundliche Verkabelung zu realisieren, ohne den Luftstrom zu stören.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
      },
      consolidation_point_panel: 'Consolidations Point Panel, Hutschienen etc.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Unterflur-Consolidation Point Panel 19" bzw. 10" 1HE',
        text: 'Unterflur-Consolidation Point Panel mit austauschbaren Frontplatten zur Aufnahme unterschiedlichster Anschlüsse in Kupfer und LWL. Optional kann das Unterflur-Consolidation Point Panel mit einem Schloss vor unbefugtem Zugriff gesichert werden.\n\nDas Montagematerial und die Keystone-Module sind nicht im Lieferumfang enthalten.',
        datasheets: [
          {
            name: 'Consolidation Point Panel 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Consolidation Point Panel 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Aufbau-Consolidation Point Panel für 4-12 Keystone-Module',
        text: 'Aufbau-Consolidation Point Panel für preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystonemodule, zum Einsatz als Consolidation Panel, Direktmontage oder auch mittels Montageset auf Hutschienen in Elektrotechnik-Verteilungen einsetzbar. Das Aufbau-Consolidation Point Panel ist in zwei Ebenen aufgebaut. Somit sind die Module bündig in der Frontplatte.\n\nDas Montagematerial und die Keystonemodule sind nicht im Lieferumfang enthalten.',
      },
      trunkkabel: 'Trunkkabel',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC-Aufteilsystem Copper-Trunk-Bundled-Cable, Klasse EA ISO/IEC',
        text: 'Das CTBC-Aufteilsystem bietet sich für den flexiblen Einsatz in der strukturierten Gebäudeverkabelung und in Rechenzentren an. Die in einem Geflechtschlauch gebündelten Kabel sind werkseitig mit fixLink<sup>®</sup>-Keystone-Modulen konfektioniert und geprüft. Die Bündelung kann sowohl mit Verlegekabeln als auch mit flexiblen Kabeln erfolgen. Die maximale Länge des gebündelten Kabels beträgt bis zu 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC-Aufteilsystem Copper-Trunk-Compact-Cable, Klasse EA ISO/IEC',
        text: 'Das CTCC-Aufteilsystem bietet sich aufgrund seines minimalen Kabeldurchmessers und der dementsprechend geringen Brandlast für den flexiblen Einsatz in der strukturierten Gebäudeverkabelung und in Rechenzentren an. Die maximale Länge des Mehrfachkabels beträgt je nach Typ 60-90 m. Das Aufteilsystem als 10-Gbit-Lösung für RZ-Verkabelungen erfüllt mindestens die Anforderung der Klasse EA.',
      },
      hds_innengabel: 'H.D.S. Innenkabel',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. Innenkabel Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. Innenkabel Kat. 7 S/FTP zur einfachen Schrank-zu-Schrank-Verkabelung in Rechenzentren und Multiverbindungen von Verteilerräumen zu Unterverteilungen. Der Kabelaufbau besteht aus 24 paargeschirmten Kabelelementen, je 4 Paare sind zu einem Verseilelement zusammengefasst. Jedes Verseilelement ist mit einem Nummernband zur leichteren Identifikation versehen. Das flexible zentrale Zugentlastungselement schützt das Kabel beim Einziehen besonders gut. Der Mantel besteht aus einem flexiblen LSOH Material.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. Innenkabel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. Innenkabel Kat. 7 S/FTP zur einfachen Schrank-zu-Schrank-Verkabelung in Rechenzentren und Multiverbindungen von Verteilerräumen zu Unterverteilungen. Der Kabelaufbau besteht aus 24 paargeschirmten Kabelelementen, je 4 Paare sind zu einem Verseilelement zusammengefasst. Jedes Verseilelement ist mit einem Nummernband zur leichteren Identifikation versehen. Das flexible zentrale Zugentlastungselement schützt das Kabel beim Einziehen besonders gut. Der Mantel besteht aus einem flexiblen LSOH Material.',
      },
      generic: {
        download: 'Download',
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  footer: {
    dataProtection: 'Datenschutz',
    imprint: 'Impressum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: '--- bitte wählen ---',
    },
  },
}

export const langKey = 'DE'

const Default = {
  translation,
  langKey,
}

export default Default

export const translation = {
  header: {
    items: [
      {
        name: 'Ana Sayfa',
        href: '/',
      },
      {
        name: 'Sistem',
        href: '#system',
      },
      {
        name: 'Modül',
        href: '#module',
      },
      {
        name: 'Montaj',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Dil',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Basitlik',
        text2: 'Bağlantıda',
      },
      subtitle: 'Geleceğin Bağlantısı',
      cta: 'daha fazla',
    },
    handling: {
      title: 'İLERİ TEKNOLOJİ İLE KOLAY KULLANIM',
      vorreiterPrefix: 'Hassasiyet ve fonksiyonel tasarımın birleşimi, ',
      vorreiterSuffix: ' ağ teknolojisinde lider tercihi yapar.',
      experte: ' , tasarım ve performansı optimal bir şekilde birleştirerek karmaşıklığı en aza indiren verimli 360° çözümler sunar.',
      pionier: ' , iş alanlarını dönüştürmede öncü olup, uzmanlığı sezgisel kullanım ile birleştirir.',
    },
    download: {
      prefix: 'Genel Bakış ',
      suffix: ' - Sistem indir',
      cta: 'İndir',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Ürün Genel Bakışı',
      title: 'FIXLINK<sup>®</sup> - SİSTEM',
    },
    module: {
      overview: 'Genel bakışı indirin',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Sertifikalar',
          text: 'Ağ ürünlerimizin bağımsız kalite güvencesi için GHMT ve Premium Verification Programlarına güveniyoruz. Böylece, 20 yıllık uzmanlığa dayanarak, müşterilerimize en yüksek ürün standartlarını garanti ediyoruz.',
        },
        datasheets: {
          title: 'Veri Sayfaları',
          text: 'Burada tüm gerekli veri sayfalarını bulabilirsiniz. İndirin ve tüm bilgileri tek bakışta görün.',
        },
        compatibilitySheets: {
          title: 'Uyumluluk Listeleri',
          text: '',
        },
        excelTemplates: {
          title: 'Etiketleme Penceresi Excel Şablonu olarak',
          text: '',
        },
      },
    },
    video: {
      title: 'MONTAJ KILAVUZU',
      text: 'Videoyu izleyin veya PDF dosyasını buradan indirin',
      manual: {
        title: 'MONTAJ KILAVUZLARI:',
      },
    },
    separator: {
      title: 'Bağlantıdan Daha Fazlası',
      title2: 'EasyLan Kozmosunu Keşfedin',
      text: 'EasyLan.de’deki yenilikçi ağ çözümlerinin çeşitliliğini keşfedin: Size özel olarak tasarlanmış. Ağınız için Oyun Değiştirici: Kablolama sistemlerimiz ve kablo yönetim çözümlerimiz.',
      cta: 'daha fazla',
    },
    combination: {
      bau: {
        prefix: 'EasyLan’in ',
        text: ' modül taşıyıcıları ile maksimum uyum ve kaliteyi deneyimleyin',
        suffix: ' Avrupa’dan doğrudan.',
      },
      intelligent: {
        prefix: 'Ağınız',
        text: ' EasyLan’ın yapılandırılabilir fiber kutusu ile özel olarak tasarlanmış',
        suffix: '',
      },
      fixlink: {
        prefix: 'Ürünlerimizle',
        suffix: ' ofis binalarınızı uzun vadede iyileştirebilirsiniz.',
      },
      hds: {
        prefix: 'EasyLan<sup>®</sup> H.D.S. - Sistem ',
        text: 'kurulum ve bakım kolaylığını piyasadaki diğer trunk kablolama sistemleri gibi benzersiz bir şekilde birleştirir.',
        suffix: '',
      },
    },
    recommended: {
      titleLabel: 'Önerilen',
      title: 'fixLink<sup>®</sup> - SİSTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Ürün Garantisi',
        text: [
          'Ürün garantisi, bir ',
          ' - fiber optik veya bakır - kablolama sisteminin tüm bileşenlerini içerir ve ',
          ' ve EN50174 kurulum standartlarına göre nitelikli bir montajcı tarafından kurulum ve test edilmiştir.',
        ],
      },
      {
        title: 'Performans Garantisi',
        text: [
          'Performans garantisi, uygulamaya (Ethernet, Fiber Channel, ATM, vb.) ek olarak sistem özelliklerini ve diğerlerini (10MBit, 100MBit, 1GBit ve 10GBit hızlar) garantiler.',
        ],
      },
      {
        title: 'Uygulama Garantisi',
        text: [
          'Uygulama garantisi, kurulum anındaki ISO/IEC 11801, EN50173 ve EIA/TIA 568B kablolama standartlarına dayanan tüm belirtilen uygulamaları (Ethernet, Fiber Channel, ATM, vb.) garantiler.',
        ],
      },
    ],
    end: {
      title: 'BAĞLANTILAR HİÇ BU KADAR KOLAY OLMAMIŞTI.',
      text: 'İleri teknoloji ile sezgisel kullanım; geleceğin kusursuz bağlantısını keşfedin: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Bağlantı Modülleri',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone Modülü Kat. 6A ISO/IEC, ekranlı',
        text: 'fixLink<sup>®</sup> Pro RJ45 Keystone Modülü ile kullanıcılar en yüksek kalitede bir RJ45 soket modülü elde eder. fixLink<sup>®</sup> Pro RJ45 Keystone Modülü, yan kesici ile bir dakikadan kısa sürede monte edilebilir. Serbestçe erişilebilir kontakt bölgesi sayesinde mükemmel montaj kalitesi ve bu sayede güvenli bir iletim performansı sağlanır. Özel kontakt geometrisi ve 30 μ" altın kaplama ile 4PPoE IEEE 802.3bt’ye göre her durumda güvence altına alınır. Muhafaza malzemesi nikel kaplı çinko döküm olup en yüksek çevresel ve mekanik gereksinimleri karşılar. Tasarıma uygun modül yuvası ile tüm anahtar programlarıyla kombinasyon mümkündür. Çevre dostu karton ambalajlarda teslim edilir.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Kalıcı Bağlantı Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Kat. 6A ISO/IEC, ekranlı Uzatıcı',
        text: 'fixLink<sup>®</sup> Uzatıcı ile kablolar kolaylıkla uzatılabilir. Ayrıca, devre dışı bırakılmış kabloları yeniden etkinleştirip, bir sonraki ihtiyaç duyulan bağlantıya kadar uzatmak mümkündür. Kanal içindeki dar alanı en iyi şekilde kullanır. Uzatıcı, kurulumun sonunda kanalın içine veya duvara vidalanır. Kurulum tamamlandıktan sonra, uzatılan kablo maksimum bağlantı uzunluğuna kadar 10-Gigabit Ethernet iletimleri için kullanılabilir ve PoE+ (Ethernet üzerinden güç) destekler.',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC ekranlı',
        text: 'fixLink<sup>®</sup> RJ45 Plug Modülü, kullanıcıya en yüksek kalite ve esneklik sunan bir alan montajlı RJ45 Plug Modülüdür. Özel kontakt geometrisi ve 50 μ" altın kaplama ile 4PPoE IEEE 802.3bt’ye göre her durumda güvence altına alınır. Muhafaza malzemesi nikel kaplı çinko döküm olup en yüksek çevresel ve mekanik gereksinimleri karşılar. Bağımsız test laboratuvarlarının çeşitli sertifikaları (güncel liste: www.easylan.de) fixLink<sup>®</sup> sisteminin en yüksek kalite standartlarını tamamlar.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone Modülü Kat. 6A EIA/TIA, ekransız',
        text: 'fixLink<sup>®</sup> RJ45 Keystone Modülü (PoE+ uyumlu) ile kullanıcılar en yüksek kalitede bir RJ45 soket modülü elde eder. fixLink<sup>®</sup> RJ45 Keystone Modülü, yan kesici ile bir dakikadan kısa sürede monte edilebilir. Serbestçe erişilebilir kontakt bölgesi sayesinde mükemmel montaj kalitesi ve bu sayede güvenli bir iletim performansı sağlanır. Muhafaza malzemesi polikarbonattan yapılmış olup en yüksek çevresel ve mekanik gereksinimleri karşılar. Tasarıma uygun modül yuvası ile tüm anahtar programlarıyla kombinasyon mümkündür. Bağımsız test laboratuvarlarının çeşitli sertifikaları fixLink<sup>®</sup> SL Keystone Modülünün en yüksek kalite standartlarını tamamlar. Çevre dostu karton ambalajlarda teslim edilir.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone Modülü Kat. 6A ISO/IEC, ekranlı',
        text: 'fixLink<sup>®</sup> SL RJ45 Keystone Modülü ile kullanıcılar en yüksek kalitede bir RJ45 soket modülü elde eder. fixLink<sup>®</sup> SL RJ45 Keystone Modülü, yan kesici ile bir dakikadan kısa sürede monte edilebilir. Serbestçe erişilebilir kontakt bölgesi sayesinde mükemmel montaj kalitesi ve bu sayede güvenli bir iletim performansı sağlanır. 360° ekran çıkarma, sadece entegre kamanın kilitlenmesiyle sağlanır, zaman alıcı bir kablo bağlama işlemi gerekmez. Özel kontakt geometrisi ve 50 μ" altın kaplama ile 4PPoE IEEE 802.3bt’ye göre her durumda güvence altına alınır. Muhafaza malzemesi nikel kaplı çinko döküm olup en yüksek çevresel ve mekanik gereksinimleri karşılar. Tasarıma uygun modül yuvası ile tüm anahtar programlarıyla kombinasyon mümkündür. Bağımsız test laboratuvarlarının çeşitli sertifikaları fixLink<sup>®</sup> SL Keystone Modülünün en yüksek kalite standartlarını tamamlar. Çevre dostu karton ambalajlarda teslim edilir.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'DIN EN ISO/IEC 17025’e göre 4PoE test raporu',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Kalıcı Bağlantı Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone Modülü açılı Kat. 6A ISO/IEC, ekranlı',
        text: 'fixLink<sup>®</sup> SL RJ45 Keystone Modülü ile kullanıcılar en yüksek kalitede, özellikle dar alan gereksinimleri için geliştirilmiş bir RJ45 soket modülü elde eder. 75° kablo çıkışı sayesinde modül, özellikle veri prizleri ve iç boşluk kablolamaları için uygundur. 27 mm minimum montaj derinliği neredeyse sınırsız uygulama çeşitliliği sağlar. fixLink<sup>®</sup> SL RJ45 Keystone Modülü, yan kesici ile bir dakikadan kısa sürede monte edilebilir. Serbestçe erişilebilir kontakt bölgesi sayesinde mükemmel montaj kalitesi ve bu sayede güvenli bir iletim performansı sağlanır. Özel kontakt geometrisi ve 50 μ" altın kaplama ile PoE+ IEEE 80.3at’ye göre her durumda güvence altına alınır. Muhafaza malzemesi nikel kaplı çinko döküm olup en yüksek çevresel ve mekanik gereksinimleri karşılar. Tasarıma uygun modül yuvası ile tüm anahtar programlarıyla kombinasyon mümkündür. Bağımsız test laboratuvarlarının çeşitli sertifikaları fixLink<sup>®</sup> SL Keystone Modülünün en yüksek kalite standartlarını tamamlar. Çevre dostu karton ambalajlarda teslim edilir.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Kalıcı Bağlantı Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Aletler ve aksesuarlar',
        text: [
          'Veri kablosunun dış kılıfını ve metal folyosunu çıkarmak için kılıf soyma aracı. Mavi renk.',
          'Ekranı sabitlemek için bakır bant. 35 x 9 mm olarak önceden kesilmiş. Ambalaj birimi: 20 adet/torba 1500 adet/rulo',
          'PreLink® SL ve fixLink® SL Keystone modülleri için renkli toz koruma kapakları.',
          'Ambalaj birimi 50 adet/polietilen torba',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Kurulum kablosu',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'EasyLan® Gold S/FTP AWG22/1 1500 MHz Cat. 7A Veri Kablosu',
        text: [
          'Birincil, ikincil ve üçüncül alanlarda kablolamada analog ve dijital sinyallerin iletimi için veri kablosu. FA sınıfına kadar uygulamalar için uygundur.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'GHMT Permanent Link Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Teknik Veri Sayfası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'EasyLan® Silber S/FTP AWG23/1 1200 MHz Cat. 7A Veri Kablosu',
        text: [
          'Birincil, ikincil ve üçüncül alanlarda kablolamada analog ve dijital sinyallerin iletimi için veri kablosu. FA sınıfına kadar uygulamalar için uygundur.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP Sertifikası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Teknik Veri Sayfası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'EasyLan® Bronze S/FTP AWG23/1 1000 MHz Cat. 7 Veri Kablosu',
        text: 'Birincil, ikincil ve üçüncül alanlarda kablolamada analog ve dijital sinyallerin iletimi için veri kablosu. FA sınıfına kadar uygulamalar için uygundur.',
        datasheets: [
          {
            name: 'Teknik Veri Sayfası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Veri Prizleri',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Önceden belirtilen modül yuvaları için AP muhafazası ve çerçeve',
        text: [
          'EasyLan modül yuvalarının montajı için yüzeye montaj muhafazası IP20, 80x80x42mm',
          'Yüzeye montaj muhafazası için derinlik genişletici, 10mm genişleme',
          '1’li çerçeve 80x80mm',
          '2’li çerçeve 80x150mm',
        ],
        datasheets: [
          {
            name: 'Yüzeye montaj muhafazası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Yüzeye montaj muhafazası derinlik genişletici',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'SL modül yuvası, 45° çıkış UP, BK, AP, BT 1’li, 2’li, 3’lü',
        text: 'SL modül yuvaları, kablo kanalı (EK) montajı, alçıpan montajı (UP) ve yüzeye montaj (AP) için özellikle uygundur. İki montaj flanşının kırılmasıyla zemin kutusu montajı (BT) da mümkündür. SL modül yuvası, bir ila üç EasyLan Keystone modülünü güvenle ve kalıcı olarak barındırabilir. Kolay etiketleme için büyük, merkezi bir etiketleme penceresi bulunur. Merkezi plaka (50x50 mm) ve çerçeve (80x80 mm) UV dayanıklı ve son derece kırılmaz plastikten yapılmıştır. Bu sayede uzun ömürlü ve renkli bir görünüm sağlanır.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Tasarım uyumlu SL modül yuvası, 45° çıkış UP, BK, AP, BT 1’li, 2’li',
        text: 'Tasarım uyumlu modül yuvası, bir çinko döküm çerçeveden oluşur. Kablo kanalı (EK) montajı, alçıpan montajı (UP) veya ayrı bir çerçeve ile yüzeye montaj (AP) için uygundur. İki montaj flanşının kırılmasıyla zemin kutusu montajı (BT) da mümkündür. Tasarım uyumlu veri prizi, piyasadaki hemen hemen tüm anahtar programlarıyla kombinasyon için uygundur. UAE uyumluluğu nedeniyle tasarım uyumlu veri prizinde toz koruma kapağı için yer yoktur.',
        datasheets: [
          {
            name: '1’li/2’li Keystone, merkezi plaka olmadan',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2’li Keystone, merkezi plaka 50x50mm ile',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1’li merkezi plaka ile',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Yüzeye montaj IP44 modül yuvası, 1’li, 2’li',
        text: 'Dayanıklı yüzeye montaj muhafazası, iki preLink<sup>®</sup>/fixLink<sup>®</sup> Keystone modülünün montajı için bir adaptör eki kullanılarak uygundur. Her biri 5,0 - 9,5 mm çapında iki kablonun girişine uygun olarak muhafazanın arkasında iki kolayca delinir alan bulunur. Modül yuvası, takılı durumda IP20 koruma sınıfını, kapalı durumda ise IP44 koruma sınıfını sağlar.',
      },
      DoseUPleer2xKS: {
        name: 'Doğrudan çıkış modül yuvası, 2’li',
        text: 'Doğrudan Keystone çıkışlı modül yuvası, bir çinko döküm çerçeveden oluşur ve kablo kanalı (EK) montajı, alçıpan (UP) montajı veya ayrı bir çerçeve ile yüzeye montaj (AP) için uygundur.',
      },
      bodentank: 'Zemin Kutusu',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann Zemin Kutusu Sistemleri UT3, UT4',
        text: 'Taşıyıcı plaka cihaz taşıyıcılarını değiştirir ve kablo girişinde mümkün olan en geniş alanı sağlar. Eğik giriş ve çıkış, çok düşük ara katmanlarda bile güvenli bir kablo yönetimi sağlar. 4x preLink<sup>®</sup>/fixLink<sup>®</sup> modül veya 1x H.D.S. için montaj plakaları ile kombinasyon mümkündür. Montaj plakaları, taşıyıcı plakaya iki vida ile sabitlenir, kullanılmayan alanlar kör plakalar ile toz geçirmez bir şekilde kapatılabilir.',
        datasheets: [
          {
            name: 'Montaj plakası UT3 için modül plakası olmadan',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Montaj plakası UT4 için modül plakası olmadan',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann Zemin Kutusu Taşıyıcı Basic UT3, UT4',
        text: 'Keystone zemin kutusu taşıyıcı, cihaz taşıyıcıyı değiştirir ve kablo girişinde mümkün olan en geniş alanı sağlar. Eğik giriş ve çıkış, çok düşük ara katmanlarda bile güvenli bir kablo yönetimi sağlar. Montaj plakaları, taşıyıcı plakaya iki vida ile sabitlenir, kullanılmayan Keystone çıkışları kör plakalar ile toz geçirmez bir şekilde kapatılabilir. Bir adaptör kullanılarak, zemin kutusu taşıyıcı Electraplan cihaz taşıyıcıları için de kullanılabilir.',
        datasheets: [
          {
            name: 'UT3 için zemin kutusu taşıyıcı, 6 modüle kadar',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'UT4 için zemin kutusu taşıyıcı, 9 modüle kadar',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'UT4 için zemin kutusu taşıyıcı, 12 modüle kadar',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK Zemin Kutusu Taşıyıcı Basic UT4',
        text: 'Keystone zemin kutusu taşıyıcı, cihaz taşıyıcıyı değiştirir ve kablo girişinde mümkün olan en geniş alanı sağlar. Eğik giriş ve çıkış, çok düşük ara katmanlarda bile güvenli bir kablo yönetimi sağlar. Montaj plakaları, taşıyıcı plakaya iki vida ile sabitlenir, kullanılmayan Keystone çıkışları kör plakalar ile toz geçirmez bir şekilde kapatılabilir. Zemin kutusu taşıyıcıları, PUK zemin kutusu sistemi ile uyumludur.',
        datasheets: [
          {
            name: 'UT4 için zemin kutusu taşıyıcı, 9 modüle kadar',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'UT4 için zemin kutusu taşıyıcı, 12 modüle kadar',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann Modül Ekleri Zemin Kutusu Sistemleri UT3, UT4, Aksesuar',
        text: 'Modüller için ekler, topraklama nedenleriyle kaplanmamış ve sıcak daldırma galvanizlidir. Montaj plakaları, taşıyıcı plakaya iki vida ile sabitlenir, kullanılmayan alanlar kör plakalar ile toz geçirmez bir şekilde kapatılabilir.',
        datasheets: [
          {
            name: '4x RJ45 Modül için Montaj Plakası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: '3x RJ45 Modül için Montaj Plakası',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'DIN Ray Adaptörü',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompakt DIN Ray Modül Muhafazası, 1 Modül için',
        text: 'preLink<sup>®</sup>/fixLink<sup>®</sup> modülleri için DIN ray modül muhafazası, sigorta otomatları için kapaksız dağıtım panolarına uygun.',
        datasheets: [
          {
            name: 'Kompakt DIN Ray Modül Muhafazası, 1 Modül için',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone-F-Kuplaj ve Kör Modül Keystone-Boş Modül',
        text: 'Koaksiyel kabloları preLink<sup>®</sup>/fixLink<sup>®</sup> patch panellerine ve priz programına entegre etmek için F-kuplaj kullanılır. preLink<sup>®</sup>/fixLink<sup>®</sup> kör kapak, hem dağıtım panellerinde hem de prizlerde kullanılmayan portları kapatmak için kullanılabilir.\n\nSC-S veya LC-D için Boş Modül Modül yedek parçası olarak\nSC-S veya LC-D kuplajlarını dağıtım panelleri veya prizlere monte etmek için boş modül.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'DIN Ray Modül Muhafazası, 1 Modül için',
        text: 'preLink<sup>®</sup>/fixLink<sup>®</sup> modülleri için DIN ray modül muhafazası, sigorta otomatları için kapaklı dağıtım panolarına uygun.',
        datasheets: [
          {
            name: 'DIN Ray Modül Muhafazası, 1 Modül için',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE, 24 Keystone Modülü için Doğrudan Çıkış',
        text: 'Dağıtım paneli 19" 1HE, 24 preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone modülü ile donatılabilir. İki katmanlı yapıdadır, böylece modüller ön plaka ile hizalıdır. Dağıtım paneli paslanmaz çelik veya toz kaplamalı demir sac olarak sipariş edilebilir.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE, 48 Keystone Modülü için Doğrudan Çıkış',
        text: 'Dağıtım paneli 19" 1HE, 48 fixLink<sup>®</sup> Pro/fixLink<sup>®</sup> Pro+ RJ45 Keystone modülü ile donatılabilir. İki katmanlı yapıdadır, böylece modüller ön plaka ile hizalıdır. Dağıtım paneli toz kaplamalı demir sacdan yapılmıştır.\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE, 24 Keystone Modülü için Doğrudan Çıkış',
        text: 'preLink<sup>®</sup>/fixLink<sup>®</sup> dağıtım paneli 19" 0,5HE, 24 fixLink<sup>®</sup> RJ45 Keystone modülü ile donatılabilir. Modüller ön plaka ile hizalıdır.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE, 24 Keystone Modülü için Etiketleme Penceresi Dahil',
        text: 'Dağıtım paneli 19" 1HE, 24 preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone modülü ile donatılabilir. İki katmanlı yapıdadır, böylece modüller ön plaka ile hizalıdır. Dağıtım paneli paslanmaz çelik veya toz kaplamalı demir sac olarak sipariş edilebilir. Ek olarak, her bir portun 12x33 ölçülerinde kesilmiş bir etiketleme kağıdı ile kolayca etiketlenmesi mümkündür.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Açılı Panel 30° 19" 1HE, 24 Keystone Modülü için',
        text: 'preLink<sup>®</sup>/fixLink<sup>®</sup> açılı panel ile kullanıcı, 24 Keystone modülünü panele 30° açıyla yerleştirebilir, böylece bağlı patch kabloları otomatik olarak kabin yanına yönlendirilir ve sadece patch katmanının yanına sabitlenir. Bu sayede kullanıcı, kablo yönlendirme için ek patch panellerine ihtiyaç duymaz. İşletimde açılı paneller, düz ön yüzü ve açılı patch kabloları ile geleneksel dikey portlu patch panellere göre daha az yer kaplar.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      baugruppentraeger: 'Modül Taşıyıcılar',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Modül Taşıyıcı 19" 3HE, Yerleştirilebilir Modüller için',
        text: 'Yerleştirilebilir modüller için 19" 3HE modül taşıyıcı. 7TE yerleştirilebilir modüller bakır ve fiber optik için kullanılabilir. Modül taşıyıcı, iki vida ile panonun yan tarafından sabitlenir. Yerleştirilebilir modüller tamamen modül taşıyıcıdan çıkarılabilir. Modül taşıyıcıda en fazla 12x 7TE yerleştirilebilir modül sabitlenebilir.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Modül Taşıyıcı için Keystone Yerleştirilebilir Modül',
        text: 'Modül taşıyıcılarının donatılması için Keystone yerleştirilebilir modül. Keystone yerleştirilebilir modül, en fazla 6 preLink<sup>®</sup>/fixLink<sup>®</sup> Keystone modülü ile donatılabilir. Keystone yerleştirilebilir modül, iki katmanlı yapıdadır, böylece modüller ön plaka ile hizalıdır. Kablo tutucu doğrudan yerleştirilebilir modül muhafazasına sabitlenir. 6 bireysel kablo veya 1 trunk kablo sabitlenebilir.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Modül Taşıyıcı (BGT) 19" 1HE, Yerleştirilebilir Modüller için',
        text: 'Bakır ve fiber optik için 7TE yerleştirilebilir modüller için modül taşıyıcı (BGT). Modül taşıyıcı, iki vida ile panonun yan tarafından sabitlenir. Yerleştirilebilir modüller tamamen modül taşıyıcıdan çıkarılabilir. Modül taşıyıcıda en fazla 3x 7TE yerleştirilebilir modül (=18 port) sabitlenebilir.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Yan Modül Taşıyıcı 3HE/14TE, Yerleştirilebilir Modüller için',
        text: 'Bakır ve fiber optik için 7TE yerleştirilebilir modüller için yan modül taşıyıcı (S-BGT). Modül taşıyıcı, iki vida ile panonun yan tarafından sabitlenir. Yerleştirilebilir modüller tamamen modül taşıyıcıdan çıkarılabilir. Modül taşıyıcıda en fazla 2x 7TE yerleştirilebilir modül (=12 port) sabitlenebilir. Yan modül taşıyıcının (S-BGT) sabitlenmesi, yan kabin deliklerine yapılır. Bu sayede basit ve hızlı montaj mümkündür. Yan kabin montajı sayesinde, hava akışını engellemeden, yerden tasarruf sağlayan ve özellikle iklim dostu bir kablolama gerçekleştirilebilir.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      consolidation_point_panel: 'Konsolidasyon Noktası Paneli, DIN rayları vb.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Zemin Altı Konsolidasyon Noktası Paneli 19" veya 10" 1HE',
        text: 'Zemin altı konsolidasyon noktası paneli, bakır ve fiber optik bağlantılar için değiştirilebilir ön plakalar ile. İsteğe bağlı olarak, zemin altı konsolidasyon noktası paneli, yetkisiz erişime karşı bir kilitle güvence altına alınabilir.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
        datasheets: [
          {
            name: 'Konsolidasyon Noktası Paneli 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Konsolidasyon Noktası Paneli 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Üst Yapı Konsolidasyon Noktası Paneli, 4-12 Keystone Modül için',
        text: 'preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone modülleri için üst yapı konsolidasyon noktası paneli, konsolidasyon paneli, doğrudan montaj veya bir montaj seti kullanılarak DIN raylarında elektrik dağıtım panolarında kullanılabilir. Üst yapı konsolidasyon noktası paneli iki katmanlı yapıdadır. Modüller ön plaka ile hizalıdır.\n\nMontaj malzemesi ve Keystone modüller teslimat kapsamına dahil değildir.',
      },
      trunkkabel: 'Trunk Kablolar',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC-Dağıtım Sistemi Bakır Trunk Demet Kablo, Sınıf EA ISO/IEC',
        text: 'CTBC dağıtım sistemi, yapılandırılmış bina kablolaması ve veri merkezlerinde esnek kullanım için uygundur. Örgülü bir kılıf içinde gruplanmış kablolar, fabrika çıkışlı fixLink<sup>®</sup> Keystone modülleri ile donatılmış ve test edilmiştir. Gruplama, hem döşeme kabloları hem de esnek kablolar ile yapılabilir. Gruplanmış kablonun maksimum uzunluğu 20 m’ye kadar olabilir.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC-Dağıtım Sistemi Bakır Trunk Kompakt Kablo, Sınıf EA ISO/IEC',
        text: 'CTCC dağıtım sistemi, minimal kablo çapı ve buna bağlı olarak düşük yanma yükü nedeniyle yapılandırılmış bina kablolaması ve veri merkezlerinde esnek kullanım için uygundur. Çoklu kablonun maksimum uzunluğu, tipe bağlı olarak 60-90 m arasında değişir. RZ kablolamaları için 10-Gbit çözüm olarak dağıtım sistemi, en az sınıf EA gereksinimlerini karşılar.',
      },
      hds_innengabel: 'H.D.S. İç Kablo',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. İç Kablo Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. iç kablo Kat. 7 S/FTP, veri merkezlerinde dolaptan dolaba kablolama ve dağıtım odalarından alt dağıtımlara çoklu bağlantılar için uygundur. Kablo yapısı, 24 çift korumalı kablo elemanından oluşur, her 4 çift bir araya getirilmiş bir kablo elemanı oluşturur. Her kablo elemanı, daha kolay tanımlama için bir numaralandırma bandı ile donatılmıştır. Esnek merkezi çekme elemanı, kablonun çekilmesi sırasında özel bir koruma sağlar. Kılıf, esnek bir LSOH malzemeden yapılmıştır.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. İç Kablo Kat. 7, 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. iç kablo Kat. 7 S/FTP, veri merkezlerinde dolaptan dolaba kablolama ve dağıtım odalarından alt dağıtımlara çoklu bağlantılar için uygundur. Kablo yapısı, 24 çift korumalı kablo elemanından oluşur, her 4 çift bir araya getirilmiş bir kablo elemanı oluşturur. Her kablo elemanı, daha kolay tanımlama için bir numaralandırma bandı ile donatılmıştır. Esnek merkezi çekme elemanı, kablonun çekilmesi sırasında özel bir koruma sağlar. Kılıf, esnek bir LSOH malzemeden yapılmıştır.',
      },
      generic: {
        download: 'İndir',
      },
    },
  },

  imprint: {
    headline: 'İzlenimler',
  },

  dataProtection: {
    headline: 'Veri Koruma',
  },

  footer: {
    dataProtection: 'Veri Koruma',
    imprint: 'İzlenimler',
    contact: 'İletişim',
  },

  UI: {
    autocomplete: {
      searchError: 'Arama gerçekleştirilemedi.',
      searchEmpty: 'Sonuç bulunamadı',
    },
    dropdown: {
      emptySelection: '--- lütfen seçin ---',
    },
  },
}

export const langKey = 'TR'

const Default = {
  translation,
  langKey,
}

export default Default

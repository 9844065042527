import styled from 'styled-components'

interface ICoreContent {
  hideHeader?: boolean
}

export const CoreContent = styled.div<ICoreContent>`
  position: relative;
  background-color: ${({ theme }) => theme.color.background.primary};
  min-height: 100vh;
  padding-top: ${({ hideHeader, theme }) => (hideHeader ? '0px' : theme.headerHeight)};
`

// module
import module from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul gewinkelt Kat. 6A ISO:IEC, geschirmt/SL RJ45 Keystone-Modul.png'

import csm_fixLink_Extender_01_c1c158832c from '../../assets/image/easyLan/system/01_Module/Extender Kat. 6A ISO:IEC, geschirmt/csm_fixLink_Extender_01_c1c158832c.png'
import csm_fixLink_Extender_a0613ee77a from '../../assets/image/easyLan/system/01_Module/Extender Kat. 6A ISO:IEC, geschirmt/csm_fixLink_Extender_a0613ee77a.png'

import csm_FixLink_Pro_f04d507fcc from '../../assets/image/easyLan/system/01_Module/Pro RJ45 Keystone-Modul Kat. 6A ISO:IEC, geschirmt/csm_FixLink_Pro_f04d507fcc.png'
import csm_Fixlink_Pro02_521c4e51f3 from '../../assets/image/easyLan/system/01_Module/Pro RJ45 Keystone-Modul Kat. 6A ISO:IEC, geschirmt/csm_Fixlink_Pro02_521c4e51f3.png'
import csm_FixLink_Pro03_b20813b518 from '../../assets/image/easyLan/system/01_Module/Pro RJ45 Keystone-Modul Kat. 6A ISO:IEC, geschirmt/csm_FixLink_Pro03_b20813b518.png'

import csm_Fixlink_UTP_01_e6c47d48a1 from '../../assets/image/easyLan/system/01_Module/RJ45 Keystone-Modul Kat. 6A EIA:TIA, ungeschirmt/csm_Fixlink-UTP_01_e6c47d48a1.png'
import csm_Fixlink_UTP_02_128ed096a8 from '../../assets/image/easyLan/system/01_Module/RJ45 Keystone-Modul Kat. 6A EIA:TIA, ungeschirmt/csm_Fixlink-UTP_02_128ed096a8.png'
import csm_Fixlink_UTP_e5de94d502 from '../../assets/image/easyLan/system/01_Module/RJ45 Keystone-Modul Kat. 6A EIA:TIA, ungeschirmt/csm_Fixlink-UTP_e5de94d502.png'

import csm_fixLink_RJ45_Plug_Katalog_01_60bf7c14ca from '../../assets/image/easyLan/system/01_Module/RJ45 Plug Kat. 6A ISO:IEC geschirmt/csm_fixLink-RJ45-Plug_Katalog_01_60bf7c14ca.png'
import csm_fixLink_RJ45_Plug_Katalog_02_cf5f3dd3c4 from '../../assets/image/easyLan/system/01_Module/RJ45 Plug Kat. 6A ISO:IEC geschirmt/csm_fixLink-RJ45-Plug_Katalog_02_cf5f3dd3c4.png'
import csm_fixLink_RJ45_Plug_Katalog_c858f0a6ed from '../../assets/image/easyLan/system/01_Module/RJ45 Plug Kat. 6A ISO:IEC geschirmt/csm_fixLink-RJ45-Plug_Katalog_c858f0a6ed.png'

import csm_fixLink_SL_gewinkelt_01_e1e50d5add from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul gewinkelt Kat. 6A ISO:IEC, geschirmt/csm_fixLink-SL_gewinkelt_01_e1e50d5add.png'
import csm_fixLink_SL_gewinkelt_02_34c439abcd from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul gewinkelt Kat. 6A ISO:IEC, geschirmt/csm_fixLink-SL_gewinkelt_02_34c439abcd.png'
import fixLink_SL_gewinkelt from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul gewinkelt Kat. 6A ISO:IEC, geschirmt/fixLink-SL gewinkelt.png'

import fixLink_SL_01 from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul Kat. 6A ISO:IEC, geschirmt/fixLink_SL_01.png'
import fixLink_SL_02 from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul Kat. 6A ISO:IEC, geschirmt/fixLink_SL_02.png'
import fixLink_SL from '../../assets/image/easyLan/system/01_Module/SL RJ45 Keystone-Modul Kat. 6A ISO:IEC, geschirmt/fixLink_SL.png'

import Abmantelwerkzeug from '../../assets/image/easyLan/system/01_Module/werkzeugeUndZubehoer/Abmantelwerkzeug.png'
import csm_KupferbandRolle_4092658142 from '../../assets/image/easyLan/system/01_Module/werkzeugeUndZubehoer/csm_Kupferband-Rolle_4092658142.png'
import csm_Staubschutzklappen_65cb5db79c from '../../assets/image/easyLan/system/01_Module/werkzeugeUndZubehoer/csm_Staubschutzklappen_65cb5db79c.png'
import KupferbandStreife from '../../assets/image/easyLan/system/01_Module/werkzeugeUndZubehoer/Kupferband-Streifen.png'

// verlegekabel
import verlegekabel from '../../assets/image/easyLan/system/Verlegekabel/Kabel.png'

import goldCsm_Datenkabel_SFTP_orange_074bad6e0f from '../../assets/image/easyLan/system/Verlegekabel/Gold/csm_Datenkabel_S-FTP_orange_074bad6e0f.png'
import goldCsm_Querschnitt_SFTP__RAL_2003_d6e62072fc from '../../assets/image/easyLan/system/Verlegekabel/Gold/csm_Querschnitt_S-FTP_-_RAL_2003_d6e62072fc.png'
import goldCsm_Querschnitt_Duplex_SFTP__RAL_2003_1fca4a638b from '../../assets/image/easyLan/system/Verlegekabel/Gold/csm_Querschnitt_Duplex_S-FTP_-_RAL_2003_1fca4a638b.png'

import silberCsm_Datenkabel_SFTP_orange_074bad6e0f from '../../assets/image/easyLan/system/Verlegekabel/Silber/csm_Datenkabel_S-FTP_orange_074bad6e0f.png'
import silberCsm_Querschnitt_SFTP__RAL_2003_d6e62072fc from '../../assets/image/easyLan/system/Verlegekabel/Silber/csm_Querschnitt_S-FTP_-_RAL_2003_d6e62072fc.png'
import silberCsm_Querschnitt_Duplex_SFTP__RAL_2003_1fca4a638b from '../../assets/image/easyLan/system/Verlegekabel/Silber/csm_Querschnitt_Duplex_S-FTP_-_RAL_2003_1fca4a638b.png'

import bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f from '../../assets/image/easyLan/system/Verlegekabel/Bronze/csm_Datenkabel_S-FTP_orange_074bad6e0f.png'
import bronzeCsm_Querschnitt_SFTP__RAL_2003_d6e62072fc from '../../assets/image/easyLan/system/Verlegekabel/Bronze/csm_Querschnitt_S-FTP_-_RAL_2003_d6e62072fc.png'
import bronzeCsm_Querschnitt_Duplex_SFTP__RAL_2003_1fca4a638b from '../../assets/image/easyLan/system/Verlegekabel/Bronze/csm_Querschnitt_Duplex_S-FTP_-_RAL_2003_1fca4a638b.png'

// datendosen
import datendosen from '../../assets/image/easyLan/system/02_Datendosen/Designfähige Modulaufnahme SL, 45-Abgang UP, BK, AP, BT 1-fach, 2-fach/csm_Datendose_designfähig_2-fach_cca354fc62.png'

import csm_Abdeckrahmen_1_fach_d4075872d1 from '../../assets/image/easyLan/system/02_Datendosen/AP-Gehäuse und Rahmen für vorgenannte Modulaufnahmen/csm_Abdeckrahmen_1-fach_d4075872d1.png'
import csm_Abdeckrahmen_2_fach_405fce48c1 from '../../assets/image/easyLan/system/02_Datendosen/AP-Gehäuse und Rahmen für vorgenannte Modulaufnahmen/csm_Abdeckrahmen_2-fach_405fce48c1.png'
import csm_Aufputzgehäuse_3748cacb5d from '../../assets/image/easyLan/system/02_Datendosen/AP-Gehäuse und Rahmen für vorgenannte Modulaufnahmen/csm_Aufputzgehäuse_3748cacb5d.png'

import csm_Datendose_designfähig_1_fach_f35757e7bc from '../../assets/image/easyLan/system/02_Datendosen/Designfähige Modulaufnahme SL, 45-Abgang UP, BK, AP, BT 1-fach, 2-fach/csm_Datendose_designfähig_1-fach_f35757e7bc.png'
import csm_Datendose_designfähig_2_fach_cca354fc62 from '../../assets/image/easyLan/system/02_Datendosen/Designfähige Modulaufnahme SL, 45-Abgang UP, BK, AP, BT 1-fach, 2-fach/csm_Datendose_designfähig_2-fach_cca354fc62.png'

import Dose_UP_leer_2x_KS from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme gerader Abgang, 2-fach/Dose UP leer 2x KS.png'

import csm_IP44_AP_Dose_geschlossen__2e4d774a1f from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme IP44 Aufputz, 1-fach, 2-fach/csm_IP44_AP_Dose_geschlossen__2e4d774a1f.png'
import csm_IP44_AP_Dose_offen_2e48717c62 from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme IP44 Aufputz, 1-fach, 2-fach/csm_IP44_AP_Dose_offen_2e48717c62.png'
import csm_IP44_AP_Dose_offen_Rückseite__dfe336f19a from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme IP44 Aufputz, 1-fach, 2-fach/csm_IP44_AP_Dose_offen_Rückseite__dfe336f19a.png'

import csm_Datendose_1Port_mit_Rahmen_a8e4d5e731 from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme SL, 45-Abgang UP, BK, AP, BT 1-fach, 2-fach, 3-fach/csm_Datendose_1Port-mit-Rahmen_a8e4d5e731.png'
import csm_Datendose_2Port_mit_Rahmen_3aa4a64ddf from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme SL, 45-Abgang UP, BK, AP, BT 1-fach, 2-fach, 3-fach/csm_Datendose_2Port-mit-Rahmen_3aa4a64ddf.png'
import csm_Datendose_3Port_mit_Rahmen_46f14fd649 from '../../assets/image/easyLan/system/02_Datendosen/Modulaufnahme SL, 45-Abgang UP, BK, AP, BT 1-fach, 2-fach, 3-fach/csm_Datendose_3Port-mit-Rahmen_46f14fd649.png'

// bodentank
import bodentank from '../../assets/image/easyLan/system/03_Bodentank/OBO:ACKERMANN BODENTANKSYSTEME UT3:UT4/csm_Bodentank_2er_Rahmen_fa84a9dd52.png'

import csm_Bodentank_2er_Rahmen_fa84a9dd52 from '../../assets/image/easyLan/system/03_Bodentank/OBO:ACKERMANN BODENTANKSYSTEME UT3:UT4/csm_Bodentank_2er_Rahmen_fa84a9dd52.png'
import csm_Bodentank_3er_Rahmen_c206643598 from '../../assets/image/easyLan/system/03_Bodentank/OBO:ACKERMANN BODENTANKSYSTEME UT3:UT4/csm_Bodentank_3er_Rahmen_c206643598.png'

import csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394 from '../../assets/image/easyLan/system/03_Bodentank/OBO:Ackermann Bodentankträger Basic UT3, UT4/csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394.png'
import csm_OBO_Bodentanktraeger_Basic_12x_Kestone_721f5e9324 from '../../assets/image/easyLan/system/03_Bodentank/OBO:Ackermann Bodentankträger Basic UT3, UT4/csm_OBO_Bodentanktraeger_Basic_12x_Kestone_721f5e9324.png'
import OBO_Bodentanktraeger_Basic_9x_Kestone from '../../assets/image/easyLan/system/03_Bodentank/OBO:Ackermann Bodentankträger Basic UT3, UT4/OBO_Bodentanktraeger_Basic_9x_Kestone.png'

import csm_PUK_Bodentank_3x3KS_2752d44a76 from '../../assets/image/easyLan/system/03_Bodentank/PUK Bodentankträger Basic UT4/csm_PUK_Bodentank_3x3KS_2752d44a76.png'
import csm_PUK_Bodentank_3x4KS_9d234c2918 from '../../assets/image/easyLan/system/03_Bodentank/PUK Bodentankträger Basic UT4/csm_PUK_Bodentank_3x4KS_9d234c2918.png'

import Blindplatte from '../../assets/image/easyLan/system/03_Bodentank/OBO:Ackermann Einsätze für Bodentanksysteme UT3, UT4, Zubehör/Blindplatte.png'
import OBO_Einsatz_4xKS from '../../assets/image/easyLan/system/03_Bodentank/OBO:Ackermann Einsätze für Bodentanksysteme UT3, UT4, Zubehör/OBO_Einsatz_4xKS.png'
import OSO_Einsatz_3xKS from '../../assets/image/easyLan/system/03_Bodentank/OBO:Ackermann Einsätze für Bodentanksysteme UT3, UT4, Zubehör/OSO_Einsatz_3xKS.png'

// hutschienenadapter
// import hutschienenadapter from '../../assets/image/easyLan/system/04_Hutschienenadapter/Hutschienen-Modulgehäuse für 1 Modul/csm_Hutschienenmodul_Kompakt_01_66214719b0.png'

import csm_Hutschienenmodul_30d1f96793 from '../../assets/image/easyLan/system/04_Hutschienenadapter/Hutschienen-Modulgehäuse für 1 Modul/csm_Hutschienenmodul_Kompakt_01_66214719b0.png'
import csm_Hutschienenmodul_Kompakt_01_66214719b0 from '../../assets/image/easyLan/system/04_Hutschienenadapter/Hutschienen-Modulgehäuse für 1 Modul/csm_Hutschienenmodul_Kompakt_01_66214719b0.png'

// import csm_Keystone_Adaptereinsatz_LCD_SCS_77592328b3 from '../../assets/image/easyLan/system/04_Hutschienenadapter/Keystone-F-Kupplung und Blindabdeckung Keystone-Leermodul/csm_Keystone_Adaptereinsatz_LCD-SCS_77592328b3.png'
// import Keystone_Adapter_F_Kupplung from '../../assets/image/easyLan/system/04_Hutschienenadapter/Keystone-F-Kupplung und Blindabdeckung Keystone-Leermodul/Keystone Adapter F-Kupplung.png'

import csm_Hutschienenmodul_01_32c872ee2e from '../../assets/image/easyLan/system/04_Hutschienenadapter/Kompaktes Hutschienen-Modulgehäuse für 1 Modul/csm_Hutschienenmodul_01_32c872ee2e.png'
import Hutschienenmodul_Kompakt from '../../assets/image/easyLan/system/04_Hutschienenadapter/Kompaktes Hutschienen-Modulgehäuse für 1 Modul/Hutschienenmodul Kompakt.png'

// panel
import panel from '../../assets/image/easyLan/system/05_Panel/Panel 19" 1HE für 24 Keystone-Module gerader Abgang/csm_1HE_fixLink_Panel_5f97ddb36b.png'

import csm_05HE_panel_fixlink_01_2fba799269 from '../../assets/image/easyLan/system/05_Panel/Panel 19" 0,5HE für 24 Keystone-Module gerader Abgang/csm_05HE_panel_fixlink_01_2fba799269.png'
import csm_05HE_panel_fixlink_c73fbc8cf9 from '../../assets/image/easyLan/system/05_Panel/Panel 19" 0,5HE für 24 Keystone-Module gerader Abgang/csm_05HE_panel_fixlink_c73fbc8cf9.png'

import csm_1HE_fixLink_Panel_01_651d804a47 from '../../assets/image/easyLan/system/05_Panel/Panel 19" 1HE für 24 Keystone-Module gerader Abgang/csm_1HE_fixLink_Panel_01_651d804a47.png'
import csm_1HE_fixLink_Panel_5f97ddb36b from '../../assets/image/easyLan/system/05_Panel/Panel 19" 1HE für 24 Keystone-Module gerader Abgang/csm_1HE_fixLink_Panel_5f97ddb36b.png'
import csm_panel_eco_24fach_easylan_grau_04_9aa3002615 from '../../assets/image/easyLan/system/05_Panel/Panel 19" 1HE für 24 Keystone-Module gerader Abgang/csm_panel_eco_24fach_easylan_grau_04_9aa3002615.png'

import csm_48x_fixLink_Panel_19a5ba2e55 from '../../assets/image/easyLan/system/05_Panel/Panel 19" 1HE für 48 Keystone-Module gerader Abgang/csm_48x_fixLink_Panel_19a5ba2e55.png'

import csm_Beschriftungspanel_70ebc940db from '../../assets/image/easyLan/system/05_Panel/Panel 19" 1HE für 24 Keystone-Module inklusive Beschriftungsfenster/csm_Beschriftungspanel_70ebc940db.png'

import csm_Seite23_67_a24b155e45 from '../../assets/image/easyLan/system/05_Panel/Winkelpanel 30° 19" 1HE für 24 Keystone-Module/csm_Seite23_67_a24b155e45.png'
import csm_Winkelpanel_30__22e31b820a from '../../assets/image/easyLan/system/05_Panel/Winkelpanel 30° 19" 1HE für 24 Keystone-Module/csm_Winkelpanel_30__22e31b820a.png'
import csm_Winkelpanel_30__links_6c3447012b from '../../assets/image/easyLan/system/05_Panel/Winkelpanel 30° 19" 1HE für 24 Keystone-Module/csm_Winkelpanel_30__links_6c3447012b.png'
import csm_Winkelpanel_30__links from '../../assets/image/easyLan/system/05_Panel/Winkelpanel 30° 19" 1HE für 24 Keystone-Module/csm_Winkelpanel_30__links-01_4889081f31.png'

// baugruppenträger
// import baugruppentraeger from '../../assets/image/easyLan/system/06_Baugruppenträger/Baugruppenträger 19" 3HE für den Einsatz von Einschubmodulen/csm_BGT_3HE-1_8bf0b66dbe.png'

// import csm_BGT_3HE1_8bf0b66dbe from '../../assets/image/easyLan/system/06_Baugruppenträger/Baugruppenträger 19" 3HE für den Einsatz von Einschubmodulen/csm_BGT_3HE-1_8bf0b66dbe.png'

// import csm_fixLink_preLink_Einschubmodul_02_b91415f81e from '../../assets/image/easyLan/system/06_Baugruppenträger/Keystone-Einschubmodul für Modulträger/csm_fixLink_-_preLink_Einschubmodul_-02_b91415f81e.png'
// import csm_fixLink_preLink_Einschubmodul_90bf825cb2 from '../../assets/image/easyLan/system/06_Baugruppenträger/Keystone-Einschubmodul für Modulträger/csm_fixLink_-_preLink_Einschubmodul_90bf825cb2.png'

// import csm_Modulträger_1HE_c1cb0024f0 from '../../assets/image/easyLan/system/06_Baugruppenträger/Modulträger (BGT) 19" 1HE für den Einsatz von Einschubmodulen/csm_Modulträger_1HE_c1cb0024f0.png'

// import csm_server_sidepanels_01_freigestellt_a24e564e0c from '../../assets/image/easyLan/system/06_Baugruppenträger/Side-Modulträger 3HE:14TE für den Einsatz von Einschubmodulen/csm_server_sidepanels_01_freigestellt_a24e564e0c.png'
// import csm_Sidemodulträger_3HE_87268249f6 from '../../assets/image/easyLan/system/06_Baugruppenträger/Side-Modulträger 3HE:14TE für den Einsatz von Einschubmodulen/csm_Sidemodulträger_3HE_87268249f6.png'

// consolidation point panel
import consolidation_point_panel from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Aufbau-Consolidation Point Panel für 4-12 Keystone-Module/csm_Unterbaupanel_8x_KS_RAL7035_4b7970d80d.png'

import csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1 from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Aufbau-Consolidation Point Panel für 4-12 Keystone-Module/csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1.png'
import csm_Unterbaupanel_6x_KS_RAL7035_80ea8a870e from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Aufbau-Consolidation Point Panel für 4-12 Keystone-Module/csm_Unterbaupanel_6x_KS_RAL7035_80ea8a870e.png'
import csm_Unterbaupanel_8x_KS_RAL7035_4b7970d80d from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Aufbau-Consolidation Point Panel für 4-12 Keystone-Module/csm_Unterbaupanel_8x_KS_RAL7035_4b7970d80d.png'
import csm_Unterbaupanel_12x_KS_RAL7035_88e81ec53b from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Aufbau-Consolidation Point Panel für 4-12 Keystone-Module/csm_Unterbaupanel_12x_KS_RAL7035_88e81ec53b.png'

import csm_CP_Gehäuse_10_39edc363aa from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Unterflur-Consolidation Point Panel 19" bzw. 10" 1HE/csm_CP-Gehäuse_10___39edc363aa.png'
import csm_CP_Gehäuse_19_0784ff206b from '../../assets/image/easyLan/system/07_Consolidation Point Panel/Unterflur-Consolidation Point Panel 19" bzw. 10" 1HE/csm_CP-Gehäuse_19___0784ff206b.png'

// trunkkabel
// import trunkkabel from '../../assets/image/easyLan/system/08_Trunkkabel/SL CTBC-Aufteilsystem Copper-Trunk-Bundled-Cable,Klasse EA ISO:IEC/csm_CTBC_Trunkkabel_fixLink_8023e4b1c3.png'

// import csm_CTBC_Trunkkabel_fixLink_8023e4b1c3 from '../../assets/image/easyLan/system/08_Trunkkabel/SL CTBC-Aufteilsystem Copper-Trunk-Bundled-Cable,Klasse EA ISO:IEC/csm_CTBC_Trunkkabel_fixLink_8023e4b1c3.png'
// import csm_Fixlink_geschlossen_hinten_Kabel_orange_Bild_2_b5cc77d502 from '../../assets/image/easyLan/system/08_Trunkkabel/SL CTBC-Aufteilsystem Copper-Trunk-Bundled-Cable,Klasse EA ISO:IEC/csm_Fixlink-geschlossen-hinten-Kabel-orange-Bild-2_b5cc77d502.png'

// import csm_CTCC_Trunkkabel_fixLink_efb2b05f62 from '../../assets/image/easyLan/system/08_Trunkkabel/SL CTCC-Aufteilsystem Copper-Trunk-Compact-Cable,Klasse EA ISO:IEC/csm_CTCC_Trunkkabel_fixLink_efb2b05f62.png'
// import csm_Fixlink_geschlossen_hinten_Kabel_Geflecht_Retusche_7a666cc8e8 from '../../assets/image/easyLan/system/08_Trunkkabel/SL CTCC-Aufteilsystem Copper-Trunk-Compact-Cable,Klasse EA ISO:IEC/csm_Fixlink-geschlossen-hinten-Kabel-Geflecht-Retusche_7a666cc8e8.png'

// // h.d.s. innenkabel
// import hds_innengabel from '../../assets/image/easyLan/system/09_H.D.S. Innenkabel/H.D.S. Innenkabel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca/csm_HDS_Innenkabel-01_4cd8e032b6.png'

// import csm_HDS_Innenkabel_01_4cd8e032b61 from '../../assets/image/easyLan/system/09_H.D.S. Innenkabel/H.D.S. Innenkabel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca/csm_HDS_Innenkabel-01_4cd8e032b6.png'
// import HDS_Innenkabel1 from '../../assets/image/easyLan/system/09_H.D.S. Innenkabel/H.D.S. Innenkabel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca/HDS Innenkabel.png'

// import csm_HDS_Innenkabel_01_4cd8e032b62 from '../../assets/image/easyLan/system/09_H.D.S. Innenkabel/H.D.S. Innenkabel Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca/csm_HDS_Innenkabel-01_4cd8e032b6.png'
// import HDS_Innenkabel2 from '../../assets/image/easyLan/system/09_H.D.S. Innenkabel/H.D.S. Innenkabel Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca/HDS Innenkabel.png'

export type GHMTCertificate = {
  name: string
  href: string
}
export type Datasheet = {
  name: string
  href: string
}

export type CompatibilitySheet = {
  name: string
  href: string
}
export type ExcelTemplate = {
  name: string
  href: string
}

export type Item = {
  name: string
  id: string
  text: string | string[]
  images: string[]
  overview?: string
  ghmtCertificates?: GHMTCertificate[]
  datasheets?: Datasheet[]
  excelTemplates?: ExcelTemplate[]
  compatibilitySheets?: CompatibilitySheet[]
  videoEmbedLinks?: string[]
  manual?: {
    de?: string
    'de-01'?: string
    'de-02'?: string
    en?: string
    'en-01'?: string
    'en-02'?: string
  }
}

export type Category = {
  name: string
  img: string
  items: Item[]
}

const getItems = (t: any): Category[] => {
  return [
    {
      name: t.easylan.categoryData.modules,
      img: module,
      items: [
        {
          name: t.easylan.categoryData.fixLink_SL_02.name,
          id: 'fixLink_SL_02',
          text: t.easylan.categoryData.fixLink_SL_02.text,
          images: [fixLink_SL_01, fixLink_SL_02, fixLink_SL],
          ghmtCertificates: t.easylan.categoryData.fixLink_SL_02.ghmtCertificates,
          datasheets: [
            {
              name: 'AWG 24-22',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/ZN-CU151082_F_Datasheet--EasyLan_fixLink_SL_Cat6A_ISO-IEC_AWG24-22_CKFAK001.pdf',
            },
            {
              name: 'AWG 27-26',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/ZN-CU151083_F_Datasheet-EasyLan_fixLink_SL_Cat6A_ISO-IEC_AWG27-26_CKFAKFL1.pdf',
            },
          ],
          videoEmbedLinks: [
            'https://www.youtube-nocookie.com/embed/5aCgEl30eSk?si=e-Z42dsELbv-kAny',
            'https://www.youtube-nocookie.com/embed/02L0j3XXo4M?si=SS_SEwhwUkT-74xL',
            'https://www.youtube-nocookie.com/embed/fJTNkuj8F94?si=aDNR66zxhsM38D6O',
          ],
          manual: {
            de: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/EasyLan_fixLink_SL_Keystone_Montageanleitung.pdf',
            en: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/EasyLan_fixLink_SL_Keystone_Montageanleitung.pdf',
          },
        },
        {
          name: t.easylan.categoryData.csm_FixLink_Pro03_b20813b518.name,
          id: 'csm_FixLink_Pro03_b20813b518',
          text: t.easylan.categoryData.csm_FixLink_Pro03_b20813b518.text,
          images: [csm_FixLink_Pro_f04d507fcc, csm_Fixlink_Pro02_521c4e51f3, csm_FixLink_Pro03_b20813b518],
          ghmtCertificates: t.easylan.categoryData.csm_FixLink_Pro03_b20813b518.ghmtCertificates,
          datasheets: [
            {
              name: 'AWG 24-22',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/ZN-CU220059_A_EasyLan-Datenblatt_fixLink__Pro_Cat6A_ISO-IEC_AWG24-22.pdf',
            },
            {
              name: 'AWG 27-26',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/ZN-CU220060_A_EasyLan--Datenblatt_fixLink__Pro_Cat6A_ISO-IEC_AWG27-26.pdf',
            },
          ],
          videoEmbedLinks: ['https://www.youtube-nocookie.com/embed/4x2-bgSwc0E?si=BfrgY7t0c8ZyGL3U'],
          manual: {
            de: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/Montageanleitung_FixLink_Pro.pdf',
            en: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/Montageanleitung_FixLink_Pro.pdf',
          },
        },
        {
          name: t.easylan.categoryData.fixLinkSLGewinkelt.name,
          id: 'fixLinkSLGewinkelt',
          text: t.easylan.categoryData.fixLinkSLGewinkelt.text,
          images: [fixLink_SL_gewinkelt, csm_fixLink_SL_gewinkelt_01_e1e50d5add, csm_fixLink_SL_gewinkelt_02_34c439abcd],
          ghmtCertificates: t.easylan.categoryData.fixLinkSLGewinkelt.ghmtCertificates,
          datasheets: [
            {
              name: 'AWG 24-22',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/ZN-CU161034_D_Datasheet_EasyLan_fixLink_WA_Cat6A_ISO-IEC_AWG24-22_CKWAK007.pdf',
            },
            {
              name: 'AWG 27-26',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/ZN-CU161035_D_Datasheet_EasyLan_fixLink__WA_Cat6A_ISO-IEC_AWG_27-26_CKWAKFL7.pdf',
            },
          ],
          videoEmbedLinks: ['https://www.youtube-nocookie.com/embed/i7CDf3zryXM?si=Qr15480UARXfm4Fu'],
          manual: {
            de: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/fixLink_gewinkelt_Montageanleitung_908961.png',
            en: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/fixLink_gewinkelt_Montageanleitung_908961.png',
          },
        },
        {
          name: t.easylan.categoryData.csm_FixlinkUTP_e5de94d502.name,
          id: 'csm_FixlinkUTP_e5de94d502',
          text: t.easylan.categoryData.csm_FixlinkUTP_e5de94d502.text,
          images: [csm_Fixlink_UTP_01_e6c47d48a1, csm_Fixlink_UTP_02_128ed096a8, csm_Fixlink_UTP_e5de94d502],
          datasheets: [
            {
              name: 'AWG 24-22',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/RJ45+Keystone-Modul/ZN-CU171012_E_EasyLan_Datenblatt_fixLink_UTP_Cat6A_EIA-TIA_AWG22-24_CKFVK000.pdf',
            },
          ],
          manual: {
            de: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/RJ45+Keystone-Modul/fixLink_UTP_Montageanleitung_908957.pdf',
            en: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/RJ45+Keystone-Modul/fixLink_UTP_Montageanleitung_908957.pdf',
          },
        },
        {
          name: t.easylan.categoryData.csm_fixLink_Extender_a0613ee77a.name,
          id: 'csm_fixLink_Extender_a0613ee77a',
          text: t.easylan.categoryData.csm_fixLink_Extender_a0613ee77a.text,
          images: [csm_fixLink_Extender_01_c1c158832c, csm_fixLink_Extender_a0613ee77a],
          datasheets: [
            {
              name: 'AWG 24-22',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Extender/ZN-CU151027_D_EasyLan_Datasheet_fixLink_Extender_Kat6A_ISO-IEC_AWG24-22_CKEFFL00.pdf',
            },
            {
              name: 'AWG 27-26',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Extender/ZN-CU151030_C_EasyLan_Datasheet_fixLink_Extender_Kat6A_ISO-IEC_AWG27-26_CKEFFLFL.pdf',
            },
          ],
          videoEmbedLinks: ['https://www.youtube-nocookie.com/embed/HfvowI674xo?si=9d0uXF4PP1jnnzs0'],
          manual: {
            de: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Extender/EasyLan_fixLink_Extender_Montageanleitung.pdf',
            en: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Extender/EasyLan_fixLink_Extender_Montageanleitung.pdf',
          },
        },
        {
          name: t.easylan.categoryData.csm_fixLinkRJ45Plug_Katalog_c858f0a6ed.name,
          id: 'csm_fixLinkRJ45Plug_Katalog_c858f0a6ed',
          text: t.easylan.categoryData.csm_fixLinkRJ45Plug_Katalog_c858f0a6ed.text,
          images: [
            csm_fixLink_RJ45_Plug_Katalog_c858f0a6ed,
            csm_fixLink_RJ45_Plug_Katalog_01_60bf7c14ca,
            csm_fixLink_RJ45_Plug_Katalog_02_cf5f3dd3c4,
          ],
          datasheets: [
            {
              name: 'AWG 26-22',
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/RJ45+Plug/ZN-CU220058_B_EasyLan-Datasheet_fixLink__RJ45_Plug_Cat6A_ISO-IEC.pdf',
            },
          ],
          videoEmbedLinks: ['https://www.youtube-nocookie.com/embed/h2XaMN7Cd70?si=X3Q8UkjO3qL0gYrJ'],
          manual: {
            de: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/RJ45+Plug/913736_Montageanleitung_FixLink_RJ45_Einzelverpackung.pdf',
            en: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/RJ45+Plug/913736_Montageanleitung_FixLink_RJ45_Einzelverpackung.pdf',
          },
        },
        {
          name: t.easylan.categoryData.werkzeugeUndZubehoer.name,
          id: 'werkzeugeUndZubehoer',
          text: t.easylan.categoryData.werkzeugeUndZubehoer.text,
          images: [csm_Staubschutzklappen_65cb5db79c, Abmantelwerkzeug, csm_KupferbandRolle_4092658142, KupferbandStreife],
          overview: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
        },
      ],
    },
    // placeholder für Verlegekabel
    {
      img: verlegekabel,
      name: t.easylan.categoryData.verlegekabel,
      items: [
        {
          name: t.easylan.categoryData.goldCsm_Datenkabel_SFTP_orange_074bad6e0f.name,
          id: 'goldCsm_Datenkabel_SFTP_orange_074bad6e0f',
          text: t.easylan.categoryData.goldCsm_Datenkabel_SFTP_orange_074bad6e0f.text,
          images: [
            goldCsm_Datenkabel_SFTP_orange_074bad6e0f,
            goldCsm_Querschnitt_SFTP__RAL_2003_d6e62072fc,
            goldCsm_Querschnitt_Duplex_SFTP__RAL_2003_1fca4a638b,
          ],
          datasheets: t.easylan.categoryData.goldCsm_Datenkabel_SFTP_orange_074bad6e0f.datasheets,
          ghmtCertificates: t.easylan.categoryData.goldCsm_Datenkabel_SFTP_orange_074bad6e0f.ghmtCertificates,
        },
        {
          name: t.easylan.categoryData.silberCsm_Datenkabel_SFTP_orange_074bad6e0f.name,
          id: 'silberCsm_Datenkabel_SFTP_orange_074bad6e0f',
          text: t.easylan.categoryData.silberCsm_Datenkabel_SFTP_orange_074bad6e0f.text,
          images: [
            silberCsm_Datenkabel_SFTP_orange_074bad6e0f,
            silberCsm_Querschnitt_SFTP__RAL_2003_d6e62072fc,
            silberCsm_Querschnitt_Duplex_SFTP__RAL_2003_1fca4a638b,
          ],
          datasheets: t.easylan.categoryData.silberCsm_Datenkabel_SFTP_orange_074bad6e0f.datasheets,
          ghmtCertificates: t.easylan.categoryData.silberCsm_Datenkabel_SFTP_orange_074bad6e0f.ghmtCertificates,
        },
        {
          name: t.easylan.categoryData.bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f.name,
          id: 'bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f',
          text: t.easylan.categoryData.bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f.text,
          images: [
            bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f,
            bronzeCsm_Querschnitt_SFTP__RAL_2003_d6e62072fc,
            bronzeCsm_Querschnitt_Duplex_SFTP__RAL_2003_1fca4a638b,
          ],
          datasheets: t.easylan.categoryData.bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f.datasheets,
        },
      ],
    },
    {
      img: datendosen,
      name: t.easylan.categoryData.datendosen,
      items: [
        {
          name: t.easylan.categoryData.csm_Datendose_designfähig_1fach_f35757e7bc.name,
          id: 'csm_Datendose_designfähig_1fach_f35757e7bc',
          text: t.easylan.categoryData.csm_Datendose_designfähig_1fach_f35757e7bc.text,
          images: [csm_Datendose_designfähig_2_fach_cca354fc62, csm_Datendose_designfähig_1_fach_f35757e7bc],
          datasheets: t.easylan.categoryData.csm_Datendose_designfähig_1fach_f35757e7bc.datasheets,
          compatibilitySheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151007_D_EasyLan_Datasheet_Liste_getesteter_designfa%CC%88higer_Schalterprogramme.pdf',
            },
          ],
        },
        {
          name: t.easylan.categoryData.csm_Abdeckrahmen_1fach_d4075872d1.name,
          id: 'csm_Abdeckrahmen_1fach_d4075872d1',
          text: t.easylan.categoryData.csm_Abdeckrahmen_1fach_d4075872d1.text,
          images: [csm_Abdeckrahmen_1_fach_d4075872d1, csm_Abdeckrahmen_2_fach_405fce48c1, csm_Aufputzgehäuse_3748cacb5d],
          datasheets: t.easylan.categoryData.csm_Abdeckrahmen_1fach_d4075872d1.datasheets,
        },
        {
          name: t.easylan.categoryData.csm_Datendose_1PortmitRahmen_a8e4d5e731.name,
          id: 'csm_Datendose_1PortmitRahmen_a8e4d5e731',
          text: t.easylan.categoryData.csm_Datendose_1PortmitRahmen_a8e4d5e731.text,
          images: [csm_Datendose_1Port_mit_Rahmen_a8e4d5e731, csm_Datendose_2Port_mit_Rahmen_3aa4a64ddf, csm_Datendose_3Port_mit_Rahmen_46f14fd649],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+SL/ZN-BL161015_B_EasyLan-Datasheet_Data_Outlet_SL__single_double_triple__incl._Central_Plate.pdf',
            },
          ],
          manual: {
            'de-01':
              'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+SL/HE05100100_HE05100101.pdf',
            'de-02': 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+SL/HE05100102.pdf',
            'en-01':
              'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+SL/HE05100100_HE05100101.pdf',
            'en-02': 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+SL/HE05100102.pdf',
          },
        },
        {
          name: t.easylan.categoryData.DoseUPleer2xKS.name,
          id: 'DoseUPleer2xKS',
          text: t.easylan.categoryData.DoseUPleer2xKS.text,
          images: [Dose_UP_leer_2x_KS],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+gerader+Abgang/ZN-DK200056_B_EasyLan_Modulaufnahme_gerader_Abgang_2-fach.pdf',
            },
          ],
        },
        {
          name: t.easylan.categoryData.csm_IP44_AP_Dose_offen_2e48717c62.name,
          id: 'csm_IP44_AP_Dose_offen_2e48717c62',
          text: t.easylan.categoryData.csm_IP44_AP_Dose_offen_2e48717c62.text,
          images: [csm_IP44_AP_Dose_geschlossen__2e4d774a1f, csm_IP44_AP_Dose_offen_2e48717c62, csm_IP44_AP_Dose_offen_Rückseite__dfe336f19a],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modulaufnahme+IP44+Aufputz/ZN-CU171226_B_CKVDAP92_EasyLan-Datasheet_Data_Outlet_IP44.pdf',
            },
          ],
          videoEmbedLinks: ['https://www.youtube-nocookie.com/embed/THoMy-x5fWY?si=-3Ai0tPa7I326h6D'],
        },
      ],
    },
    {
      img: bodentank,
      name: t.easylan.categoryData.bodentank,
      items: [
        {
          name: t.easylan.categoryData.csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394.name,
          id: 'csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394',
          text: t.easylan.categoryData.csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394.text,
          images: [
            OBO_Bodentanktraeger_Basic_9x_Kestone,
            csm_OBO_Bodentanktraeger_Basic_12x_Kestone_721f5e9324,
            csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394,
          ],
          datasheets: t.easylan.categoryData.csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394.datasheets,
        },
        {
          name: t.easylan.categoryData.csm_PUK_Bodentank_3x3KS_2752d44a76.name,
          id: 'csm_PUK_Bodentank_3x3KS_2752d44a76',
          text: t.easylan.categoryData.csm_PUK_Bodentank_3x3KS_2752d44a76.text,
          images: [csm_PUK_Bodentank_3x3KS_2752d44a76, csm_PUK_Bodentank_3x4KS_9d234c2918],
          datasheets: t.easylan.categoryData.csm_PUK_Bodentank_3x3KS_2752d44a76.datasheets,
        },
        {
          name: t.easylan.categoryData.csm_Bodentank_2er_Rahmen_fa84a9dd52.name,
          id: 'csm_Bodentank_2er_Rahmen_fa84a9dd52',
          text: t.easylan.categoryData.csm_Bodentank_2er_Rahmen_fa84a9dd52.text,
          images: [csm_Bodentank_3er_Rahmen_c206643598, csm_Bodentank_2er_Rahmen_fa84a9dd52],
          datasheets: t.easylan.categoryData.csm_Bodentank_2er_Rahmen_fa84a9dd52.datasheets,
        },

        {
          name: t.easylan.categoryData.OSO_Einsatz_3xKS.name,
          id: 'OSO_Einsatz_3xKS',
          text: t.easylan.categoryData.OSO_Einsatz_3xKS.text,
          images: [Blindplatte, OBO_Einsatz_4xKS, OSO_Einsatz_3xKS],
          datasheets: t.easylan.categoryData.OSO_Einsatz_3xKS.datasheets,
        },
      ],
    },
    {
      img: panel,
      name: t.easylan.categoryData.panel,
      items: [
        {
          name: t.easylan.categoryData.csm_1HE_fixLink_Panel_5f97ddb36b.name,
          id: 'csm_1HE_fixLink_Panel_5f97ddb36b',
          text: t.easylan.categoryData.csm_1HE_fixLink_Panel_5f97ddb36b.text,
          images: [csm_1HE_fixLink_Panel_01_651d804a47, csm_1HE_fixLink_Panel_5f97ddb36b, csm_panel_eco_24fach_easylan_grau_04_9aa3002615],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Panel+19%22+1HE+f%C3%BCr+24+Keystone-Module+gerader+Abgang/ZN-BL151004_D_EasyLan_Datasheet_1U_19___KS_Panel.pdf',
            },
          ],
          videoEmbedLinks: ['https://www.youtube-nocookie.com/embed/fJTNkuj8F94?si=OzaEjgsPkqzGMfLH'],
        },
        {
          name: t.easylan.categoryData.csm_48x_fixLink_Panel_19a5ba2e55.name,
          id: 'csm_48x_fixLink_Panel_19a5ba2e55',
          text: t.easylan.categoryData.csm_48x_fixLink_Panel_19a5ba2e55.text,
          images: [csm_48x_fixLink_Panel_19a5ba2e55],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Panel+19%22+1HE+f%C3%BCr+48+Keystone-Module+gerader+Abgang/ZN-BL230042_B_CKVFL0481E_EasyLan_Datasheet_Panel_1HE_19___for_48_FixLink_Pro_Pro__Keystone_Modules.pdf',
            },
          ],
        },
        {
          name: t.easylan.categoryData.csm_05HE_panel_fixlink_c73fbc8cf9.name,
          id: 'csm_05HE_panel_fixlink_c73fbc8cf9',
          text: t.easylan.categoryData.csm_05HE_panel_fixlink_c73fbc8cf9.text,
          images: [csm_05HE_panel_fixlink_01_2fba799269, csm_05HE_panel_fixlink_c73fbc8cf9],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Panel+19%22+0%2C5HE+f%C3%BCr+24+Keystone-Module+gerader+Abgang/ZN-BL151092_B_CKVPL5FOEE_EasyLan_Datasheet_0_5HE_19___Panel.pdf',
            },
          ],
        },
        {
          name: t.easylan.categoryData.csm_Beschriftungspanel_70ebc940db.name,
          id: 'csm_Beschriftungspanel_70ebc940db',
          text: t.easylan.categoryData.csm_Beschriftungspanel_70ebc940db.text,
          images: [csm_Beschriftungspanel_70ebc940db],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Panel+19%22+1HE+f%C3%BCr+24+Keystone-Module+inklusive+Beschriftungsfenster/ZN-BL171059-B_CKVPL0B01E_EasyLan_Datasheet_-_19__Panel_24x_Keystone_incl._labeling_windows.pdf',
            },
          ],
          excelTemplates: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Panel+19%22+1HE+f%C3%BCr+24+Keystone-Module+inklusive+Beschriftungsfenster/ZVK+Perfo+Vorlage.xlsx',
            },
          ],
        },
        {
          name: t.easylan.categoryData.csm_Winkelpanel_30__22e31b820a.name,
          id: 'csm_Winkelpanel_30__22e31b820a',
          text: t.easylan.categoryData.csm_Winkelpanel_30__22e31b820a.text,
          images: [csm_Seite23_67_a24b155e45, csm_Winkelpanel_30__22e31b820a, csm_Winkelpanel_30__links_6c3447012b, csm_Winkelpanel_30__links],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Winkelpanel+30%C2%B0+19%22+1HE+f%C3%BCr+24+Keystone-Module/ZN-BL151068_D_EasyLan_Datasheet_30__Winkelpanel.pdf',
            },
          ],
        },
      ],
    },
    {
      img: consolidation_point_panel,
      name: t.easylan.categoryData.consolidation_point_panel,
      items: [
        {
          name: t.easylan.categoryData.csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1.name,
          id: 'csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1',
          text: t.easylan.categoryData.csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1.text,
          images: [
            csm_Unterbaupanel_8x_KS_RAL7035_4b7970d80d,
            csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1,
            csm_Unterbaupanel_6x_KS_RAL7035_80ea8a870e,
            csm_Unterbaupanel_12x_KS_RAL7035_88e81ec53b,
          ],
          datasheets: [
            {
              name: t.easylan.categoryData.generic.download,
              href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Aufbau-Consolidation+Point+Panel+f%C3%BCr+4-12+Keystone-Module/ZN-BL161024-B_EasyLan_Datasheet_Keystone_Unterbaupanels.pdf',
            },
          ],
        },
        {
          name: t.easylan.categoryData.csm_CPGehäuse_10___39edc363aa.name,
          id: 'csm_CPGehäuse_10___39edc363aa',
          text: t.easylan.categoryData.csm_CPGehäuse_10___39edc363aa.text,
          images: [csm_CP_Gehäuse_10_39edc363aa, csm_CP_Gehäuse_19_0784ff206b],
          datasheets: t.easylan.categoryData.csm_CPGehäuse_10___39edc363aa.datasheets,
        },
        // hutschienenadapter
        {
          name: t.easylan.categoryData.csm_Hutschienenmodul_Kompakt_01_66214719b0.name,
          id: 'csm_Hutschienenmodul_Kompakt_01_66214719b0',
          text: t.easylan.categoryData.csm_Hutschienenmodul_Kompakt_01_66214719b0.text,
          images: [csm_Hutschienenmodul_30d1f96793, csm_Hutschienenmodul_Kompakt_01_66214719b0],
          datasheets: t.easylan.categoryData.csm_Hutschienenmodul_Kompakt_01_66214719b0.datasheets,
        },
        // {
        //   name: t.easylan.categoryData.KeystoneAdapterFKupplung.name,
        //   id: 'KeystoneAdapterFKupplung',
        //   text: t.easylan.categoryData.KeystoneAdapterFKupplung.text,
        //   images: [csm_Keystone_Adaptereinsatz_LCD_SCS_77592328b3, Keystone_Adapter_F_Kupplung],
        // },
        {
          name: t.easylan.categoryData.csm_Hutschienenmodul_01_32c872ee2e.name,
          id: 'csm_Hutschienenmodul_01_32c872ee2e',
          text: t.easylan.categoryData.csm_Hutschienenmodul_01_32c872ee2e.text,
          images: [csm_Hutschienenmodul_01_32c872ee2e, Hutschienenmodul_Kompakt],
          datasheets: t.easylan.categoryData.csm_Hutschienenmodul_01_32c872ee2e.datasheets,
        },
        // baugruppentraeger
        // {
        //   name: t.easylan.categoryData.csm_BGT_3HE1_8bf0b66dbe.name,
        //   id: 'csm_BGT_3HE1_8bf0b66dbe',
        //   text: t.easylan.categoryData.csm_BGT_3HE1_8bf0b66dbe.text,
        //   images: [csm_BGT_3HE1_8bf0b66dbe],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Baugruppentr%C3%A4ger+19%22+3HE+f%C3%BCr+den+Einsatz+von+Einschubmodulen/ZN-BL171075_C_Datasheet_EasyLan_BGT_19_Zoll_3HE_84TE.pdf',
        //     },
        //   ],
        // },
        // {
        //   name: t.easylan.categoryData.csm_fixLink_preLink_Einschubmodul_02_b91415f81e.name,
        //   id: 'csm_fixLink_preLink_Einschubmodul_02_b91415f81e',
        //   text: t.easylan.categoryData.csm_fixLink_preLink_Einschubmodul_02_b91415f81e.text,
        //   images: [csm_fixLink_preLink_Einschubmodul_02_b91415f81e, csm_fixLink_preLink_Einschubmodul_90bf825cb2],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Keystone-Einschubmodul+f%C3%BCr+Modultr%C3%A4ger/ZN-BL181383_B_CVE000001E_EasyLan_Datasheet_Keystone_-_Einschubmodul_3HE_-_7TE.pdf',
        //     },
        //   ],
        // },
        // {
        //   name: t.easylan.categoryData.csm_Modulträger_1HE_488e9c42e4.name,
        //   id: 'csm_Modulträger_1HE_488e9c42e4',
        //   text: t.easylan.categoryData.csm_Modulträger_1HE_488e9c42e4.text,
        //   images: [csm_Modulträger_1HE_c1cb0024f0],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Modultr%C3%A4ger+(BGT)+19%22+1HE+f%C3%BCr+den+Einsatz+von+Einschubmodulen/ZN-BL161039_D_AVM113001E_Datasheet_EasyLan_BGT_19_Zoll_1HE.pdf',
        //     },
        //   ],
        // },
        // {
        //   name: t.easylan.categoryData.csm_Sidemodulträger_3HE_87268249f6.name,
        //   id: 'csm_Sidemodulträger_3HE_87268249f6',
        //   text: t.easylan.categoryData.csm_Sidemodulträger_3HE_87268249f6.text,
        //   images: [csm_server_sidepanels_01_freigestellt_a24e564e0c, csm_Sidemodulträger_3HE_87268249f6],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Side-Modultr%C3%A4ger+3HE-14TE+f%C3%BCr+den+Einsatz+von+Einschubmodulen/ZN-BL181372_B_AVMS10001E_EasyLan_Datasheet_Side-Modultra%CC%88ger_14TE_-_3HE.pdf',
        //     },
        //   ],
        // },
        // // trunkkabel
        // {
        //   name: t.easylan.categoryData.csm_CTBC_Trunkkabel_fixLink_8023e4b1c3.name,
        //   id: 'csm_CTBC_Trunkkabel_fixLink_8023e4b1c3',
        //   text: t.easylan.categoryData.csm_CTBC_Trunkkabel_fixLink_8023e4b1c3.text,
        //   images: [csm_CTBC_Trunkkabel_fixLink_8023e4b1c3, csm_Fixlink_geschlossen_hinten_Kabel_orange_Bild_2_b5cc77d502],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+CTBC-Aufteilsystem+Copper-Trunk-Bundled-Cable/ZN-CU151064_C_Datasheet_EasyLan_CTBC_with_fixLink.pdf',
        //     },
        //   ],
        // },
        // {
        //   name: t.easylan.categoryData.csm_CTCC_Trunkkabel_fixLink_efb2b05f62.name,
        //   id: 'csm_CTCC_Trunkkabel_fixLink_efb2b05f62',
        //   text: t.easylan.categoryData.csm_CTCC_Trunkkabel_fixLink_efb2b05f62.text,
        //   images: [csm_CTCC_Trunkkabel_fixLink_efb2b05f62, csm_Fixlink_geschlossen_hinten_Kabel_Geflecht_Retusche_7a666cc8e8],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+CTCC-Aufteilsystem+Copper-Trunk-Compact-Cable/ZN-CU181518_B_EasyLan_Datasheet_CTCC_with_fixLink_AWG26.pdf',
        //     },
        //   ],
        // },
        // // hds_innengabel
        // {
        //   name: t.easylan.categoryData.csm_HDS_Innenkabel01_4cd8e032b6.name,
        //   id: 'csm_HDS_Innenkabel01_4cd8e032b6',
        //   text: t.easylan.categoryData.csm_HDS_Innenkabel01_4cd8e032b6.text,
        //   images: [csm_HDS_Innenkabel_01_4cd8e032b61, HDS_Innenkabel1],
        //   datasheets: [
        //     {
        //       name: 'AWG23 B2ca',
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/H.D.S.+Innenkabel+Kat.+7%2C+6x+(4+x+2+x+AWG+23)+S-FTP+B2ca/ZN-DK181347_C_EasyLan-Datasheet_Data_Cable_24P_AWG23_B2ca.pdf',
        //     },
        //     {
        //       name: 'AWG23 Dca',
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/H.D.S.+Innenkabel+Kat.+7%2C+6x+(4+x+2+x+AWG+23)+S-FTP+B2ca/ZN-DK181348_C_EasyLan-Datasheet_Data_Cable_24P_AWG23_Dca.pdf',
        //     },
        //   ],
        // },
        // {
        //   name: t.easylan.categoryData.csm_HDS_Innenkabel01_4cd8e032b7.name,
        //   id: 'csm_HDS_Innenkabel01_4cd8e032b7',
        //   text: t.easylan.categoryData.csm_HDS_Innenkabel01_4cd8e032b7.text,
        //   images: [csm_HDS_Innenkabel_01_4cd8e032b62, HDS_Innenkabel2],
        //   datasheets: [
        //     {
        //       name: t.easylan.categoryData.generic.download,
        //       href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/H.D.S.+Innenkabel+Kat.+7+6x+(4+x+2+x+AWG+26)+S-FTP+Dca/ZN-DK181349_C_EasyLan-Datasheet_Data_Cable_24P_AWG26_Dca.pdf',
        //     },
        //   ],
        // },
      ],
    },
  ]
}

export default getItems

import wire from '../../../assets/image/easyLan/hero/wire.svg'
// import wire from '../../../assets/image/easyLan/hero/wire_new.svg'
import bg from '../../../assets/image/easyLan/hero/bg.svg'
import textBG from '../../../assets/image/easyLan/hero/text-bg.svg'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Block from '../../UI/Block/Block'
import { BackgroundImage, ImageWrapper, Layer, SlideInContent } from './Hero.Styled'

const Hero = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Block>
      <Layer zIndex={1}>
        <BackgroundImage img={bg} animation={'fadeIn'} />
      </Layer>
      <Layer img={textBG} zIndex={2} centerContent>
        <div className="w-full h-40 flex flex-col-reverse lg:flex-row items-center gap-5 lg:justify-between">
          <SlideInContent animation={'fadeInLeft'}>
            <span className="text-danger text-center" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
            <div className="font-bold text-white text-xl leading-10 md:text-[50px] lg:text-[60px] md:leading-[50px] lg:leading-[60px]">
              {t.easylan.hero.title.text1}
              <br />
              {t.easylan.hero.title.text2}
            </div>
            <div className="text-white font-sm text-center">{t.easylan.hero.subtitle}</div>
            <a className="py-1 px-5 uppercase bg-danger text-white" href="/#system">
              {t.easylan.hero.cta}
            </a>
          </SlideInContent>
          <ImageWrapper className="" animation={'fadeInRight'}>
            <img className="h-40 lg:h-[50vh]" alt="" src={wire} />
          </ImageWrapper>
        </div>
      </Layer>
    </Block>
  )
}

export default Hero

import { useDispatch, useSelector } from 'react-redux'
import Item, { NaviItem } from './Item'
import props from '../../../../redux/props'
import { useEffect, useState } from 'react'
import { Bars4Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { Lang, LangKey } from '../../../../redux/defaultState'
import { setLocal } from '../../../../redux/action/local'

import al from '../../../../assets/image/flags/al.gif'
import bg from '../../../../assets/image/flags/bg.gif'
import cz from '../../../../assets/image/flags/cz.gif'
import de from '../../../../assets/image/flags/de.gif'
import en from '../../../../assets/image/flags/en.gif'
import fr from '../../../../assets/image/flags/fr.gif'
import hr from '../../../../assets/image/flags/hr.gif'
import ma from '../../../../assets/image/flags/ma.gif'
import md from '../../../../assets/image/flags/md.gif'
import pl from '../../../../assets/image/flags/pl.gif'
import ro from '../../../../assets/image/flags/ro.gif'
import ru from '../../../../assets/image/flags/ru.gif'
import sk from '../../../../assets/image/flags/sk.gif'
import sr from '../../../../assets/image/flags/sr.gif'
import tn from '../../../../assets/image/flags/tn.gif'
import tr from '../../../../assets/image/flags/tr.gif'
import ua from '../../../../assets/image/flags/ua.gif'
import bs from '../../../../assets/image/flags/bs.gif'
import ModalSimple from '../../ModalSimple/ModalSimple'
import { Flag } from './Navigation.Styled'
import cookie, { LANG } from '../../helper/cookie'

const Navigation = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const currentLang = useSelector((s) => s[props.CURRENTLANG])
  const languages: Lang[] = useSelector((s) => s[props.LANGUAGES])
  const [open, setOpen] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [languageModalOpen, setLanguageModalOpen] = useState<boolean>(false)
  const flagMap = {
    de,
    en,
    fr,
    ua,
    tr,
    tn,
    sr,
    sk,
    ru,
    ro,
    pl,
    md,
    ma,
    hr,
    cz,
    bs,
    bg,
    al,
  }

  useEffect(() => {
    const lang = cookie.get(LANG)
    if (lang) {
      if (!currentLang || currentLang !== lang) {
        dispatch(setLocal(props.CURRENTLANG, lang))
        const newLang = languages.find((l) => l.langKey === lang)
        dispatch(setLocal(props.TRANSLATION, newLang.translation))
      }
    } else {
      setLanguageModalOpen(true)
    }
  }, [currentLang, dispatch, languages])

  const isActive = (href: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.location.pathname === href
    }
    return false
  }

  const switchLang = (lang: LangKey) => () => {
    const newLang = languages.find((l) => l.langKey === lang)
    dispatch(setLocal(props.TRANSLATION, newLang.translation))
    dispatch(setLocal(props.CURRENTLANG, newLang.langKey))
    cookie.set(LANG, newLang.langKey)
  }

  const toggleMenu = () => {
    setOpen(!open)
  }

  const toggleLanguageModal = () => setLanguageModalOpen(!languageModalOpen)

  return (
    <>
      {/* desktop menu */}
      <div className="hidden lg:flex justify-end flex-row gap-10">
        {t.header.items.map((i: NaviItem) => (
          <Item active={isActive(i.href)} key={i.name} href={i.href} newTab={i.newTab}>
            {i.name}
          </Item>
        ))}
        <Item highlight onClick={toggleLanguageModal}>
          {t.header.language}
        </Item>
      </div>

      {/* mobile menu */}
      <div className="flex lg:hidden justify-end flex-row gap-3">
        <Item highlight onClick={toggleLanguageModal}>
          {t.header.language}
        </Item>
        <Item onClick={toggleMenu}>
          <Bars4Icon className="h-6 text-white" />
          {open && (
            <div className="absolute shadow-md z-20 top-full right-0 w-fit mt-3 flex flex-col">
              {t.header.items.map((i: NaviItem) => (
                <Item
                  className={`${isActive(i.href) ? '!text-danger' : '!text-black'} bg-white py-1 px-5 border-t border-black first:border-none`}
                  key={i.name}
                  href={i.href}
                  newTab={i.newTab}
                >
                  {i.name}
                </Item>
              ))}
            </div>
          )}
        </Item>
      </div>
      <ModalSimple show={languageModalOpen} onClose={toggleLanguageModal} onConfirm={toggleLanguageModal} noPadding>
        <div className="pt-1 px-1">
          <XMarkIcon className="ml-auto h-6 text-danger cursor-pointer" onClick={toggleLanguageModal} />
        </div>
        <div className="px-10 pb-5 pt-3">
          <div className="text-white text-center text-md md:text-lg leading-8">CHOOSE YOUR LANGUAGE</div>
          <div className="text-white text-center text-xs mt-3 md:mt-0">YOUR SELECTION WILL BE SAVED FOR YOUR NEXT VISIT</div>
          <div className="flex flex-wrap gap-[15px] pt-5">
            {languages.map((lang: Lang) => (
              <Flag
                className={`${lang.langKey === currentLang ? 'opacity-30' : 'border border-white'}`}
                key={lang.langKey}
                onClick={switchLang(lang.langKey)}
              >
                <img src={flagMap[lang.langKey.toLowerCase()]} className="w-full aspect-video object-cover" alt={lang.langKey} />
              </Flag>
            ))}
          </div>
        </div>
      </ModalSimple>
    </>
  )
}

export default Navigation

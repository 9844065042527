import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { langKey as DELangKey } from '../../../../translations/de'
import { langKey as FRLangKey } from '../../../../translations/fr'

const Separator = () => <span className="hidden lg:inline text-danger">|</span>

const Link = ({ children, href }) => (
  <a className="text-white text-sm uppercase" href={href} target="_blank" rel="noreferrer">
    {children}
  </a>
)

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const currentLang = useSelector((s) => s[props.CURRENTLANG])

  const isGerman = currentLang === DELangKey
  const isFrench = currentLang === FRLangKey

  return (
    <div className="bg-dark flex flex-col lg:flex-row items-center justify-center py-6 gap-3">
      {isGerman && <Link href="https://www.zvk-gmbh.de/datenschutzerklaerung">{t.footer.dataProtection}</Link>}
      {isFrench && <Link href="https://www.zvk.fr/info/politique-de-confidentialite.html">{t.footer.dataProtection}</Link>}
      {!isGerman && !isFrench && <Link href="https://www.zvk-gmbh.de/en/privacy-policy">{t.footer.dataProtection}</Link>}
      <Separator />
      {isGerman && <Link href="https://www.zvk-gmbh.de/impressum">{t.footer.imprint}</Link>}
      {isFrench && <Link href="https://www.zvk.fr/info/notice-du-site.html">{t.footer.imprint}</Link>}
      {!isGerman && !isFrench && <Link href="https://www.zvk-gmbh.de/en/imprint">{t.footer.imprint}</Link>}
      <Separator />
      {isGerman && <Link href="https://www.easylan.de/ueber-easylan/easylan-partner">{t.footer.contact}</Link>}
      {isFrench && <Link href="https://www.zvk.fr/info/contact.html">{t.footer.contact}</Link>}
      {!isGerman && !isFrench && <Link href="https://www.zvk-gmbh.de/en/contact">{t.footer.contact}</Link>}
    </div>
  )
}

export default Footer

import { useSelector } from 'react-redux'
import bg from '../../../assets/image/easyLan/end/bg.png'
import fixlink from '../../../assets/image/easyLan/end/fixlink.svg'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'

const End = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="relative">
      <img src={bg} alt="EasyLan" className="absolute lg:relative h-full left-0 z-0 object-cover lg:w-full" />
      <div className="relative py-10 lg:absolute top-0 left-0 z-20 h-full w-full flex items-center">
        <Container>
          <div className="flex flex-col gap-10 lg:gap-0 lg:flex-row items-center">
            <div className="lg:flex-1">
              <img src={fixlink} alt="fixLink" className="w-8/12 mx-auto lg:mx-0" />
            </div>
            <div className="lg:flex-1">
              <div className="flex flex-col gap-1">
                <span className="text-danger" />
                <div className="text-lg text-white font-bold leading-8">{t.easylan.end.title}</div>
                <div className="text-white" dangerouslySetInnerHTML={{ __html: t.easylan.end.text }}></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default End

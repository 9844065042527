export const translation = {
  header: {
    items: [
      {
        name: 'Domů',
        href: '/',
      },
      {
        name: 'Systém',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montáž',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Jazyk',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Jednoduchost',
        text2: 'v připojení',
      },
      subtitle: 'Tvůj odkaz pro budoucnost',
      cta: 'více',
    },
    handling: {
      title: 'INTUITIVNÍ MANIPULACE SE SETKÁVÁ S POKROČILOU TECHNOLOGIÍ',
      vorreiterPrefix: 'Kombinace přesnosti a funkčního designu činí ',
      vorreiterSuffix: ' vedoucí volbou v technologii připojení.',
      experte: ' nabízí efektivní 360° řešení, které optimálně spojuje design a výkon pro minimalizaci složitosti.',
      pionier: ' je průkopníkem v přetváření pracovních prostorů, spojující odborné znalosti s intuitivní manipulací.',
    },
    download: {
      prefix: 'Přehled ',
      suffix: ' - Stáhnout systém',
      cta: 'Stáhnout',
      href: 'https://zvk-easylan.blackflagship.com/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Přehled produktů',
      title: 'FIXLINK<sup>®</sup> - SYSTÉM',
    },
    module: {
      overview: 'Stáhnout přehled',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Certifikáty',
          text: 'Spoléháme na GHMT a jejich program Premium Verification pro nezávislé zajištění kvality našich síťových produktů. Tímto garantujeme na základě 20 let zkušeností nejvyšší produktové standardy pro naše zákazníky.',
        },
        datasheets: {
          title: 'Datové listy',
          text: 'Zde najdete všechny potřebné datové listy. Stáhněte si je a mějte všechny informace na jednom místě.',
        },
        compatibilitySheets: {
          title: 'Seznamy kompatibility',
          text: '',
        },
        excelTemplates: {
          title: 'Označovací okna jako šablona Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'NÁVOD K MONTÁŽI',
      text: 'Podívejte se na video nebo si zde jednoduše stáhněte PDF',
      manual: {
        title: 'NÁVODY K MONTÁŽI:',
      },
    },
    separator: {
      title: 'Více než jen připojení',
      title2: 'Objev EasyLan kosmos',
      text: 'Objevte rozmanitost inovativních síťových řešení na EasyLan.de: Přizpůsobená přímo pro vás. Herní měnič pro vaši síť: Naše kabelážní systémy a řešení pro správu kabelů.',
      cta: 'více',
    },
    combination: {
      bau: {
        prefix: 'Zažijte maximální přizpůsobení a kvalitu,',
        text: ' nosiče skupin EasyLan ',
        suffix: ' přímo z Evropy.',
      },
      intelligent: {
        prefix: 'Vaše síť',
        text: ' na míru ',
        suffix: 's konfigurovatelnou EasyLan Spleissbox',
      },
      fixlink: {
        prefix: 'S našimi ',
        suffix: ' produkty můžete dlouhodobě zlepšit své kancelářské budovy.',
      },
      hds: {
        prefix: 'Systém',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: 'spojuje snadnou instalaci a údržbu jako žádný jiný trunkový kabelový systém na trhu.',
      },
    },
    recommended: {
      titleLabel: 'Doporučeno',
      title: 'fixLink<sup>®</sup> - SYSTÉM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Záruka na produkt',
        text: [
          'Záruka na produkt zahrnuje všechny komponenty ',
          ' - optického nebo měděného kabelážního systému, které byly instalovány a testovány kvalifikovaným instalatérem podle pokynů ',
          ' a platných instalačních norem EN50174.',
        ],
      },
      {
        title: 'Výkonnostní záruka',
        text: [
          'Výkonnostní záruka garantuje kromě aplikace (Ethernet, Fiber Channel, ATM atd.) také vlastnosti systému, mimo jiné přenosové rychlosti (10MBit, 100MBit, 1GBit a 10GBit).',
        ],
      },
      {
        title: 'Aplikační záruka',
        text: [
          'Aplikační záruka garantuje všechny specifikované aplikace (Ethernet, Fiber Channel, ATM atd.), které jsou založeny na aktuálních kabelážních standardech ISO/IEC 11801, EN50173 a EIA/TIA 568B platných v době instalace.',
        ],
      },
    ],
    end: {
      title: 'PŘIPOJENÍ NIKDY NEBYLO TAK JEDNODUCHÉ.',
      text: 'Intuitivní manipulace se setkává s pokročilou technologií; objevte bezproblémové připojení budoucnosti: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Připojovací moduly',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-Modul Kat. 6A ISO/IEC, stíněný',
        text: 'S modulem fixLink<sup>®</sup> Pro RJ45 Keystone uživatel získává RJ45 zásuvkový modul nejvyšší kvality. Modul fixLink<sup>®</sup> Pro RJ45 Keystone lze sestavit pomocí bočních kleští za méně než minutu. Díky volně přístupné kontaktní zóně je zaručena perfektní kvalita sestavy a tím i bezpečný přenosový výkon. Speciální kontaktní geometrie v kombinaci s 30 μ" zlatým povlakem zajišťuje 4PPoE podle IEEE 802.3bt za všech podmínek. Těleso je vyrobeno z poniklované zinkové slitiny a splňuje nejvyšší ekologické a mechanické požadavky. V kombinaci s designovým držákem modulu je možná kombinace se všemi spínacími programy. Jsou dodávány v ekologickém kartonovém balení.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanentní odkaz certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, stíněný',
        text: 'S prodlužovačem fixLink<sup>®</sup> lze snadno prodloužit kabely. Dále lze reaktivovat odstavené kabely a prodloužit je na další potřebné místo. Omezený prostor v kabelovém kanálu je tak optimálně využit. Extender se jednoduše přišroubuje na konec instalovaného kabelu do kanálu nebo na zeď. Po dokončení instalace lze takto prodloužený kabel využít až do maximální délky odkazu i pro 10-Gigabitové Ethernetové přenosy a podporuje PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC stíněný',
        text: 'S RJ45 zásuvkovým modulem fixLink<sup>®</sup> uživatel získává pole-sestavitelný RJ45 zásuvkový modul nejvyšší kvality a flexibility. Speciální kontaktní geometrie v kombinaci s 50 μ" zlatým povlakem zajišťuje 4PPoE podle IEEE 80.3bt za všech podmínek. Těleso je vyrobeno z poniklované zinkové slitiny a splňuje nejvyšší ekologické a mechanické požadavky. Různé certifikace (aktuální seznam: www.easylan.de) od nezávislých testovacích laboratoří doplňují nejvyšší kvalitní standardy fixLink<sup>®</sup> systému.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-Modul Kat. 6A EIA/TIA, nestíněný',
        text: 'S modulem fixLink<sup>®</sup> RJ45 Keystone (schopný PoE+) uživatel získává RJ45 zásuvkový modul nejvyšší kvality. Modul fixLink<sup>®</sup> RJ45 Keystone lze sestavit pomocí bočních kleští za méně než minutu. Díky volně přístupné kontaktní zóně je zaručena perfektní kvalita sestavy a tím i bezpečný přenosový výkon. Těleso je vyrobeno z polykarbonátu a splňuje nejvyšší ekologické a mechanické požadavky. V kombinaci s designovým držákem modulu je možná kombinace se všemi spínacími programy. Různé certifikace od nezávislých testovacích laboratoří doplňují nejvyšší kvalitní standardy fixLink<sup>®</sup> SL Keystone-modulu. Jsou dodávány v ekologickém kartonovém balení.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-Modul Kat. 6A ISO/IEC, stíněný',
        text: 'S modulem fixLink<sup>®</sup> SL RJ45 Keystone uživatel získává RJ45 zásuvkový modul nejvyšší kvality. Modul fixLink<sup>®</sup> SL RJ45 Keystone lze sestavit pomocí bočních kleští za méně než minutu. Díky volně přístupné kontaktní zóně je zaručena perfektní kvalita sestavy a tím i bezpečný přenosový výkon. Pro 360° stínění se pouze zacvakne integrovaný klín, čímž odpadá časově náročné použití kabelové svorky. Speciální kontaktní geometrie v kombinaci s 50 μ" zlatým povlakem zajišťuje 4PPoE podle IEEE 802.3bt za všech podmínek. Těleso je vyrobeno z poniklované zinkové slitiny a splňuje nejvyšší ekologické a mechanické požadavky. V kombinaci s designovým držákem modulu je možná kombinace se všemi spínacími programy. Různé certifikace od nezávislých testovacích laboratoří doplňují nejvyšší kvalitní standardy fixLink<sup>®</sup> SL Keystone-modulu. Jsou dodávány v ekologickém kartonovém balení.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Testovací zpráva 4PoE podle DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanentní odkaz certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-Modul úhlový Kat. 6A ISO/IEC, stíněný',
        text: 'S modulem fixLink<sup>®</sup> SL RJ45 Keystone uživatel získává RJ45 zásuvkový modul nejvyšší kvality, který byl speciálně vyvinut pro aplikace s omezeným prostorem. Díky svému 75° kabelovému vývodu je modul vhodný zejména pro použití v datových zásuvkách a dutých kabelážích. Minimální montážní hloubka 27 mm umožňuje téměř neomezenou rozmanitost použití. Modul fixLink<sup>®</sup> SL RJ45 Keystone lze sestavit pomocí bočních kleští za méně než minutu. Díky volně přístupné kontaktní zóně je zaručena perfektní kvalita sestavy a tím i bezpečný přenosový výkon. Speciální kontaktní geometrie v kombinaci s 50 μ" zlatým povlakem zajišťuje PoE+ podle IEEE 80.3at za všech podmínek. Těleso je vyrobeno z poniklované zinkové slitiny a splňuje nejvyšší ekologické a mechanické požadavky. V kombinaci s designovým držákem modulu je možná kombinace se všemi spínacími programy. Různé certifikace od nezávislých testovacích laboratoří doplňují nejvyšší kvalitní standardy fixLink<sup>®</sup> SL Keystone-modulu. Jsou dodávány v ekologickém kartonovém balení.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanentní odkaz certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Nástroje a příslušenství',
        text: [
          'Nástroj pro odizolování pro odstranění vnějšího pláště a kovových fólií datového kabelu. Modrá barva.',
          'Měděná páska pro upevnění stínění. Předřezané na 35 x 9 mm. Balení: 20 kusů/taška 1500 kusů/role',
          'Barevné ochranné kryty proti prachu pro moduly preLink® SL a fixLink® SL Keystone.',
          'Balení 50 kusů/polyethylenový sáček',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikát GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certifikát GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Instalační kabel',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Datový kabel EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Datový kabel pro přenos analogových a digitálních signálů v kabeláži v primární, sekundární a terciární oblasti. Vhodné pro aplikace do třídy FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikát GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certifikát GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Technický list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Datový kabel EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Datový kabel pro přenos analogových a digitálních signálů v kabeláži v primární, sekundární a terciární oblasti. Vhodné pro aplikace do třídy FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikát GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Technický list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Datový kabel EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Datový kabel pro přenos analogových a digitálních signálů v kabeláži v primární, sekundární a terciární oblasti. Vhodné pro aplikace do třídy FA.',
        datasheets: [
          {
            name: 'Technický list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Datové zásuvky',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'AP pouzdro a rám pro výše uvedené modulární držáky',
        text: [
          'Nástěnné pouzdro IP20, 80x80x42mm pro instalaci EasyLan modulárních držáků',
          'Mezistěna pro nástěnné pouzdro pro zvýšení výšky o 10 mm',
          'Jednoduchý krycí rám 80x80mm',
          'Dvojitý krycí rám 80x150mm',
        ],
        datasheets: [
          {
            name: 'Nástěnné pouzdro',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Hloubkové rozšíření nástěnného pouzdra',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'SL modulární držák, 45° výstup UP, BK, AP, BT jednoportový, dvouportový, tříportový',
        text: 'SL modulární držáky jsou zvláště vhodné pro instalaci v kabelovém kanálu (EK), montáž pod omítku (UP) a nástěnnou montáž (AP). Odstraněním dvou montážních jazýčků je možná také instalace do podlahového boxu (BT). SL modulární držák může bezpečně a trvale držet jeden až tři EasyLan Keystony. Má velké, centrálně umístěné okno pro snadné označení. Centrální deska (50x50 mm) a rám (80x80 mm) jsou vyrobeny z UV-odolného a extrémně odolného plastu. Tím je zajištěn dlouhodobý a barevně věrný vzhled.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Designový modulární držák SL, 45° výstup UP, BK, AP, BT jednoportový, dvouportový',
        text: 'Designový modulární držák je vyroben z rámu z litého zinku. Je vhodný pro instalaci do kabelového kanálu (EK), pro montáž pod omítku (UP) nebo s použitím samostatného rámu také pro nástěnnou montáž (AP). Odstraněním dvou montážních jazýčků je možná také instalace do podlahového boxu (BT). Designový modulární držák je kompatibilní s téměř všemi spínacími programy dostupnými na trhu. Díky kompatibilitě s UAE designový modulární držák neposkytuje prostor pro prachovou krytku.',
        datasheets: [
          {
            name: 'Jedno- / dvouportový Keystone, bez centrální desky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Dvouportový Keystone, s centrální deskou 50x50 mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: 'Jednoportový s centrální deskou',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'IP44 nástěnný modulární držák, jednoportový, dvouportový',
        text: 'Robustní nástěnné pouzdro je pomocí adaptéra vhodné pro instalaci dvou preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone modulů. Pro zavedení dvou kabelů s průměrem 5,0 - 9,5 mm jsou na zadní straně pouzdra dvě snadno propíchnutelná místa. Modulární držák dosahuje ve zasunutém stavu třídy ochrany IP20, ve zavřeném stavu třídy ochrany IP44.',
      },
      DoseUPleer2xKS: {
        name: 'Modulární držák s přímým výstupem, dvouportový',
        text: 'Modulární držák s přímým výstupem Keystone je vyroben z rámu z litého zinku a je vhodný pro instalaci do kabelového kanálu (EK), pro montáž pod omítku (UP) nebo s použitím samostatného rámu také pro nástěnnou montáž (AP).',
      },
      bodentank: 'Podlahový box',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann podlahové systémy UT3, UT4',
        text: 'Nosná deska nahrazuje držáky zařízení a nabízí tak maximální prostor pro kabeláž. Šikmé vedení kabelů umožňuje bezpečné vedení kabelů i při velmi nízkých mezistěnách. Je možné kombinovat s montážními deskami pro 4x preLink<sup>®</sup>/fixLink<sup>®</sup> moduly nebo 1x H.D.S. Montážní desky jsou připevněny ke nosné desce dvěma šrouby, nevyužitá pole nosné desky mohou být zakryta krycími deskami, které chrání před prachem.',
        datasheets: [
          {
            name: 'Nosná deska pro UT3 bez montážní desky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Nosná deska pro UT4 bez montážní desky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann základní nosič pro podlahové boxy UT3, UT4',
        text: 'Keystone nosič pro podlahové boxy nahrazuje držáky zařízení a nabízí tak maximální prostor pro kabeláž. Šikmé vedení kabelů umožňuje bezpečné vedení kabelů i při velmi nízkých mezistěnách. Montážní desky jsou připevněny ke nosné desce dvěma šrouby, nevyužité průchody Keystone nosiče pro podlahové boxy mohou být zakryty krycími deskami, které chrání před prachem. Pomocí adaptéru lze nosič pro podlahové boxy použít také pro držáky zařízení Electraplan.',
        datasheets: [
          {
            name: 'Nosič pro podlahové boxy UT3 pro až 6 modulů',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Nosič pro podlahové boxy UT4 pro až 9 modulů',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Nosič pro podlahové boxy UT4 pro až 12 modulů',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK základní nosič pro podlahové boxy UT4',
        text: 'Keystone nosič pro podlahové boxy nahrazuje držáky zařízení a nabízí tak maximální prostor pro kabeláž. Šikmé vedení kabelů umožňuje bezpečné vedení kabelů i při velmi nízkých mezistěnách. Montážní desky jsou připevněny ke nosné desce dvěma šrouby, nevyužité průchody Keystone nosiče pro podlahové boxy mohou být zakryty krycími deskami, které chrání před prachem. Nosiče pro podlahové boxy jsou kompatibilní se systémem PUK podlahových boxů.',
        datasheets: [
          {
            name: 'Nosič pro podlahové boxy UT4 pro až 9 modulů',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Nosič pro podlahové boxy UT4 pro až 12 modulů',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann vložky pro podlahové systémy UT3, UT4, příslušenství',
        text: 'Vložky pro moduly nejsou povrchově upraveny práškovým lakováním a jsou žárově pozinkované kvůli uzemnění. Montážní desky jsou připevněny ke nosné desce dvěma šrouby, nevyužitá pole nosné desky mohou být zakryta krycími deskami, které chrání před prachem.',
        datasheets: [
          {
            name: 'Montážní deska pro 4x RJ45 moduly',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Montážní deska pro 3x RJ45 moduly',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'DIN lišta adaptér',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktní DIN lišta modulární pouzdro pro 1 modul',
        text: 'DIN lišta modulární pouzdro pro preLink<sup>®</sup>- / fixLink<sup>®</sup> moduly, vhodné pro rozvaděče bez krytu pro jističe.',
        datasheets: [
          {
            name: 'Kompaktní DIN lišta modulární pouzdro pro 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone F-svorka a krycí záslepka prázdného modulu Keystone',
        text: 'F-svorka a krycí záslepka jako náhrada modulu\nF-svorka slouží k integraci koaxiálních kabelů do preLink<sup>®</sup> / fixLink<sup>®</sup> patch panelů a zásuvkových programů. Krycí záslepka preLink<sup>®</sup>/fixLink<sup>®</sup> může být použita k zakrytí nevyužitých portů jak v rozvaděči, tak v zásuvce.\n\nPrázdný modul pro SC-S nebo LC-D jako náhrada modulu\nPrázdný modul pro SC-S nebo LC-D svorky v rozvaděči nebo zásuvce.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'DIN lišta modulární pouzdro pro 1 modul',
        text: 'DIN lišta modulární pouzdro pro preLink<sup>®</sup>- / fixLink<sup>®</sup> moduly, vhodné pro rozvaděče s krytem pro jističe.',
        datasheets: [
          {
            name: 'DIN lišta modulární pouzdro pro 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE pro 24 Keystone modulů s přímým výstupem',
        text: 'Distribuční pole 19" 1HE může být osazeno až 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone moduly. Je uspořádáno do dvou úrovní, takže moduly jsou zarovnány s předním panelem. Distribuční pole může být objednáno buď z nerezové oceli, nebo z práškově lakovaného železného plechu.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE pro 48 Keystone modulů s přímým výstupem',
        text: 'Distribuční pole 19" 1HE může být osazeno až 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone moduly. Je uspořádáno do dvou úrovní, takže moduly jsou zarovnány s předním panelem. Distribuční pole je vyrobeno z práškově lakovaného železného plechu.\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE pro 24 Keystone modulů s přímým výstupem',
        text: 'Distribuční pole 19" 0,5HE může být osazeno až 24 fixLink<sup>®</sup> RJ45 Keystone moduly. Jsou zarovnány s předním panelem.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE pro 24 Keystone modulů včetně označovacího okna',
        text: 'Distribuční pole 19" 1HE může být osazeno až 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone moduly. Je uspořádáno do dvou úrovní, takže moduly jsou zarovnány s předním panelem. Distribuční pole může být objednáno buď z nerezové oceli, nebo z práškově lakovaného železného plechu. Navíc je možné jednotlivé porty označit pomocí přiloženého, 12x33 vyraženého označovacího papíru.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Úhlový panel 30° 19" 1HE pro 24 Keystone modulů',
        text: 'U úhlového panelu preLink<sup>®</sup> / fixLink<sup>®</sup> má uživatel možnost zacvaknout 24 Keystone modulů pod úhlem 30° do panelu, čímž připojené patch kabely automaticky vedou k boční stěně skříně a musí být upevněny pouze po straně vedle patchovací roviny. Uživatel tak může upustit od použití dalších patch panelů pro vedení kabelů. V provozu vyžadují úhlové panely s plochým předním panelem a šikmo umístěnými patch kabely méně místa vpředu než běžné patch panely s vertikálně instalovanými porty.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      baugruppentraeger: 'Nosiče skupin',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Nosič skupin 19" 3HE pro použití zasouvacích modulů',
        text: 'Modulární nosič 19" 3HE pro použití 7TE zasouvacích modulů pro měď a optiku. Jsou připevněny dvěma šrouby z přední strany modulu. Zasouvací moduly lze zcela vysunout z modulárního nosiče. V modulárním nosiči může být upevněno maximálně 12x 7TE zasouvacích modulů.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Keystone zasouvací modul pro modulární nosič',
        text: 'Keystone zasouvací modul pro osazení modulárních nosičů. Keystone zasouvací modul může být osazen až 6 preLink<sup>®</sup> nebo fixLink<sup>®</sup> Keystone moduly. Keystone zasouvací modul je uspořádán do dvou úrovní, takže moduly jsou zarovnány s předním panelem. Uchycení kabelu se provádí přímo na krytu zasouvacího modulu. Může být upevněno 6 jednotlivých kabelů nebo 1 trunk kabel.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Modulární nosič (BGT) 19" 1HE pro použití zasouvacích modulů',
        text: 'Modulární nosič (BGT) pro použití 7TE zasouvacích modulů pro měď a optiku. Jsou připevněny dvěma šrouby z přední strany modulu. Zasouvací moduly lze zcela vysunout z modulárního nosiče. V modulárním nosiči může být upevněno maximálně 3x 7TE zasouvacích modulů (=18 portů).\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Boční modulární nosič 3HE/14TE pro použití zasouvacích modulů',
        text: 'Boční modulární nosič (S-BGT) pro použití 7TE zasouvacích modulů pro měď a optiku. Zasouvací moduly jsou připevněny dvěma šrouby z přední strany modulu. Zasouvací moduly lze zcela vysunout z modulárního nosiče. V bočním modulárním nosiči může být upevněno maximálně 2x 7TE zasouvacích modulů (=12 portů). Uchycení bočního modulárního nosiče (S-BGT) se provádí na boční perforaci skříně. Tím je zajištěna jednoduchá a rychlá montáž. Díky boční montáži ve skříni je možné realizovat prostorově úsporné, zvláště klimaticky přátelské kabelážní řešení, aniž by byla narušena cirkulace vzduchu.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      consolidation_point_panel: 'Panel konsolidačních bodů, DIN lišty atd.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Podlahový Consolidation Point Panel 19" nebo 10" 1HE',
        text: 'Podlahový Consolidation Point Panel s výměnnými předními deskami pro různé typy připojení pro měď a optiku. Volitelně může být podlahový Consolidation Point Panel zabezpečen zámkem proti neoprávněnému přístupu.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
        datasheets: [
          {
            name: 'Consolidation Point Panel 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Consolidation Point Panel 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Nástavbový Consolidation Point Panel pro 4-12 Keystone modulů',
        text: 'Nástavbový Consolidation Point Panel pro preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone moduly, použitelný jako Consolidation Panel, pro přímou montáž nebo pomocí montážní sady na DIN lišty v elektrotechnických rozvaděčích. Nástavbový Consolidation Point Panel je uspořádán do dvou úrovní. Moduly jsou zarovnány s předním panelem.\n\nMontážní materiál a Keystone moduly nejsou součástí dodávky.',
      },
      trunkkabel: 'Trunk kabel',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC rozdělovací systém Copper-Trunk-Bundled-Cable, třída EA ISO/IEC',
        text: 'CTBC rozdělovací systém je vhodný pro flexibilní použití ve strukturované budovové kabeláži a v datových centrech. Kabely svázané v jednom plášti jsou z výroby osazeny fixLink<sup>®</sup> Keystone moduly a testovány. Svazek může být tvořen buď instalačními kabely, nebo flexibilními kabely. Maximální délka svázaného kabelu je až 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC rozdělovací systém Copper-Trunk-Compact-Cable, třída EA ISO/IEC',
        text: 'CTCC rozdělovací systém je díky svému minimálnímu průměru kabelu a nízké hmotnosti ideální pro flexibilní použití ve strukturované budovové kabeláži a v datových centrech. Maximální délka vícevláknového kabelu je v závislosti na typu 60-90 m. Rozdělovací systém jako 10-Gbit řešení pro datová centra splňuje minimálně požadavky třídy EA.',
      },
      hds_innengabel: 'H.D.S. Vnitřní kabel',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. Vnitřní kabel Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. Vnitřní kabel Kat. 7 S/FTP pro snadné propojení skříní v datových centrech a multikonektorové propojení mezi distribučními místnostmi. Konstrukce kabelu se skládá z 24 párů stíněných kabelových prvků, přičemž 4 páry tvoří jeden svazkový prvek. Každý svazkový prvek je opatřen identifikačním páskem pro snadnější identifikaci. Flexibilní centrální prvek pro uvolnění tahu chrání kabel při instalaci. Plášť je vyroben z flexibilního materiálu LSOH.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. Vnitřní kabel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. Vnitřní kabel Kat. 7 S/FTP pro snadné propojení skříní v datových centrech a multikonektorové propojení mezi distribučními místnostmi. Konstrukce kabelu se skládá z 24 párů stíněných kabelových prvků, přičemž 4 páry tvoří jeden svazkový prvek. Každý svazkový prvek je opatřen identifikačním páskem pro snadnější identifikaci. Flexibilní centrální prvek pro uvolnění tahu chrání kabel při instalaci. Plášť je vyroben z flexibilního materiálu LSOH.',
      },
      generic: {
        download: 'Stáhnout',
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Ochrana údajů',
  },

  footer: {
    dataProtection: 'Ochrana údajů',
    imprint: 'Impressum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Hledání se nezdařilo.',
      searchEmpty: 'Žádné výsledky',
    },
    dropdown: {
      emptySelection: '--- vyberte prosím ---',
    },
  },
}

export const langKey = 'CZ'

const Default = {
  translation,
  langKey,
}

export default Default

import Cookies from 'universal-cookie'
import { cookieLiveTimeInMillis } from '../../../utility'

type Options = {
  path?: string
  expires?: Date
}

export const COOKIE_CONSENT = 'cookie_consent'
export const LANG = 'lang'

const get = (name: string) => {
  const cookies = new Cookies()
  return cookies.get(name)
}

const set = (name: string, value: string, options: Options = { path: '/' }) => {
  const hasConsent = isConsentGiven(name)

  if (!hasConsent) return false
  const cookies = new Cookies()
  cookies.set(name, value, { expires: new Date(Date.now() + cookieLiveTimeInMillis), ...options })
}

const remove = (name: string, options: Options = { path: '/' }) => {
  const cookies = new Cookies()
  cookies.remove(name, options)
}

// consent functions start

const initConsentSettings = (translation: any, initToggleValue: boolean) => {
  const settings = {}
  translation.cookieConsent.cookies.forEach((cookie) => {
    cookie.items.forEach((item: any) => {
      settings[item.name] = cookie.disabled === true ? true : initToggleValue
    })
  })
  return settings
}

const getConsentSettings = () => {
  const consent = cookie.get(COOKIE_CONSENT)

  if (consent && typeof consent === 'object') {
    return consent
  }
  return false
}

const setConsentSettings = (settings: any) => {
  if (typeof settings !== 'object') return false
  set(COOKIE_CONSENT, JSON.stringify(settings))
}

const isConsentGiven = (name: string) => {
  if (name === COOKIE_CONSENT) return true
  if (name === LANG) return true
  const consent = getConsentSettings()

  if (!consent) return false
  return consent[name]
}

// consent functions end

const cookie = {
  get,
  set,
  remove,
  consent: {
    init: initConsentSettings,
    get: getConsentSettings,
    set: setConsentSettings,
    isGiven: isConsentGiven,
  },
}

export default cookie

import { useState } from 'react'
import play from '../../../assets/image/easyLan/video/play.png'

type Props = {
  video: string
  disableCover?: boolean
}

const Embed: React.FC<Props> = ({ video, disableCover = false }) => {
  const [showVideo, setShowVideo] = useState(false)
  return (
    <div className="shadow-inner w-full aspect-video bg-light">
      {!showVideo && !disableCover && (
        <div onClick={() => setShowVideo(true)} className="cursor-pointer flex items-center justify-center h-full">
          <img src={play} alt="PLAY" className="h-36 w-auto" />
        </div>
      )}
      {(showVideo || !!disableCover) && (
        <iframe
          width="100%"
          height="100%"
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}
    </div>
  )
}

export default Embed

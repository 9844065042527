import { Gradient } from '../Gradient/Gradient.Styled'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'

const Separator = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-white relative pt-40">
      <Gradient className="h-10 rotate-180" />
      <div className="py-20 bg-danger">
        <Container>
          <div className="text-lg lg:text-[40px] leading-10 lg:leading-[40px] text-white mt-3 uppercase">{t.easylan.separator.title}</div>
          <div className="text-lg lg:text-[40px] leading-10 lg:leading-[40px] font-bold text-white mt-3 uppercase">{t.easylan.separator.title2}</div>
          <div className="mt-3 text-white max-w-screen-sm">{t.easylan.separator.text}</div>
          <a
            className="mt-5 text-white uppercase bg-dark shadow-md w-fit font-bold py-2 px-10 text-sm inline-block"
            href="https://www.easylan.de/"
            target="_blank"
            rel="noreferrer"
          >
            {t.easylan.separator.cta}
          </a>
        </Container>
      </div>
      <Gradient className="h-10" />
    </div>
  )
}

export default Separator

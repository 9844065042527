import easylan from '../../assets/image/easyLan/easylan.svg'
import Layout from '../UI/Layout/Layout'
// import SubMenu from './SubMenu'
import Hero from './Hero/Hero'
import Handling from './Handling/Handling'
import Download from './Download/Download'
import System from './System/System'
import Module from './Module/Module'
// import Detail from './Detail/Detail'
import Video from './Video/Video'
import Separator from './Separator/Separator'
// import Combination from './Combination/Combination'
// import Recommended from './Recommended/Recommended'
import Guarantee from './Guarantee/Guarantee'
import End from './End/End'

const EasyLan = () => {
  return (
    <Layout logo={easylan} headerLineColor={'red'}>
      {/* <SubMenu /> */}
      <Hero />
      <Handling />
      <Download />
      <System />
      <Module />
      {/* <Detail /> */}
      <Video />
      <Separator />
      {/* <Combination /> */}
      {/* <Recommended /> */}
      <Guarantee />
      <Download />
      <End />
    </Layout>
  )
}

export default EasyLan

import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const StyledItem = styled.div<{ large?: boolean; highlight?: boolean }>`
  aspect-ratio: 1/1;
  ${({ highlight, theme }) =>
    highlight
      ? `
      background-color: ${theme.color.background.danger};
    `
      : `
    background-color: ${theme.color.background.light};
    `}
  ${({ large }) =>
    large &&
    `
      grid-row: span 2;
    grid-column: span 2;

  `}
`

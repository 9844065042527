import styled from 'styled-components'
import { Gradient } from '../Gradient/Gradient.Styled'
import bg from '../../../assets/image/easyLan/module/bg.svg'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import Accordion from '../../UI/Accordion/Accordion'
import useGetSelectedCategoryItem from '../useGetSelectedCategoryItem'
import ModuleSelection from './ModuleSelection/ModuleSelection'
import fixlink from '../../../assets/image/easyLan/fixlink.svg'
import Carousel from '../../UI/Carousel/Carousel'

const Content = styled.div`
  background-image: url(${bg});
  background-size: 100%;
  background-position: center 20vh;
  background-repeat: no-repeat;
`

const Module = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const selectedCategoryItem = useGetSelectedCategoryItem()

  const getAccordionItems = () => {
    if (!selectedCategoryItem) return []
    const items = []
    if (selectedCategoryItem.item.ghmtCertificates) {
      items.push({
        title: t.easylan.module.accordion.ghmtCertificates.title,
        text: t.easylan.module.accordion.ghmtCertificates.text,
        links: selectedCategoryItem.item.ghmtCertificates.map((l) => ({ name: l.name, href: l.href })),
      })
    }
    if (selectedCategoryItem.item.datasheets) {
      items.push({
        title: t.easylan.module.accordion.datasheets.title,
        text: t.easylan.module.accordion.datasheets.text,
        links: selectedCategoryItem.item.datasheets.map((l) => ({ name: l.name, href: l.href })),
      })
    }
    if (selectedCategoryItem.item.compatibilitySheets) {
      items.push({
        title: t.easylan.module.accordion.compatibilitySheets.title,
        text: t.easylan.module.accordion.compatibilitySheets.text,
        links: selectedCategoryItem.item.compatibilitySheets.map((l) => ({ name: l.name, href: l.href })),
      })
    }
    if (selectedCategoryItem.item.excelTemplates) {
      items.push({
        title: t.easylan.module.accordion.excelTemplates.title,
        text: t.easylan.module.accordion.excelTemplates.text,
        links: selectedCategoryItem.item.excelTemplates.map((l) => ({ name: l.name, href: l.href })),
      })
    }
    return items
  }

  const getSliderItems = (images: string[]) => {
    return images.map((image, i) => (
      <div key={i} className="w-full h-full">
        <img className="w-full aspect-square object-contain" src={image} alt="Pro-RJ45" />
      </div>
    ))
  }

  if (!selectedCategoryItem) return <></>
  return (
    <div className="bg-white relative" id="module">
      <Gradient className="absolute top-0 left-0 w-full h-10 rotate-180" />
      <Container>
        <Content className="pt-[15vh]">
          <div className="flex flex-col lg:justify-between pt-20">
            <div className="flex flex-col lg:flex-row lg:gap-20 items-start">
              <div className="w-full lg:w-5/12 shrink-0">
                <div className="w-full aspect-square relative">
                  {selectedCategoryItem.item.images.length > 1 ? (
                    <Carousel key={selectedCategoryItem.item.id} items={getSliderItems(selectedCategoryItem.item.images)} itemsToShow={1} />
                  ) : (
                    <img src={selectedCategoryItem.item.images[0]} alt={selectedCategoryItem.item.name} className="w-full h-full object-contain" />
                  )}
                  <div className="absolute top-2 left-2">
                    <img src={fixlink} alt="" className="h-10" />
                  </div>
                </div>
              </div>
              <div className="lg:w-5/12">
                <span className="text-danger" dangerouslySetInnerHTML={{ __html: t.easylan.fixLink }} />
                <div className="font-bold text-lg uppercase">{selectedCategoryItem.item.name}</div>
                {Array.isArray(selectedCategoryItem.item.text) ? (
                  <ul>
                    {selectedCategoryItem.item.text.map((text, i) => (
                      <li dangerouslySetInnerHTML={{ __html: text }} key={i} className="text-sm whitespace-pre-wrap list-disc mt-2 ml-4" />
                    ))}
                  </ul>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: selectedCategoryItem.item.text }} className="text-sm whitespace-pre-wrap" />
                )}
                {selectedCategoryItem.item.overview && (
                  <a
                    href={selectedCategoryItem.item.overview}
                    className="inline-block mt-10 text-white bg-dark px-2 pr-20 py-1 text-sm w-fit shadow-md"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t.easylan.module.overview}
                  </a>
                )}
                {(selectedCategoryItem.item.ghmtCertificates ||
                  selectedCategoryItem.item.datasheets ||
                  selectedCategoryItem.item.compatibilitySheets) && (
                  <Accordion
                    className="mt-10"
                    items={getAccordionItems().map((i) => ({
                      title: i.title,
                      content: (
                        <div className="pb-5">
                          <div key={i} className="text-sm mt-2">
                            {i.text}
                          </div>
                          <div className="text-sm mt-2 flex flex-col gap-2">
                            {i.links.map((l, i) => (
                              <a
                                key={i}
                                href={l.href}
                                className="text-white bg-dark px-2 pr-20 py-1 text-sm w-fit shadow-md"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {l.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      ),
                    }))}
                  />
                )}
              </div>
            </div>
            <ModuleSelection />
          </div>
        </Content>
      </Container>
    </div>
  )
}

export default Module

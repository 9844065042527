import Header from './Header/Header'
import Footer from './Footer/Footer'
import { CoreContent } from './Layout.Styled'
// import CookieConsent from './CookieConsent/CookieConsent'

type Props = {
  children: React.ReactNode
  className?: string
  hideHeader?: boolean
  logo?: string
  headerLineColor?: string
}

const Layout: React.FC<Props> = ({ children, className, hideHeader = false, logo, headerLineColor }) => {
  return (
    <div className={className}>
      {!hideHeader && <Header logo={logo} lineColor={headerLineColor} />}
      {/* <CookieConsent> */}
      <CoreContent>{children}</CoreContent>
      {/* </CookieConsent> */}
      <Footer />
    </div>
  )
}

export default Layout

import { forwardRef } from 'react'
import { StyledBlock, CustomContainer } from './Block.Styled'

type Props = {
  height?: string
  margin?: string
  container?: boolean
  sticky?: boolean
  overflow?: string
  backgroundImage?: string
  backgroundColor?: string
  minified?: boolean
  children: React.ReactNode
  className?: string
}

export type Ref = any

const Block: React.FC<Props> = forwardRef<Ref, Props>(
  ({ height, margin, container, children, sticky, overflow, backgroundImage, backgroundColor, minified, className, ...props }, ref) => {
    return (
      <StyledBlock
        sticky={sticky}
        height={height}
        margin={margin}
        overflow={overflow}
        ref={ref}
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        minified={minified}
        className={className}
        {...props}
      >
        {container ? <CustomContainer>{children}</CustomContainer> : children}
      </StyledBlock>
    )
  },
)

export default Block
